import {closePopup} from "utils/commonUtil";
import React from "react";
import {ReactComponent as LineCircle} from "assets/icon/line-circle.svg";
import {ReactComponent as XButton} from "assets/icon/xButton.svg";

const Terms2 = () => {
  return (
    <div id="terms2Popup" className="popupContainer termsPopup d-flex a-center j-center d-none">
      <div className="popupBody bc-white p-relative">
        <XButton width={52} height={52} onClick={() => closePopup("terms2Popup")} />
        <div className="inner">
          <div className="popupHead fs-22 fw-7 ls-3">
            안전수칙
          </div>
          <ul className="textArea scrollbar fs-18 fw-5 ls-3">
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>농작업자는 자신은 물론 타인에게 위해를 가하지 않도록 평소부터 안전의식을 갖고 작업에 임해야 한다. 또한, 농업용 기계 기구의 일상점검이나 적정한 조작 등을 통해 농작업을 안전하게 실시할 수 있도록 노력하면서 주변환경도 배려해야 한다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>농작업을 시키기 위하여 타인의 농작업자를 고용할 경우, 고용주로서 피고용자에 대한 안전성을 확보하면서 주변환경에도 배려한다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>농작업자 및 고용주는 농작업 안전에 관한 교육 및 홍보활동 등에 적극적으로 참가하여 안전의식을 높이고, 도로교통법 등 관계법령을 숙지하는 등 안전한 농작업을 위해 노력해야 한다.</p>
            </li>
            <p className="fs-22 fw-7 ls-3 mt-30 mb-5">농작업 제한 대상</p>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>다음 해당하는 자는 농업기계를 운전조작시 고속작업 등 위험을 수반한 농작업을 수행하지 말아야 하고 또한 시키지도 말아야 한다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>음주를 하였거나 약물을 복용하고 있어 작업에 지장이 있는 자</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>병, 부상, 과로 등으로 정상적인 작업이 곤란한 자</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>임신 중이거나 해당 작업이 임신 또는 출산과 관련하여 기능장애 등 건강상태에 악영향을 미친다고 생각되는 자</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>연소자 및 미숙련자(숙련 작업자의 지도하에 실시하는 경우 제외)</p>
            </li>
            <p className="fs-22 fw-7 ls-3 mt-30 mb-5">농작업시 유의 사항</p>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>하루의 작업을 시작하기 전에는 준비운동을, 작업 후에는 정리운동을 하여 몸을 풀어준다.또한 기후존건이나 작업자의 몸 상태를 감안하여 무리없는 작업을 하도록 해야하며, 여러명이 작업할 경우에는 사전에 그날의 작업에 대해 미리 협의한다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>기상조건이나 포장조건 등에 의해 작업이 순조롭게 진행되지 않을 때 무리하여 작업하면 결과적으로 사고의 원인이 될 가능성이 많기 때문에 여유를 갖고 무리없는 작업계획을 세운다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>가능한 한 하루의 작업시간은 8시간을 넘지 않도록 하며 피로가 축적되지 않도록 2시간 마다 정기적으로 휴식을 취하도록 한다.</p>
            </li>
            <p className="fs-22 fw-7 ls-3 mt-30 mb-5">농기계 안전취급 관리 요령</p>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>바른 취급법을 익히자</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>취급설명서 숙지 : 구조, 조작법, 일상점검정비, 정기점검정비</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>취급설명서 보관 : 눈에 잘 띄는 곳에 보관 - 의심나면 즉시 확인</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>작업에 알맞은 복장을 착용하자</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>몸에 착 붙은 작업복 착용(헐렁항 옷, 긴소매, 큰장갑은 사고 유발)</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>작업화는 코가 단단하고 미끄러지지 않는 것을 착용한다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>자신을 과시하지 말고 언제나 신중히 운전하자</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>주의해야할 장소 : 교차로, 빗물이 있는 도로, 비탈길, 커브길, 포장 출입구 등</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>점검 정비는 반드시 엔진을 끄고 하자</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>위험부위 : 벨트, 체인, 예취날, 경운날, 스크류, 분리침, 동력 취출축 등</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>결함이 있으면 정비한 후에 운전 : 연료 냉각수, 이상음, 배기색 불량, 압축불량, 클러치, 변속장치, 브레이크 작동불량 등</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Terms2;