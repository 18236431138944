import React, { useEffect, useState } from 'react';
import TradeDetailTagInfo from './TradeDetailTagInfo';
import TradeDetailImage from './TradeDetailImage';
import TradeDetailNicknameBar from './TradeDetailNicknameBar';
import TradeDetailTitle from './TradeDetailTitle';
import TradeDetailTable from './TradeDetailTable';
import TradeDetailContent from './TradeDetailContent';
import TradeDetailComment from './TradeDetailComment';
import noImage from "assets/image/trade/noBigImage.svg"
import TradeImagePopup from "../../../components/trade/TradeImagePopup";
import { pageLoadingEvent } from 'utils/commonUtil';

const TradeDetailBody = ({ tradeData }) => {
    const [imagePopup, setImagePopup] = useState(false);

    const defaultImage = [noImage];

    const getCategoryTag = (categoryId) => {
        let mainTag = '기타';
        let subTag = '기타';

        switch (categoryId) {
            case 6:
                mainTag = '농기계';
                subTag = '트랙터';
                break;
            case 7:
                mainTag = '농기계';
                subTag = '경운기';
                break;
            case 8:
                mainTag = '농기계';
                subTag = '이앙기';
                break;
            case 9:
                mainTag = '농기계';
                subTag = '관리기';
                break;
            case 10:
                mainTag = '농기계';
                subTag = '콤바인';
                break;
            case 11:
                mainTag = '농기계';
                subTag = '드론';
                break;
            case 12:
                mainTag = '농기계';
                subTag = '기타 농기계';
                break;
            case 13:
                mainTag = '농지';
                subTag = '논';
                break;
            case 14:
                mainTag = '농지';
                subTag = '밭';
                break;
            case 15:
                mainTag = '농지';
                subTag = '과수원';
                break;
            case 16:
                mainTag = '농지';
                subTag = '하우스재배';
                break;
            case 17:
                mainTag = '농지';
                subTag = '축사';
                break;
            case 18:
                mainTag = '농지';
                subTag = '농업창고';
                break;
            case 19:
                mainTag = '농지';
                subTag = '기타농지';
                break;
            case 20:
                mainTag = '인력';
                subTag = '농작업 인력';
                break;
            case 21:
                mainTag = '인력';
                subTag = '행사 인력';
                break;
            case 22:
                mainTag = '인력';
                subTag = '기타 인력';
                break;
            case 23:
                mainTag = '농산물';
                subTag = '벼/맥류';
                break;
            case 24:
                mainTag = '농산물';
                subTag = '양파';
                break;
            case 25:
                mainTag = '농산물';
                subTag = '고추';
                break;
            case 26:
                mainTag = '농산물';
                subTag = '단호박';
                break;
            case 27:
                mainTag = '농산물';
                subTag = '기타 농축산물';
                break;
            case 5:
                mainTag = '기타';
                subTag = '기타거래';
                break;
            default:
                mainTag = '기타';
                subTag = '기타';
                break;
        }

        return { mainTag, subTag };
    };

    const { mainTag, subTag } = getCategoryTag(tradeData.categoryId);

    const boxResize = () => {
        let imgListBox = document.querySelectorAll(".mainImageListBOx");
        let noImage = document.querySelectorAll(".noImage");

        imgListBox.forEach((element) => {
            let width = element.offsetWidth;
            let height = width * 0.4;
            element.style.height = `${height}px`;
        });
        noImage.forEach((element) => {
            let width = element.offsetWidth;
            let height = width * 0.4;
            element.style.height = `${height}px`;
        });
    }

    window.addEventListener('resize', boxResize);

    useEffect(() => {
        boxResize();
        pageLoadingEvent();
    });

    return (
        <>
            {imagePopup &&
                <TradeImagePopup imageList={tradeData.imageUrlList.length === 0 ? defaultImage : tradeData.imageUrlList} setValue={setImagePopup} />
            }

            <div className='tradeDetailBody d-flex f-column a-center j-center' >
                <div className='inner'>
                    <TradeDetailTagInfo mainTag={mainTag || ''} subTag={subTag || ''} />
                </div>
                <div className="imgList imgListBox mainImageListBOx w-100">
                    <TradeDetailImage
                      imagePaths={tradeData.imageUrlList.length === 0 ? defaultImage : tradeData.imageUrlList}
                      setValue={setImagePopup}
                      active={tradeData.imageUrlList.length !== 0}
                    />
                </div>
                <div className='inner'>
                    <TradeDetailNicknameBar nickname={tradeData.writerName || ''} tradeType={tradeData.type === 1 ? "해주세요" : "해드립니다"} />
                    <TradeDetailTitle title={tradeData.title} />
                </div>
                <TradeDetailTable
                    price={tradeData.paymentCost}
                    type={mainTag}
                    location={tradeData.address}
                    costNegotiate={tradeData.isCostNegotiable}  // 추가
                />
                <TradeDetailContent
                    content={tradeData.desc || ''}
                    timeStamp={tradeData.createDateTime || new Date().toISOString().split('T')[0]}
                    viewCount={tradeData.views}
                    tradeCount={tradeData.requestCount}
                />
                <TradeDetailComment
                    tradeData={tradeData}
                />
            </div>
        </>
    );
}

export default TradeDetailBody;