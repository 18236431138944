// 날짜 이름 구하기
export const getDayName = (number) => {
  const list = ["일", "월", "화", "수", "목", "금", "토"];
  return list[number] || "";
}

// categoryId를 문자열로 매핑하는 함수
export const getCategoryNameById = (categoryId) => {
  switch (categoryId) {
    case 1: return '농기계';
    case 2: return '농지';
    case 3: return '인력';
    case 4: return '농산물';
    case 5: return '기타 거래';
    case 6: return '트랙터';
    case 7: return '경운기';
    case 8: return '이앙기';
    case 9: return '관리기';
    case 10: return '콤바인';
    case 11: return '드론';
    case 12: return '기타 농기계';
    case 13: return '논';
    case 14: return '밭';
    case 15: return '과수원';
    case 16: return '하우스재배';
    case 17: return '축사';
    case 18: return '농업창고';
    case 19: return '기타농지';
    case 20: return '농작업 인력';
    case 21: return '행사 인력';
    case 22: return '기타 인력';
    case 23: return '벼/맥류';
    case 24: return '양파';
    case 25: return '고추';
    case 26: return '단호박';
    case 27: return '기타 농축산물';
    default: return '카테고리 정보 없음';
  }
};

export const getCategoryNameByIdBig = (categoryId) => {
  switch (true) {
    case [1, 6, 7, 8, 9, 10, 11, 12].includes(categoryId):
      return '농기계';
    case [2, 13, 14, 15, 16, 17, 18, 19].includes(categoryId):
      return '농지';
    case [3, 20, 21, 22].includes(categoryId):
      return '인력';
    case [4, 23, 24, 25, 26, 27].includes(categoryId):
      return '농산물';
    case [5].includes(categoryId):
      return '기타';
    default:
      return '전체';
  }
};

export const getSubCategoriesByCategory = (category) => {
  switch (category) {
    case '농기계':
      return ['트랙터', '경운기', '이앙기', '관리기', '콤바인', '드론', '기타 농기계'];
    case '농지':
      return ['논', '밭', '과수원', '하우스재배', '축사', '농업창고', '기타농지'];
    case '인력':
      return ['농작업 인력', '행사 인력', '기타 인력'];
    case '농산물':
      return ['벼/맥류', '양파', '고추', '단호박', '기타 농축산물'];
    case '기타':
      return ['기타 거래'];
    default:
      return [];
  }
};
