import React from "react";

const LoadingPopup = () => {
  return (
    <section id="loadingPopup" className="loadingPopup popupContainer d-flex a-center j-center">
      <div className="loadingRolling">
        <div className="loadingBox">
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default LoadingPopup;