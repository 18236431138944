import React from "react";
import {handelInput} from "../utils/commonUtil";

const CommonInputBox = (
  {id, title, subTitle, titleClass, hintMessage, maxLength, placeholder, value, setValue, activeEvent, disabled, type}
) => {
  // const handleInputChange = (e) => {
  //   if (activeEvent) {
  //     const target = e.target;
  //     const getValue = target.value;

  //     if (getValue.length >= 1) {
  //       target.classList.add("active");
  //     } else {
  //       target.classList.remove("active");
  //     }

  //     if (setValue) setValue(getValue);
  //   }
  // }

  const activeCheck = activeEvent && value.length > 0

  return (
    <div className="commonInputBox">
      <label
        htmlFor={id}
        className="fs-24 mb-15 d-block"
      >
        <span className={`fw-6 ${titleClass}`}>{title}</span>
        {subTitle &&
          <span>({subTitle})</span>
        }
      </label>
      {hintMessage &&
        <p className="fw-6 fc-mediumGray2 mb-10">{hintMessage}</p>
      }

      <input
        id={id}
        className={`commonInput w-100 fs-24 br-6 bc-white ${activeCheck ? "active" : ""}`}
        type="text"
        defaultValue={value}
        // value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        // onChange={handleInputChange}
        onChange={(e) => setValue(e.target.value)} 
        onInput={(e) => handelInput(e, type)}
      />
    </div>
  );
};

export default React.memo(CommonInputBox)