import { wasUrl } from "utils/commonUtil";
import { apiWithAuth } from "./apiHelpers";

export const postComment = async (cookieDatas, data) => {
    try {
        await apiWithAuth(
            `${wasUrl}comment`,
            'post',
            data,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return 200;
    } catch (error) {
        return 500;
    }
}

export const changeComment = async (cookieDatas, commentId, data) => {
    try {
        await apiWithAuth(
            `${wasUrl}comment/${commentId}`,
            'post',
            data,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return 200;
    } catch (error) {
        return 500;
    }
}

export const deleteComment = async (cookieDatas, commentId) => {
    try {
        await apiWithAuth(
            `${wasUrl}comment/remove/${commentId}`,
            'post',
            null,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return 200;
    } catch (error) {
        return 500;
    }
};

export const getReplyList = async (cookieDatas, data) => {
    try {
        let commentId = data.id;
        const response = await apiWithAuth(
            `${wasUrl}comment/reply/member`,
            'get',
            { commentId },
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return response.data;
    } catch (e) {
        console.error(e);
    }
}

export const getCommentList = async (cookieDatas, data) => {
    try {
        const response = await apiWithAuth(
            `${wasUrl}comment/member`,
            'get',
            data,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return response.data.list;
    } catch (e) {
        console.error(e);
    }
}

export const getReplyLists = async (cookieDatas, commentList) => {
    try {
        const updatedReplyLists = await Promise.all(commentList.map(async (element) => {
            const replyList = await getReplyList(cookieDatas, element);
            return { id: element.id, list: replyList };
        }));
        return updatedReplyLists;
    } catch (e) {
        console.error(e);
    }
};

export const getDealList = async (cookieDatas, data) => {
    try {
        const response = await apiWithAuth(
            `${wasUrl}trade/member`,
            'get',
            data,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return response;
    } catch (e) {
        console.error(e);
    }
};

export const getTradeDetail = async (tradeId, cookieDatas) => {
    try {
        const response = await apiWithAuth(
            `${wasUrl}trade/member/${tradeId}`,
            'get',
            null,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return response.data;
    } catch (e) {
        console.error(e);
    }
};