import React from "react";
import { handelInput } from "utils/commonUtil";
// import {handelInput, noSpecialText, onlyNumber} from "utils/commonUtil";

const LoginInputBox = ({ id, maxLength, placeholder, setValue, type }) => {

  const handleInputChange = (e) => {
    const value = e.target.value;
    setValue(value);

    const parent = e.target.parentNode;
    if (value.length >= 1) {
      parent.classList.add("active");
    } else {
      parent.classList.remove("active");
    }
  };

  return (
    <input
      id={id}
      className="loginInput w-100 borderBox fs-22 pl-15 pr-15"
      type="text"
      maxLength={maxLength}
      placeholder={placeholder}
      onChange={handleInputChange}
      onInput={(e) => handelInput(e, type)}
    />
  );
};

export default LoginInputBox;
