import React from 'react';

const TradeDetailContent = ({ content, timeStamp, viewCount, tradeCount }) => {
    return (
        <div className='tradeDetailContent d-flex f-column a-start j-center' style={{ width: '100%' }}>
            <div className='detailContent d-flex f-column a-start j-center bb-1-lightGray14 pt-30 pb-50 bc-white' style={{ width: '100%' }}>
                <div className='inner'>
                    <span className='fs-20 fw-5 fc-darkGray23 wb-all'>
                        {content}
                    </span>
                </div>
            </div>
            <div className='detailContentInfo d-flex a-center j-center bc-white' style={{ width: '100%', height: 64 }}>
                <div className='inner d-flex f-row a-center j-between'>
                    <span className='leftTimeInfo d-flex a-center j-left fs-16 fw-5 fc-mediumGray4' style={{ width: '50%' }}>
                        {`${timeStamp} 등록`}
                    </span>
                    <div className='rightCountInfo d-flex f-row a-center j-right' style={{ width: '50%' }}>
                        <span className='fs-18 fw-6 fc-green1'>
                            조회
                        </span>
                        <span className='fs-18 fw-4 fc-black8 ml-5'>
                            {viewCount}
                        </span>
                        <span className='fs-18 fw-6 fc-green1 ml-18'>
                            거래 신청
                        </span>
                        <span className='fs-18 fw-4 fc-black8 ml-5'>
                            {tradeCount}
                        </span>
                    </div>
                </div>
            </div>
            <div className='content-comment-divideBar bc-lightGray13' style={{ width: '100%', height: 13 }}></div>
        </div>
    );
}

export default TradeDetailContent;
