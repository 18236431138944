import React from 'react'

const TradeDetailTagInfo = ({ mainTag, subTag }) => {
    return (
        <div className='tradeDetailTagInfo d-flex f-row a-center j-left pt-15 pb-15' style={{ width: "100%" }}>
            <span className='fs-24 fw-5 fc-black1'>{mainTag}</span>
            <span className='fs-12 fw-4 fc-darkGray18 ml-20 mr-20'>〉</span>
            <span className='fs-24 fw-5 fc-black1 mr-20'>{subTag}</span>
        </div>
    );
}

export default TradeDetailTagInfo