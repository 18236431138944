import React from "react";
// 스타일 파일을 import

const LoginButton = ({ text, onClick, isActive = true }) => {
    return (
        <button className="login-button fw-7 fs-30 mt-20 mb-20 m-auto"
            onClick={isActive? onClick : {}}
        >
            {text}
        </button>
    );
};

export default LoginButton;
