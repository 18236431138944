import React, { useState, useEffect } from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import { pageLoadingEvent } from 'utils/commonUtil';
import TradeTabBar from './tab/TradeTabBar';
import TradeDropdown from './TradeDropdown';
import TradeToggleTab from './TradeToggleTab';
import TradeBody from './TradeBody';
import { useLocation, useNavigate } from 'react-router-dom';
import BackHeader from 'components/header/BackHeader';
import { getDealList } from 'api/trade';
import { useCookies } from 'react-cookie';

const TradeMain = () => {
  const tabs = [
    { index: 1, name: '농기계' },
    { index: 2, name: '농지' },
    { index: 3, name: '인력' },
    { index: 4, name: '농산물' },
    { index: 5, name: '기타' },
  ];

  const dropdownList = [
    { index: 1, list: ['농기계 전체', '트랙터', '경운기', '이앙기', '관리기', '콤바인', '드론', '기타 농기계'], type: '농기계' },
    { index: 2, list: ['농지 전체', '논', '밭', '과수원', '하우스재배', '축사', '농업 창고', '기타 농지'], type: '농지' },
    { index: 3, list: ['인력 전체', '농작업 인력', '행사 인력', '기타 인력'], type: '인력' },
    { index: 4, list: ['농산물 전체', '벼/맥류', '양파', '고추', '단호박', '기타 농축산물'], type: '농산물' },
    { index: 5, list: ['기타 거래'], type: '기타' },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [selectedElement, setSelectedElement] = useState(dropdownList[0].list[0]);
  const [toggleTab, setToggleTab] = useState('전체');
  const [tradeData, setTradeData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [pageContentAmount] = useState(6); // 페이지당 보여줄 항목 수
  const startIndex = (currentPage - 1) * pageContentAmount;
  const paginatedData = tradeData.slice(startIndex, startIndex + pageContentAmount);

  const [cookies, setCookie] = useCookies(['authToken', 'refreshToken']);

  const convertIndexNumber = (numberIndex) => tabs.find(tab => tab.index === numberIndex).name;

  const getCategoryIdByName = (categoryName) => {
    switch (categoryName) {
      case '농기계': return 1;
      case '농지': return 2;
      case '인력': return 3;
      case '농산물': return 4;
      case '기타':
      case '기타 거래': return 5;
      case '트랙터': return 6;
      case '경운기': return 7;
      case '이앙기': return 8;
      case '관리기': return 9;
      case '콤바인': return 10;
      case '드론': return 11;
      case '기타 농기계': return 12;
      case '논': return 13;
      case '밭': return 14;
      case '과수원': return 15;
      case '하우스재배': return 16;
      case '축사': return 17;
      case '농업 창고': return 18;
      case '기타 농지': return 19;
      case '농작업 인력': return 20;
      case '행사 인력': return 21;
      case '기타 인력': return 22;
      case '벼/맥류': return 23;
      case '양파': return 24;
      case '고추': return 25;
      case '단호박': return 26;
      case '기타 농축산물': return 27;
      default: return null;
    }
  };

  const fetchDataFromAPI = async (data) => {
    try {
      const cookieDatas = {
        authToken: cookies.authToken,
        refreshToken: cookies.refreshToken,
      };

      const response = await getDealList(cookieDatas, data, setCookie); // 쿠키 데이터와 함께 호출
      if (response.status === 200) {
        const fetchedData = response.data.list; // 서버로부터 받은 데이터를 추출
        setTradeData(fetchedData); // 응답값을 그대로 tradeData에 저장
        setIsEmpty(fetchedData.length === 0); // 리스트가 비어있는지 확인하여 상태 업데이트
        return fetchedData;
      }
      throw new Error('Failed to fetch data');
    } catch (error) {
      console.error(error);
      setIsEmpty(true); // 에러 발생 시에도 빈 리스트로 처리
      return [];
    }
  };

  useEffect(() => {
    const data = {
      page: 1,
      count: 99,
      categoryId: 1
    }
    const initializeData = async () => {
      const initialData = await fetchDataFromAPI(data);
      setTradeData(initialData);
    };

    initializeData();
  }, [cookies.authToken, cookies.refreshToken, setCookie]);

  useEffect(() => {
    pageLoadingEvent();

    const pathParts = location.pathname.split('/');
    const tabIndexFromUrl = Number(pathParts[2]);
    const dropdownElementFromUrl = pathParts[3];
    const toggleTabFromUrl = pathParts[4];

    if (tabIndexFromUrl && dropdownList.find(item => item.index === tabIndexFromUrl)) {
      setActiveTabIndex(tabIndexFromUrl);
      const dropdownListForTab = dropdownList.find(item => item.index === tabIndexFromUrl).list;
      if (dropdownElementFromUrl && dropdownListForTab.includes(dropdownElementFromUrl)) {
        setSelectedElement(dropdownElementFromUrl);
      } else {
        setSelectedElement(dropdownListForTab[0]);
      }
      if (toggleTabFromUrl) {
        setToggleTab(toggleTabFromUrl);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    const newPath = `/tradeMain/${convertIndexNumber(activeTabIndex)}/${selectedElement}/${toggleTab}`;
    navigate(newPath, { replace: true });
  }, [activeTabIndex, selectedElement, toggleTab, navigate]);

  const handleTabClick = async (index) => { // 추가
    setActiveTabIndex(index);
    const categoryId = getCategoryIdByName(tabs.find(tab => tab.index === index).name);
    const data = {
      page: 1,
      count: 99,
      categoryId: categoryId
    }
    const newData = await fetchDataFromAPI(data); // 탭 클릭 시 categoryId로 API 호출
    setCurrentPage(1);
    setTradeData(newData);
    setSelectedElement(dropdownList.find(item => item.index === index).list[0]);
  };

  const handleDropdownChange = async (selected) => { // 추가
    setSelectedElement(selected);
    let categoryId;
    if (selected.includes('전체')) {
      categoryId = activeTabIndex;  // 탭의 categoryId 사용
    } else {
      categoryId = getCategoryIdByName(selected);  // 나머지 경우
    }
    const data = {
      page: 1,
      count: 99,
      categoryId: categoryId
    }
    const newData = await fetchDataFromAPI(data);  // API 호출
    setCurrentPage(1);
    setTradeData(newData);
  };

  const handleToggleTabClick = async (tab) => {
    const currentUrl = window.location.href;
    const segments = currentUrl.split('/');
    const urlParam = decodeURIComponent(segments[segments.length - 2]);

    let categoryId;

    if (urlParam.includes('전체')) {
      categoryId = activeTabIndex;
    } else if (urlParam === '맥류') {
      categoryId = 23;
    } else {
      categoryId = getCategoryIdByName(urlParam);
    }
    if (tab === '전체') {
      setToggleTab('전체');
      const data = {
        page: 1,
        count: 99,
        categoryId: categoryId
      }
      const newData = await fetchDataFromAPI(data);
      setTradeData(newData);
    } else {
      setToggleTab(tab);
      let tradeType = tab === '해주세요' ? 1 : 2; // '해주세요'는 1, '해드립니다'는 2
      const data = {
        page: 1,
        count: 99,
        categoryId: categoryId,
        tradeType: tradeType
      }
      const newData = await fetchDataFromAPI(data);
      setCurrentPage(1);
      setTradeData(newData);
    }
  };

  return (
    <section id="tradeMain" className="container bc-lightGray12">
      <Header />
      <div className='content content1 scrollbar'>
        <BackHeader title={'이웃과 거래하기'} />
        <TradeTabBar tabs={tabs} onTabClick={handleTabClick} activeTabIndex={activeTabIndex} />
        <TradeDropdown
          dropdownList={dropdownList.find(item => item.index === activeTabIndex).list}
          selectedElement={selectedElement}
          setSelectedElement={handleDropdownChange}
          type={dropdownList.find(item => item.index === activeTabIndex).type}
        />
        <TradeToggleTab activeTab={toggleTab} onTabClick={handleToggleTabClick} />
        <TradeBody
          tradeData={paginatedData}
          getCategoryNameById={getCategoryIdByName}
          isEmpty={isEmpty}
          totalItems={tradeData.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Footer />
    </section>
  );
};

export default TradeMain;