import React from "react";
import {ReactComponent as RightArrow} from "assets/icon/rightArrow.svg";

const MainMenuBox = ({list, status, setStatus}) => {
  const items = list.map(({name}, index) => {
    return (
      <button
        key={index}
        className={`w-100 bc-white fw-6 d-flex a-center j-between ${status === name ? "active" : ""}`}
        onClick={() => setStatus(name)}
      >
        <span>{name}</span>
        <RightArrow className="arrow" />
      </button>
    );
  });


  return (
    <div className="mainMenuBox d-block fs-24">
      { items }
    </div>
  );
};

export default React.memo(MainMenuBox);