import React, { useEffect } from "react";
import DaumPostcode from 'react-daum-postcode';
import { pageLoadingEvent } from "utils/commonUtil";
import { useNavigate, useLocation } from "react-router-dom"; // useNavigate와 useLocation 사용
import Header from "components/header/Header";
import { ReactComponent as BackButtonIcon } from "assets/icon/backButton.svg";

const PostCode = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    pageLoadingEvent();
  }, []);

  const handlePostCode = (data) => {
    const { roadAddress } = data;

    try {
      // 선택된 주소를 WritingPage로 반환
      const handleSearchComplete = location.state?.onComplete;
      if (handleSearchComplete) {
        handleSearchComplete({ roadAddress });
      }

      
      navigate("/WritingPage", { state: { selectedAddress: roadAddress, fromSearch: true } });
    } catch (error) {
      console.error("주소 처리 중 오류 발생:", error);
    }
  };

  return (
    <section className="container">
      <Header />
      <div className="content content2 scrollbar">
        <div className="secondHeader d-flex a-center bc-white pt-10 pb-10">
          <button className="button-with-icon" onClick={() => navigate(-1)}>
            <BackButtonIcon />
          </button>
          <span className="fs-30 fw-700 a-center d-flex j-center" style={{flexGrow: 0.7}}>주소검색</span>
        </div>
        <div className="">
          <DaumPostcode style={{ width: '100%', height: '800px' }} onComplete={handlePostCode} />
        </div>
      </div>
    </section>
  );
};

export default PostCode;
