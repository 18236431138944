import React, { useEffect, useState } from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import { format } from "date-fns";
import { pageLoadingEvent, useGetCookies } from "utils/commonUtil";
import { ReactComponent as MapPinIcon } from "assets/icon/mapPin.svg";
import homeBtn1 from "assets/image/home/homeBtn1.webp";
import homeBtn2 from "assets/image/home/homeBtn2.webp";
import writeBtn from "assets/image/button/writeBtn.webp";
import mapBtn from "assets/image/home/mapBtn.webp";
import { useNavigate } from "react-router-dom";
import TwoImageBox from "../components/TwoImageBox";
import CommonPopup from "components/popup/CommonPopup";
import PopupCheckSVG from 'assets/icon/popupCheck.svg'
import TodayBox from "../components/home/TodayBox";
import OutLinkTwoImageBox from "../components/home/OutLinkTwoImageBox";
import HomeSlider from "../components/home/HomeSlider";
import "assets/css/home.scss";
import { useGetHomeNoticeListApi } from "../hooks/noticeHooks";
import { getDashboardInfo, getPopupInfo } from "api/dashboard";
import { weatherDisplay } from "data/weatherData";
import { apiWithAuth } from 'api/apiHelpers';
import { wasUrl } from 'utils/commonUtil';
import { useCookies } from 'react-cookie';
import NoticePopup from "components/home/NoticePopup";
import { ReactComponent as WeatherLightgray } from 'assets/icon/weatherLightgray.svg'

const Home = () => {
  const [isFirstRegister, setIsFirstRegister] = useState(false);
  const [hasNewTrade, setHasNewTrade] = useState(false);
  const [newTradeCount, setNewTradeCount] = useState(0);
  const [list, setList] = useState([]);
  const [dashboardInfo, setDashboardInfo] = useState(null); // Dashboard 정보를 관리하는 상태 추가
  const navigate = useNavigate();
  const memberId = localStorage.getItem("userId");
  const [cookies, setCookie] = useCookies(['authToken', 'refreshToken']);
  const authToken = cookies.authToken;
  const refreshToken = cookies.refreshToken;

  const [popupData, setPopupData] = useState(null); // 팝업 데이터를 저장하는 상태
  const [showPopup, setShowPopup] = useState(false); // 팝업 표시 여부를 제어하는 상태
  const [showNotReadyPopup, setShowNotReadyPopup] = useState(false); // 팝업 표시 여부를 제어하는 상태

  const today = new Date();
  const formatString = format(today, "yyyy-MM-dd");
  const currentHour = today.getHours();

  const cookieData = useGetCookies();

  useGetHomeNoticeListApi({ setList }, { page: 1, count: 10, date: formatString });

  const urlQueryParam = new URLSearchParams(window.location.search);

  const fetchPopupData = async () => {
    try {
      const response = await getPopupInfo();
      if (response.list && response.list.length > 0) {
        setPopupData(response.list[0]); // 첫 번째 팝업 데이터를 저장
        setShowPopup(true); // 팝업 표시
      }
    } catch (error) {
      console.error("팝업 정보를 가져오는 중 오류 발생:", error);
    }
  };
  useEffect(() => {
    fetchPopupData();
  }, []);

  // 팝업을 닫는 함수
  const handleClosePopup = () => {
    setShowPopup(false); // 팝업을 닫기
  };


  const registerBtnAction = (() => {
    const url = window.location.origin + window.location.pathname;
    window.history.replaceState(null, '', url); // URL에서 쿼리 파라미터 제거
    setIsFirstRegister(false);
  });

  const goToNewTrade = () => {
    navigate('/myTradeList'); // 링크 수정
  };

  const fetchDashboardInfo = async () => {
    try {
      const info = await getDashboardInfo(cookieData);
      setDashboardInfo(info); // Dashboard 정보를 상태에 저장
    } catch (error) {
      console.error("Dashboard 정보를 가져오는 중 오류 발생:", error);
    }
  };

  // Dashboard 정보를 가져오는 useEffect
  useEffect(() => {
    pageLoadingEvent();

    fetchDashboardInfo();
    setShowPopup(true);  // 팝업을 무조건 보여줍니다.
    setPopupData({
      list: [
        {
          id: 1,
          description: "테스트 팝업입니다.",
          imageUrl: "https://via.placeholder.com/300x150.png?text=Test+Popup"
        }
      ]
    });

    if (urlQueryParam.get('registered')) {
      setIsFirstRegister(true);
    }

    if (memberId && authToken && refreshToken) {
      const url = `${wasUrl}trade/request/count/new`; // 새 거래 수를 가져오는 URL

      apiWithAuth(url, 'get', null, authToken, refreshToken, setCookie) // 쿠키와 함께 전달
        .then(response => {
          const count = response.data.newTradeRequestCount;
          if (count > 0) {
            setNewTradeCount(count);
            setHasNewTrade(true);
          }
        })
        .catch(error => {
          console.error("새로운 거래 수를 가져오는 중 오류 발생:", error);
        });
    }

    const confirmBtn = document.querySelector('#newTradePopup .twoBtn');
    if (confirmBtn) {
      confirmBtn.addEventListener('click', goToNewTrade);
    }

    const registerPopupBtn = document.querySelector('#registerPopup .oneBtn');
    registerPopupBtn.addEventListener('click', registerBtnAction);

    return () => {
      if (confirmBtn) {
        confirmBtn.removeEventListener('click', goToNewTrade);
      }
      registerPopupBtn.removeEventListener('click', registerBtnAction);
    };
  }, []);

  // 박스 크기 조절 함수
  const boxResize = () => {
    let homeSlider = document.querySelectorAll(".homeSlider");

    homeSlider.forEach((element) => {
      let width = document.querySelector(".content").offsetWidth;
      let height = width * 0.26;
      element.style.height = `${height}px`;
    });
  };

  window.addEventListener('resize', boxResize);

  useEffect(() => {
    boxResize();
  }, []);

  let weatherInfo;
  let newTradeExist;
  let weatherDisplayInfo;
  let weatherIcon = '기본 아이콘 경로'; // 기본 아이콘 경로 설정
  let weatherText = '날씨 정보 없음';  // 기본 날씨 텍스트 설정
  let tempDiff = 0; // 기본값으로 0 설정

  // dashboardInfo가 있고 weatherInfo가 존재할 때만 처리
  if (dashboardInfo && dashboardInfo.weather) {
    weatherInfo = dashboardInfo.weather;
    newTradeExist = dashboardInfo.existNewNoticeForMyTrade;

    // weatherType이 정의되어 있으면 weatherDisplay 호출
    if (weatherInfo.weatherType) {
      weatherDisplayInfo = weatherDisplay(weatherInfo.weatherType, currentHour);
      weatherIcon = weatherDisplayInfo.icon;
      weatherText = weatherDisplayInfo.text;
    }

    // todayCelsius와 yesterdayCelsius가 모두 정의되어 있을 때 tempDiff 계산
    if (typeof weatherInfo.todayCelsius !== 'undefined' && typeof weatherInfo.yesterdayCelsius !== 'undefined') {
      tempDiff = weatherInfo.todayCelsius - weatherInfo.yesterdayCelsius;
    }
  }

  // return 부분에서는 weatherIcon, weatherText, tempDiff 등의 값을 항상 사용할 수 있도록 설정
  return (
    <>
      <section id="home" className="container bc-lightGray10">
        <Header />
        <div className="content homeContent scrollbar">
          <div className="inner mb-20">
            <TodayBox />
            <article className="bc-white br-9 pt-15 pr-20 pb-25 pl-20 mb-40"
              onClick={() => navigate("/weather")}
              style={{ cursor: "pointer" }}
            >
              {dashboardInfo && (
                typeof weatherInfo !== 'undefined'
                  ? <>
                    <div className="d-flex a-center j-between mb-20">
                      <div className="d-flex a-center j-left">
                        <MapPinIcon width={17} height={21} style={{ '--path-color': '#51CFB2', marginRight: 8 }} />
                        <span className="fs-24 fw-5 ls-2">함평군</span>
                      </div>
                      <div className="fs-18 fw-6 ls-3 mr-15">어제보다
                        <span className="fc-green1">{` ${Math.abs(tempDiff)}˚`}</span>
                        <span>{` ${tempDiff >= 0 ? '높음' : '낮음'}`}</span>
                      </div>
                    </div>
                    <div className="d-flex a-center j-between">
                      <div className="weatherBox" style={{ width: '50%' }}>
                        <div className="d-flex a-center j-left">
                          <img className="mr-20" src={weatherIcon} alt="weather1" style={{ width: '33.33%', aspectRatio: '1' }} />
                          <div style={{ width: '60%' }}>
                            <p className="d-flex f-row a-center j-left fs-24 fw-6 mb-2">
                              {weatherText}
                              <span className="fs-38 fw-6 fc-green3 ml-8">
                                {`${weatherInfo?.todayCelsius ?? 'N/A'}˚`}
                              </span>
                            </p>
                            <p className="fs-18 fw-5">강수확률 <span>{weatherInfo?.rainProbability ?? 'N/A'}</span>%</p>
                          </div>
                        </div>
                      </div>
                      <div className="weatherBox" style={{ width: '50%' }}>
                        <div className="d-flex a-start j-right ml-20">
                          <div className="d-flex f-column a-end j-center" style={{ width: '80%' }}>
                            <p className="fs-20 fw-4 mb-10 fc-mediumGray2 mr-20">↑ 최고기온
                              <span className="fs-24 fw-5 fc-black1">{weatherInfo?.todayMaxCelsius ?? 'N/A'}
                              </span>
                              ˚
                            </p>
                            <p className="fs-20 fw-4 fc-mediumGray2 mr-20">↓ 최저기온
                              <span className="fs-24 fw-5 fc-black1">
                                {weatherInfo?.todayMinCelsius ?? 'N/A'}
                              </span>
                              ˚
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  : <div className="d-flex f-column a-center j-center">
                    <WeatherLightgray style={{ width: 44, height: 44 }} />
                    <span className="fs-22 fw-5 fc-mediumGray2">기상청의 날씨 정보를 불러오지 못했습니다.</span>
                    <span className="fs-22 fw-5 fc-mediumGray2">불편을 드려 죄송합니다.</span>
                  </div>
              )}
            </article>

            <div className="mb-20">
              <TwoImageBox
                id="homeBtnBox"
                image1={homeBtn1}
                alt1="이웃과 거래하기"
                url1="/tradeMain"
                image2={homeBtn2}
                alt2="함평군 농업기계 임대 신청"
                url2="/center"
              />
            </div>

            <button
              className="mapBtnBox w-100"
              onClick={() => navigate("/Map")}
            >
              <img className="d-block" src={mapBtn} alt="mapBtnImage" loading="lazy" />
            </button>
          </div>

          <div className={`homeSlider w-100 ${typeof list === "undefined" || list.length === 0 ? "d-none" : ""}`}>
            <HomeSlider list={list} />
          </div>

          <div className="inner mt-20 mb-20">
            <OutLinkTwoImageBox />
          </div>

          <button
            className="fixed-write-button"
            onClick={() => navigate("/menuwrite")}
          >
            <img src={writeBtn} alt="글쓰기" loading="lazy" />
          </button>
          <div className="homeFooter bc-darkGray11 w-100">
            <div className="inner d-flex f-column a-start j-left">
              <span className="fs-18 fw-6 fc-lightGray2">함평군 농기계서비스 두레</span>
              <span className="fs-18 fw-6 fc-lightGray2 mt-8">고객센터 061-320-2502</span>
              <span className="fs-14 fw-4 fc-lightGray16 mt-4">전남 함평군 학교면 학교화산길 90</span>
              {/* <span className="fs-14 fw-4 fc-lightGray16 mt-4">T.061-320-2500,2480</span> */}
              <span className="fs-14 fw-4 fc-lightGray16 mt-4">{"COPYRIGHT(c) HAMPYEONG COUNTY. ALL RIGHTS RESERVED"}</span>
            </div>
          </div>
        </div>
        {showPopup && popupData && (
          <NoticePopup popupData={popupData} onClose={handleClosePopup} />
        )}
        <Footer hasNewTrade={hasNewTrade} />
      </section>
      <CommonPopup
        id='notReadyPopup'
        title1='서비스 '
        title2='준비중'
        title3='입니다.'
        text='고객님의 편의를 위해 빠른 시일 내에 서비스를 준비 중에 있습니다. 곧 더 나은 모습으로 찾아뵙겠습니다.'
        btnText1='확인'
        btnEvent1={false}
        status={showNotReadyPopup}
        setStatus={setShowNotReadyPopup}
      />
      <CommonPopup
        id='registerPopup'
        image={PopupCheckSVG}
        title1='회원가입이 '
        title2='완료'
        title3='되었어요!'
        text='편리한 농촌 생활을 위한 두레의 다양한 서비스를 이용하세요.'
        btnText1='확인'
        btnEvent1={true}
        status={isFirstRegister}
        setStatus={setIsFirstRegister}
      />
      <CommonPopup
        id='newTradePopup'
        title1='새로운 거래신청 '
        title2={`${newTradeCount}`}
        title3='건이 도착했어요!'
        btnText1='닫기'
        btnText2='확인하기'
        btnType='two'
        status={hasNewTrade}
        setStatus={setHasNewTrade}
        btnEvent1={false}
      />
    </>

  );

};

export default Home;
