import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TradeTabElement from './TradeTabElement';

const TradeTabBar = ({ tabs, onTabClick, activeTabIndex }) => {
    const [parentWidth, setParentWidth] = useState(0);
    const tabBarRef = useRef(null);

    useEffect(() => {
        if (tabBarRef.current) {
            setParentWidth(tabBarRef.current.offsetWidth);
        }
    }, []); // 빈 배열을 사용하여 컴포넌트가 마운트될 때 한 번만 실행

    const tabWidth = tabs.length > 0 ? `${parentWidth / tabs.length}px` : '0px';

    return (
        <div className="tradeTabBar bb-1-mediumGray27 a-center j-between"
            style={{ width: '100%' }}>
            <div className="tabButtons ml-10 mr-10 d-flex a-center j-center" ref={tabBarRef}>
                {tabs.map((tab) => (
                    <TradeTabElement
                        key={tab.index}
                        isActive={tab.index === activeTabIndex}
                        label={tab.name}
                        onClick={() => onTabClick(tab.index)}
                        selected={tab.index === activeTabIndex}
                        width={tabWidth}
                    />
                ))}
            </div>
        </div>
    );
};

TradeTabBar.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    onTabClick: PropTypes.func.isRequired,
    activeTabIndex: PropTypes.number.isRequired,
};

export default TradeTabBar;
