import React, { useEffect, useState } from "react";
import { getElementValue, getParameter, pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import BackHeader from "components/header/BackHeader";
import CommonBarBtn from "components/button/CommonBarBtn";
import CommonPopup from "components/popup/CommonPopup";
import CommonInputBox from "components/CommonInputBox";
import CommonAreaBox from "components/CommonAreaBox";
import { useGetQnaDetailApi } from "hooks/inquiryHooks";
import { removeQnaApi, setQnaApi } from "api/inquiryApi";
import removeBtn from "assets/image/button/removeBtn.svg";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';  // 쿠키 사용
import  "assets/css/inquiry.scss";

const InquiryUpdate = () => {
  const id = Number(getParameter("id"));
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState(0);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);

  const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 가져오기
  const authToken = cookies.authToken;  // 쿠키에서 authToken 값 추출

  useGetQnaDetailApi({ setTitle, setContent, setStatus }, id, authToken);  // authToken 전달

  useEffect(() => {
    pageLoadingEvent();

    const completeBtn = document.getElementById('completeBtn');
    completeBtn.addEventListener('click', handleConfirm);
    const confirmBtn = document.querySelector('#inquiryPopup .twoBtn');
    confirmBtn.addEventListener('click', removeEvent);

    return () => {
      completeBtn.removeEventListener('click', handleConfirm);
      confirmBtn.removeEventListener('click', removeEvent);
    };
  }, []);

  const removeEvent = async () => {
    const removeData = {
      writerType: 2,
      userId: userId
    };

    try {
      await removeQnaApi(id, removeData, authToken);  // authToken 전달
      navigate("/inquiry?page=1&active=문의내역", { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = async () => {
    const getTitle = getElementValue("title");
    const getContent = getElementValue("content");

    if (!getTitle || !getContent) {
      setErrorMessage(notInputParams);
      setIsWarningPopupOpen(true);
      return false;
    }

    const setData = {
      title: getTitle,
      content: getContent
      
    };

    try {
      await setQnaApi(id, setData, authToken);  // authToken 전달
      navigate(`/InquiryDetail?id=${id}`, { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  const notInputParams = () => {
    return `${title ? '' : '제목'}${!title && !content ? ', ' : ''}${content ? '' : '문의 내용'}`;
  };

  return (
    <section id="Inquiry" className="inquiryDetail container">
      <Header />
      <div className="content content6 scrollbar">
        <BackHeader title='1:1 문의' />
        <div className="inner">
          <div className="mb-40 mt-30">
            <CommonInputBox
              id="title"
              title="제목"
              maxLength={30}
              placeholder="제목을 입력해 주세요(30자 이내)"
              value={title}
              setValue={setTitle}
              activeEvent={true}
            />
          </div>

          <CommonAreaBox
            id="content"
            title="문의내용"
            maxLength={100}
            placeholder="문의내용을 입력해 주세요(100자 이내)"
            value={content}
            setValue={setContent}
            activeEvent={true}
          />
        </div>
      </div>

      <div className={`inner ${status === false ? "" : "d-none"}`}>
        <div className="btnBox d-flex a-center j-between">
          <button className="removeBtn" onClick={() => setIsRemovePopupOpen(true)}>
            <img src={removeBtn} alt="삭제" />
          </button>
          <div className="setBox ">
            <CommonBarBtn
              id='completeBtn'
              active={true}
              text='작성완료'
              activeText='작성완료'
            />
          </div>
        </div>
      </div>

      <CommonPopup
        id='notInputPopup'
        title1=''
        title2={errorMessage}
        title3=' 항목을 입력해주세요.'
        text=''
        btnText1='확인'
        btnEvent1={false}
        status={isWarningPopupOpen}
        setStatus={setIsWarningPopupOpen}
      />
      <CommonPopup
        id='inquiryPopup'
        title1='문의글을'
        title2='삭제'
        title3='하시겠어요?'
        text='문의글 삭제 시 복구가 불가능합니다.'
        btnType='two'
        btnText1='취소'
        btnText2='삭제하기'
        btnEvent1={false}
        status={isRemovePopupOpen}
        setStatus={setIsRemovePopupOpen}
      />
    </section>
  );
};

export default InquiryUpdate;
