import { wasUrl } from "utils/commonUtil";
import { apiWithAuth } from "./apiHelpers";
import axios from "axios";

export const getDashboardInfo = async (cookieDatas) => {
    try {
        const response = await apiWithAuth(
            `${wasUrl}dashboard/member`,
            'get',
            null,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return response.data;
    } catch (e) {
        console.log(e);
    }
};

export const getPopupInfo = async () => {
    const response = await axios.get(`${wasUrl}popup/member`);

    return response.data
}