import BackHeader from "components/header/BackHeader";
import Header from "components/header/Header";
import React, { useEffect } from "react";
import {openPopup, pageLoadingEvent} from "utils/commonUtil";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CenterSafety } from 'assets/icon/centerSafety.svg';
import { ReactComponent as CenterObserve } from 'assets/icon/centerObserve.svg';
import Terms1 from "components/popup/Terms1";
import Terms2 from "components/popup/Terms2";

const TradeCenterSelect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    pageLoadingEvent();
  }, []);

  return (
    <>
      <Terms1 />
      <Terms2 />
      <section id="tradeCenterItems" className="centerItems container bc-white">
        <Header />
        <div className='content homeContent scrollbar'>
          <BackHeader title='함평군 농업기계 임대 신청' />
          <div className="inner">
            <div className="centerListSubTitleBox d-flex f-column a-center j-center ta-left w-100 mt-30 mb-30">
              <p className="fs-32 fw-7 fc-black1 w-100">
                함평군 농업기계임대사업소
              </p>
              <p className="fs-32 fw-7 fc-black1 w-100">
                <span className="fc-green1">임대 장비를 선택</span>
                해주세요
              </p>
            </div>
            <div className="centerListBtnBox d-flex f-row a-center j-left w-100 mb-30">
              <button
                className="safetyBtn d-flex f-row a-center j-center bc-lightGray9 mr-15"
                onClick={() => openPopup("terms2Popup")}
              >
                <CenterSafety />
                <span className="fs-20 fw-6 fc-darkGray10 ml-5">
                안전수칙
              </span>
              </button>
              <button
                className="observedBtn d-flex f-row a-center j-center bc-lightGray9"
                onClick={() => openPopup("terms1Popup")}
              >
                <CenterObserve />
                <span className="fs-20 fw-6 fc-darkGray10 ml-5">
                준수사항
              </span>
              </button>
            </div>
            <article className="d-flex a-center j-left mb-25">
              <div className="categoryImageBox"></div>
              <p className="fs-28 fw-7">휴립복토기-피복기</p>
            </article>
            <article className="itemList" onClick={() => navigate("/center/detail")}>
              <div className="itemBox d-flex a-start j-between">
                <div className="imageBox">

                </div>
                <div className="textBox fs-22">
                  <p className="fw-7 mb-5">장비그룹</p>
                  <p className="mb-5">기종명</p>
                  <p className="mb-5">형식</p>
                  <p className="mb-5">규격</p>
                  <p className="mb-5">최대 임대 일수: 3일</p>
                  <p className="fw-5 fc-red1">임대료: 5,000원(일 기준)</p>
                </div>
              </div>
              <div className="itemBox d-flex a-start j-between">
                <div className="imageBox">

                </div>
                <div className="textBox fs-22">
                  <p className="fw-7 mb-5">장비그룹</p>
                  <p className="mb-5">기종명</p>
                  <p className="mb-5">형식</p>
                  <p className="mb-5">규격</p>
                  <p className="mb-5">최대 임대 일수: 3일</p>
                  <p className="fw-5 fc-red1">임대료: 5,000원(일 기준)</p>
                </div>
              </div>
              <div className="itemBox d-flex a-start j-between">
                <div className="imageBox">

                </div>
                <div className="textBox fs-22">
                  <p className="fw-7 mb-5">장비그룹</p>
                  <p className="mb-5">기종명</p>
                  <p className="mb-5">형식</p>
                  <p className="mb-5">규격</p>
                  <p className="mb-5">최대 임대 일수: 3일</p>
                  <p className="fw-5 fc-red1">임대료: 5,000원(일 기준)</p>
                </div>
              </div>
              <div className="itemBox d-flex a-start j-between">
                <div className="imageBox">

                </div>
                <div className="textBox fs-22">
                  <p className="fw-7 mb-5">장비그룹</p>
                  <p className="mb-5">기종명</p>
                  <p className="mb-5">형식</p>
                  <p className="mb-5">규격</p>
                  <p className="mb-5">최대 임대 일수: 3일</p>
                  <p className="fw-5 fc-red1">임대료: 5,000원(일 기준)</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
};

export default TradeCenterSelect;