import React from "react";
import Slider from "react-slick";
import {useNavigate} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

const HomeSlider = ({list}) => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    sliderToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
  }

  return (
    <Slider {...settings}>
     {list.map(({id, imageUrl}, index) => {
        return (
          <div key={index} onClick={() => navigate(`/noticeDetail?id=${id}`)}>
             <img src={imageUrl} alt={`notice-${id}`} />  {/* imageUrl을 사용 */}
          </div>
        )
      })}
    </Slider>
  );
};

export default React.memo(HomeSlider);