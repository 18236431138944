import React, {useEffect, useState} from "react";
import {addDays, format, isBefore, parseISO, startOfWeek} from "date-fns";
import Header from "components/header/Header";
import BackHeader from "components/header/BackHeader";
import {closePopup, getParameter, getRadioValue, openPopup, pageLoadingEvent} from "utils/commonUtil";
import {ReactComponent as CenterX} from "assets/icon/centerX.svg";
import {ReactComponent as CheckBtn} from "assets/icon/circle-checkButton.svg";
import {ReactComponent as LineCircle} from "assets/icon/line-circle.svg";
import {ReactComponent as XButton} from "assets/icon/xButton.svg";
import {ReactComponent as Radio} from "assets/icon/radio-fill.svg";
import "assets/css/center.scss";
import {useCookies} from "react-cookie";
import {getRentMachineApi, postRentApi} from "../../../api/centerApi";

const TradeCenterDetail = () => {
  const getCenter = getParameter("center") || "본부";
  const getNo = getParameter("no") || "";
  const getMachine = getParameter("machine") || "동력파쇄기";
  const getName = getParameter("name") || "목재파쇄기(18마력)";
  const getRentPrice = Number(getParameter("price")) || 0;
  const getFormat = getParameter("format") || "DLK18TRC";
  const getStandard = getParameter("standard") || "파쇄기";
  const [dayValue, setDayValue] = useState(0);
  const [dayList, setDayList] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  const disabledDates = [];
  const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 가져오기
  const authToken = cookies.authToken;

  useEffect(() => {
    if (dayValue > 0 && dayList.length === dayValue && checkValue) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
  }, [dayValue, dayList, checkValue])

  // 오늘 날짜부터 2주일 동안의 날짜를 가져오는 함수
  const getFutureDates = () => {
    const dates = [];
    const today = new Date();
    const startDate = startOfWeek(today, { weekStartsOn: 0 }); // 주의 시작을 일요일로 설정

    for (let i = 0; i < 17; i++) {
      const futureDate = addDays(startDate, i);
      dates.push(futureDate);
    }
    return dates;
  };

  // 날짜를 주 단위로 그룹화하는 함수
  const groupByWeek = (dates) => {
    const weeks = [];
    for (let i = 0; i < dates.length; i += 7) {
      weeks.push(dates.slice(i, i + 7));
    }
    return weeks;
  };

  const futureDates = getFutureDates();
  const groupedDates = groupByWeek(futureDates);
  const today = new Date();
  useEffect(() => {
    pageLoadingEvent();
  }, []);

  const dayBtnEvent = (value) => {
    setDayValue(value);
    setDayList([]);
    const td = document.querySelectorAll("tbody td");
    td.forEach((element) => {
      element.classList.remove("active");
    });
  }

  const selectDayEvent = (e) => {
    const target = e.target;
    const value = target.getAttribute("data-value");

    if (!value) return false;
    if (target.classList.contains("disabled")) return false;

    const [year, month, day] = value.split('-').map(Number);
    const currentDate = new Date(year, month - 1, day);

    const isAdjacentDay = (currentValue) => {
      const [currentYear, currentMonth, currentDay] = currentValue.split('-').map(Number);
      const current = new Date(currentYear, currentMonth - 1, currentDay);

      // 전날 또는 다음날인지 확인
      const diffInDays = (current - currentDate) / (1000 * 60 * 60 * 24);
      return diffInDays === 1 || diffInDays === -1;
    };

    if (target.classList.contains("active")) {
      target.classList.remove("active");
      setDayList((prevList) =>
        prevList.filter((item) => item !== value)
      );
    } else {
      if (dayList.length < dayValue) {
        const canActivate = dayList.some(isAdjacentDay);

        if (canActivate || dayList.length === 0) {
          target.classList.add("active");

          setDayList((prevList) => {
            const newList = [...prevList, value];
            // 날짜순으로 정렬
            return newList.sort((a, b) => new Date(a) - new Date(b));
          });
        }
      }
    }
  };

  const addEvent = async () => {
    const data = {
      machineNo: getNo,
      reservationDate: dayList[0],
      shipmentType: Number(getRadioValue("shipmentType")),
      reservationDays: dayValue
    }

    console.log(data);

    try {
      /*await postRentApi(data, authToken);*/
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <div id="checkPopup" className="popupContainer termsPopup d-flex a-center j-center d-none">
        <div className="popupBody bc-white p-relative">
          <XButton width={52} height={52} onClick={() => closePopup("checkPopup")} />
          <div className="inner">
            <div className="popupHead fs-22 fw-7 ls-3">
              사용조건 및 준수사항
            </div>
            <ul className="textArea scrollbar fs-18 fw-5 ls-3">
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임차인은 농기계를 내 것처럼 아껴서 사용한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>사용 후 보관시는 깨끗이 세척한 후 안전한 창고 내에 보관한다.(1일 이상 사용할 때)</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임차 후 발생한 고장수리비는 임차인이 부담한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>운전미숙, 부주의 등 임차인 과실로 발생한 고장은 임차인이 책임 수리한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임차인이 농기계의 운반 및 사용 중 발생한 사고에 대해서는 민ㆍ형사상의 모든 책임을 진다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>사용 중 농기계를 분실 또는 파손하였을 때에는 임차인이 보상한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임대기간 만료시에는 깨끗이 세척 후 지체없이 반납한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임대기간 중 농기계의 유지, 보수 및 기본운영에 필요한 소요경비(수리비, 연료비, 농작업상해공제 등)는 임차인이 부담한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>안전사고에 대비하여 임차인은 임대농기계를 대상으로 농기계종합공제에 의무적으로 가입하여야함.(별도 안전사고 부담 각서 제출)</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>예약취소시 2일간 임대신청이 정지 됩니다. 우천으로 취소시 임대사업장으로 취소 요청 하시기 바랍니다.</p>
              </li>
            </ul>
            <div className="btnBox">
              <button
                className="checkBtn fs-22 fc-white fw-7 ls-3"
                onClick={() => {
                  closePopup("checkPopup");
                  setCheckValue(true);
                }}
              >
                동의하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="resultPopup" className="popupContainer d-flex a-center j-center d-none">
        <div className="popupBody bc-white scrollbar">
          <div className="inner">
            <div className="textArea ta-center fs-28 fw-7">
              <p className="fc-green11">{getName}</p>
              <p className="fc-red1">{dayList.map(date => format(parseISO(date), 'M/d')).join(', ')}</p>
              <p>임대신청 할까요?</p>
            </div>
            <div className="btnArea d-flex a-center j-between fs-24 fw-7 fc-white">
              <button
                className="oneBtn bc-mediumGray9 half"
                onClick={() => closePopup("resultPopup")}
              >
                취소
              </button>
              <button
                className="twoBtn half bc-green1"
                onClick={addEvent}
              >
                임대 신청
              </button>
            </div>
          </div>
        </div>
      </div>
      <section id="tradeCenterDetail" className="container bc-lightGray12">
        <Header />
        <div className="content content4 scrollbar">
          <BackHeader title="함평군 농업기계 임대 신청" />
          <article className="bc-white mb-15">
            <div className="inner bb-1-lightGray17 mb-20">
              <p className="fw-7 fs-28 ls-3 fc-green1 pt-20 mb-20">{getName}</p>
            </div>
            <div className="inner pb-15">
              <p className="fw-7 fs-22 mb-20">장비 정보</p>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">임대장소</p>
                <p>{getCenter}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">장비그룹</p>
                <p>{getMachine}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">기종명</p>
                <p>{getName}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">형식</p>
                <p>{getFormat}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">규격</p>
                <p>{getStandard}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">임대료</p>
                <div className="ta-right">
                  <p className="fs-28 fc-red1 fw-7">{getRentPrice.toLocaleString()}원</p>
                  <p className="fs-18 d-flex a-center j-right">(임대료 {getRentPrice.toLocaleString()}원 <CenterX className="centerX" /> 1일)</p>
                </div>
              </div>
            </div>
          </article>
          <article className="bc-white mb-15">
            <div className="inner pt-25 pb-20">
              <p className="fw-7 fs-22 mb-20">임대일</p>
              <div className="d-flex a-center j-left">
                <button
                  className={`dayBtn ${dayValue === 1 ? "active fw-6 fs-24" : "fs-22"}`}
                  onClick={() => dayBtnEvent(1)}
                >
                  1
                </button>
                <button
                  className={`dayBtn ${dayValue === 2 ? "active fw-6 fs-24" : "fs-22"}`}
                  onClick={() => dayBtnEvent(2)}
                >
                  2
                </button>
                <button
                  className={`dayBtn ${dayValue === 3 ? "active fw-6 fs-24" : "fs-22"}`}
                  onClick={() => dayBtnEvent(3)}
                >
                  3
                </button>
                <p className="fs-22 fw-5">일 <span className="fc-red1">(최대 임대 일수: 3일)</span></p>
              </div>
            </div>
          </article>
          <article className="bc-white mb-15">
            <div className="inner pt-25 pb-15">
              <p className="fw-7 fs-22 mb-5">임대 예약일</p>
              <p className="fs-18 fw-5 fc-mediumGray2 mb-35">2주 이내의 예약만 가능합니다.</p>
              <table className="dayTable">
                <thead>
                <tr className="fs-20 fw-5 ta-center">
                  <th className="fc-red3">일</th>
                  <th>월</th>
                  <th>화</th>
                  <th>수</th>
                  <th>목</th>
                  <th>금</th>
                  <th className="fc-blue4">토</th>
                </tr>
                </thead>
                <tbody className="fs-20 ta-center tbody">
                {groupedDates.map((week, index) => (
                  <tr key={index}>
                    {week.map((date, idx) => {
                      const formattedDate = format(date, 'M/d'); // 날짜를 M/d 형식으로 변환
                      const isDisabled = disabledDates.includes(formattedDate); // 날짜가 disabledDates에 있는지 확인

                      return (
                        <td
                          key={idx}
                          data-value={isBefore(date, today) || isDisabled ? '' : format(date, "yyyy-MM-dd")}
                          className={isBefore(date, today) ? 'none' : isDisabled ? 'disabled' : ''}
                          onClick={!isDisabled ? (e) => selectDayEvent(e) : null} // 비활성화된 날짜는 클릭 불가
                        >
                          {isBefore(date, today) ? '' : isDisabled ? '불가능' : formattedDate}
                        </td>
                      );
                    })}
                    {/* 주어진 주의 날짜가 7일 미만인 경우 나머지 셀 비워두기 */}
                    {week.length < 7 && [...Array(7 - week.length)].map((_, emptyIdx) => <td key={emptyIdx} className="none"></td>)}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </article>
          <article className="bc-white mb-15">
            <div className="inner pt-25 pb-15" onClick={() => openPopup("checkPopup")}>
              <p className="fw-7 fs-22 mb-15">사용조건 및 준수사항</p>
              <div className={`checkBox d-flex a-center j-left fs-20 ${checkValue ? "active" : ""}`}>
                <CheckBtn className="mr-10" />
                사용조건 및 준수사항을 확인 후 동의합니다.
              </div>
            </div>
          </article>
          <article className="bc-white">
            <div className="inner pt-25 pb-15">
              <p className="fw-7 fs-22 mb-5">배송 종류</p>
              <div className="d-flex a-center j-left radioBox">
                <input id="shipmentType1" type="radio" name="shipmentType" hidden value={1} defaultChecked />
                <label className="d-flex a-center j-left mr-30" htmlFor="shipmentType1">
                  <span className="back d-block"></span>
                  <p className="fs-22">자가배송</p>
                </label>
                <input id="shipmentType2" type="radio" name="shipmentType" hidden value={2} />
                <label className="d-flex a-center j-left" htmlFor="shipmentType2">
                  <span className="back d-block"></span>
                  <p className="fs-22">센터배송</p>
                </label>
              </div>
            </div>
          </article>
        </div>
        <article className="bottomBtnBox bc-white d-flex a-center j-center">
          <div className="inner">
            <button
              className={`commonBarBtn w-100 br-7 fc-white fw-7 fs-28 m-auto ${btnActive ? "bc-green1" : "bc-darkGray20"}`}
              disabled={!btnActive}
              onClick={() => openPopup("resultPopup")}
            >
              임대 신청하기
            </button>
          </div>
        </article>
      </section>
    </>
  );
};

export default TradeCenterDetail;