import React, { useState, useEffect } from 'react';
import { ReactComponent as RightArrow } from "assets/icon/rightArrow.svg";
import { ReactComponent as XButton } from "assets/icon/xButton.svg";
import { ReactComponent as Camera } from "assets/icon/cameraSVG.svg";
import { ReactComponent as Lock } from "assets/icon/lock.svg";
import { ReactComponent as LockColor } from "assets/icon/lock-color.svg";
import { ReactComponent as EnterIcon } from "assets/icon/enterIcon.svg";
import { ReactComponent as MenuColumn } from "assets/icon/menuColumn.svg";
import { ReactComponent as SubmitBtn } from "assets/icon/submitBtn.svg";
import { ReactComponent as CancelBtn } from "assets/icon/cancelBtn.svg";
import { ReactComponent as ChangeBtn } from "assets/icon/changeBtn.svg"
import { changeComment, deleteComment, getCommentList, getReplyLists, postComment } from 'api/trade';
import TradeSnackBar from './TradeSnackBar';
import CommonPopup2 from 'components/popup/CommonPopup2';
import { pageLoadingEvent, useGetCookies } from 'utils/commonUtil';
import LoadingPopup from 'components/popup/LoadingPopup';
import { Navigate } from 'react-router-dom';

const TradeDetailComment = ({ tradeData }) => {
    const tradeId = tradeData.id;

    const tradeBodyData = {
        page: 1,
        count: 77,
        tradeId: tradeId
    }

    const cookieDatas = useGetCookies();

    const [commentList, setCommentList] = useState([]);
    const [replyLists, setReplyLists] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCommentsAndReplies = async () => {
        try {
            // 댓글 목록 가져오기
            const comments = await getCommentList(cookieDatas, tradeBodyData);
            setCommentList(comments);

            // 댓글 목록이 존재할 때만 답글 목록 가져오기
            if (comments) {
                const replies = await getReplyLists(cookieDatas, comments);
                setReplyLists(replies);
            }
        } catch (error) {
            console.error('댓글 및 답글을 가져오는 중 오류 발생:', error);
        } finally {
            setLoading(false); // 로딩 상태 종료
        }
    };
    useEffect(() => {
        fetchCommentsAndReplies();
    }, []);

    const [commentState, setCommentState] = useState({
        textAreaValues: {},
        replyVisible: {},
        isSecretComment: {},
        image: {},
    });

    const [commentPopup, setCommentPopup] = useState(false);

    const [popupParam, setPopupParam] = useState({
        data: null,
    });

    const [isCommentChange, setIsCommentChange] = useState({});

    const commentDisplayChange = (data, bool) => {
        setCommentPopup(false);
        setIsCommentChange(prevState => ({
            ...prevState,
            [data.id]: bool
        }))
    }

    const handleCommentSubmit = async (level, commentId) => {
        if (commentState.textAreaValues[commentId].length === 0) {
            showSnackBar('댓글 내용을 입력해주세요.');
            return;
        }
        const commentData = {
            tradeId: tradeId,
            upperCommentId: level === 2 ? commentId : null,
            level: level,
            content: commentState.textAreaValues[commentId] || '',
            isHidden: commentState.isSecretComment[commentId] ? 1 : 0,
        };

        const file = commentState.image[commentId];
        let addImageList = [];

        if (file) {
            try {
                addImageList = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        try {
                            const base64data = reader.result.split(',')[1];
                            const fileName = file.name.split('.').slice(0, -1).join('.');
                            const fileExtension = file.name.split('.').pop();
                            resolve([{
                                encodedFile: base64data,
                                fileName,
                                fileExtension,
                            }]);
                        } catch (error) {
                            reject(error);
                        }
                    };
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(file);
                });
            } catch (error) {
                alert('이미지 처리 중 문제가 발생했습니다.');
                console.error(error);
                return;
            }
        }

        const postData = { ...commentData, addImageList };

        let statusCode = await postComment(cookieDatas, postData);
        if (statusCode === 200) {
            setCommentState(prevState => ({
                ...prevState,
                textAreaValues: { ...prevState.textAreaValues, [commentId]: '' },
                isSecretComment: { ...prevState.isSecretComment, [commentId]: false },
                image: { ...prevState.image, [commentId]: null }
            }));
            snackBarDisplay(level === 1 ? '댓글이 등록되었습니다.' : '답글이 등록되었습니다.');
            fetchCommentsAndReplies();
        } else {
            snackBarDisplay(level === 1 ? '댓글 등록에 실패했습니다.' : '답글 등록에 실패했습니다.');
        }
    };

    const handleChangeComment = async (level, commentId) => {
        const commentData = {
            content: commentState.textAreaValues[commentId],
            isHidden: commentState.isSecretComment[commentId],
        };
        const file = commentState.image[commentId];
        let addImageList = [];

        if (file) {
            try {
                addImageList = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        try {
                            const base64data = reader.result.split(',')[1];
                            const fileName = file.name.split('.').slice(0, -1).join('.');
                            const fileExtension = file.name.split('.').pop();
                            resolve([{
                                encodedFile: base64data,
                                fileName,
                                fileExtension,
                            }]);
                        } catch (error) {
                            reject(error);
                        }
                    };
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(file);
                });
            } catch (error) {
                alert('이미지 처리 중 문제가 발생했습니다.');
                console.error(error);
                return;
            }
        }

        let postData = addImageList ? { ...commentData, addImageList } : commentData;
        let statusCode = await changeComment(cookieDatas, commentId, postData);

        if (statusCode === 200) {
            setCommentState(prevState => ({
                ...prevState,
                textAreaValues: { ...prevState.textAreaValues, [commentId]: '' },
                isSecretComment: { ...prevState.isSecretComment, [commentId]: false },
                image: { ...prevState.image, [commentId]: null },
                isCommentChange: { ...prevState.isCommentChange, [commentId]: false },
            }));
            snackBarDisplay(level === 1 ? '댓글이 수정되었습니다.' : '답글이 수정되었습니다.');
            fetchCommentsAndReplies();
        } else {
            snackBarDisplay(level === 1 ? '댓글 수정에 실패했습니다.' : '답글 수정에 실패했습니다.');
        }
    };

    const handleDeleteComment = async (data) => {
        const commentId = data.id;
        const statusCode = await deleteComment(cookieDatas, commentId);

        if (statusCode === 200) {
            snackBarDisplay(data.level === 1 ? '댓글이 삭제되었습니다.' : '답글이 삭제되었습니다.');
            fetchCommentsAndReplies();
        } else {
            snackBarDisplay(data.level === 1 ? '댓글 삭제에 실패했습니다.' : '답글 삭제에 실패했습니다.');
        }
        setCommentPopup(false);
    };

    const tabCommentMenu = (element) => {
        setPopupParam({
            data: element,
        });

        setCommentPopup(true);
    };

    // 상태 변경 함수
    const toggleState = (stateKey, commentId) => {
        setCommentState((prevState) => ({
            ...prevState,
            [stateKey]: {
                ...prevState[stateKey],
                [commentId]: !prevState[stateKey][commentId],
            },
        }));
    };

    const handleInputChange = (commentId, event) => {
        setCommentState((prevState) => ({
            ...prevState,
            textAreaValues: {
                ...prevState.textAreaValues,
                [commentId]: event.target.value,
            },
        }));
    };

    const getFormattedTimeStamp = (timestamp) => {
        const now = new Date();
        const commentTime = new Date(timestamp);
        const diffInMinutes = Math.floor((now - commentTime) / (1000 * 60));

        if (diffInMinutes < 60) {
            return `${diffInMinutes}분 전`;
        } else if (diffInMinutes < 1440) {
            return `${Math.floor(diffInMinutes / 60)}시간 전`;
        } else {
            return timestamp;
        }
    };

    // 파일 업로드 핸들러 (이미지 최대 크기 5MB)
    const handleImageUpload = (commentId, event) => {
        const file = event.target.files[0]; // 첫 번째 파일 선택
        if (file && file.size <= 5 * 1024 * 1024) { // 5MB 제한
            setCommentState(prevState => ({
                ...prevState,
                image: {
                    ...prevState.image,
                    [commentId]: file // 파일 객체 저장
                }
            }));
        } else {
            alert("이미지 크기는 5MB 이하만 가능합니다.");
        }
    };

    const convertFileToUrl = (file) => {
        if (file) {
            return URL.createObjectURL(file);
        } else {
            return '';
        }
    }

    const resetImg = ((commentId) => {
        setCommentState(prevState => ({
            ...prevState,
            image: {
                ...prevState.image,
                [commentId]: null // 이미지 초기화
            }
        }));
    })

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const snackBarDisplay = ((message) => {
        setSnackBarMessage(message);
        setShowSnackBar(true);
        setTimeout(() => {
            setShowSnackBar(false);
        }, 1000); // 1초 후 스낵바 숨기기
    })

    const clickInput = ((commentId) => {
        document.getElementById(`imageUpload-${commentId}`).click();
    });

    useEffect(() => {
        pageLoadingEvent();

        // 삭제 버튼 이벤트 리스너
        const handleDeleteClick = () => handleDeleteComment(popupParam.data);
        const deleteBtn = document.querySelector('#commentPopup .oneBtn');

        // 변경 버튼 이벤트 리스너
        const handleChangeClick = () => commentDisplayChange(popupParam.data, true);
        const changeBtn = document.querySelector('#commentPopup .twoBtn');

        // 팝업 닫기 버튼 이벤트 리스너
        const handleCloseClick = () => setCommentPopup(false);
        const xBtn = document.querySelector('#commentPopup .xbtn');

        if (deleteBtn && changeBtn && xBtn) {
            deleteBtn.addEventListener('click', handleDeleteClick);
            changeBtn.addEventListener('click', handleChangeClick);
            xBtn.addEventListener('click', handleCloseClick);
        }

        return () => {
            if (deleteBtn && changeBtn && xBtn) {
                deleteBtn.removeEventListener('click', handleDeleteClick);
                changeBtn.removeEventListener('click', handleChangeClick);
                xBtn.removeEventListener('click', handleCloseClick);
            }
        };
    }, [popupParam]);

    if (loading) {
        return <div>Loading...</div>; // 로딩 중일 때 표시할 컴포넌트
    }


    return (
        <div className='tradeDetailComment d-flex f-column bc-white' style={{ width: '100%' }}>
            <div className='inner'>
                <div className={`commentHeader d-flex f-row a-end j-left ${commentList.length ? 'pb-20' : ''}`} style={{ width: '91.66%', height: 54 }}>
                    <span className='fs-24 fw-6 fc-black2'>{"댓글 "}</span>
                    <span className={`fs-24 fw-6 ${commentList.length ? 'fc-green1' : 'fc-black2'} ml-4`}>{commentList.length}</span>
                </div>
            </div>
            <div className='commentList'>
                {commentList.map((element, index) => (
                    <div
                        id={`commentID:${element.id}`}
                        key={index}
                        className={`commentElement ${commentList[commentList.length - 1]?.id === element.id ? '' : 'bb-1-lightGray14'} ${element.writerName === localStorage.getItem('nickName') ? 'bc-lightGray11' : 'bc-white'}`}
                    >
                        <div className='inner'>
                            <div className={`commentElementHeader d-flex a-center ${element.writerName === localStorage.getItem('nickName') ? 'j-between' : 'j-left'} pt-20`} style={{ height: 30 }}>
                                <span className='author fs-22 fw-7 fc-black2'>
                                    {element.isHidden === 1 ? '*****' : element.writerName}
                                </span>
                                {element.writerName === localStorage.getItem('nickName') &&
                                    <button className='headerMenuBtn'
                                        style={{ width: 25, height: 25, objectFit: 'cover' }}
                                        onClick={() => tabCommentMenu(element)}
                                    >
                                        <MenuColumn style={{ width: '100%', height: '100%' }} />
                                    </button>
                                }
                            </div>
                            <div className='commentElementContent d-flex a-start j-left mt-15 mb-8'>
                                <span className={`fs-20 fw-4 ${element.isHidden === 1 ? 'fc-mediumGray16' : 'fc-black2'} ellipsis-30`}>
                                    {element.isHidden === 1 ? '비밀댓글입니다.' : element.content}
                                </span>
                            </div>
                            {element.isHidden !== 1 && element.imageUrl?.length > 0 && (
                                <div className='commentElementPhoto ba-1-mediumGray28 mb-20' style={{ width: 90, height: 90 }}>
                                    <img className='commentImg' src={element.imageUrl} alt="commentImage" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </div>
                            )}
                            <div className={`commentElementFooter d-flex a-end ${element.isHidden === 1 ? 'j-right' : 'j-between'} pb-20`}>
                                {element.isHidden !== 1 && (
                                    <button
                                        className='d-flex f-row a-center j-center br-7 ba-1-lightGray15'
                                        style={{ width: 91, height: 40 }}
                                        onClick={() => toggleState('replyVisible', element.id)}
                                    >
                                        <span className='fs-16 fw-6 fc-darkGray24'>
                                            {"답글 "}
                                            <span className='fc-red1'
                                                style={{ lineHeight: 1 }}
                                            >
                                                {replyLists.find(reply => reply.id === element.id)?.list.length || ''}
                                            </span>
                                        </span>
                                        {commentState.replyVisible[element.id]
                                            ? <XButton style={{ width: 20, height: 20, marginLeft: 5 }} />
                                            : <RightArrow style={{ width: 20, height: 20, marginLeft: 5 }} />
                                        }
                                    </button>
                                )}
                                <span className='fs-14 fw-4 fc-mediumGray2'>
                                    {getFormattedTimeStamp(element.createDateTime)}
                                </span>
                            </div>
                        </div>
                        {isCommentChange[element.id] &&
                            <div className='changeComment d-flex f-column a-center j-center bc-lightGray13 mt-20 w-100'>
                                <div className='inner'>
                                    <div className='changeCommentHeader d-flex f-row a-center j-left w-100'
                                        style={{ height: 66 }}
                                    >
                                        <span className='fs-22 fw-6 fc-mediumGray29 ml-5'>댓글 수정</span>
                                    </div>
                                    <div className='changeCommentBody d-flex f-column a-center j-center mb-20 bc-white w-100' >
                                        <textarea key={`tf-${element.id}`}
                                            className='textInputField pa-10 br-7 fs-20 fw-4 fc-black2 w-100 borderBox'
                                            style={{
                                                height: 80,
                                                resize: 'none',
                                                border: 'none',
                                                outline: 'none',
                                                fontFamily: 'pretendard'
                                            }}
                                            placeholder="거래와 관련된 댓글을 500자 이내로 입력해주세요." value={commentState.textAreaValues[element.id] || ''}
                                            onChange={(e) => handleInputChange(element.id, e)}
                                            maxLength={500}
                                        />
                                        <div
                                            className={`commentImgBox ${commentState.image[element.id] ? 'd-flex ' : 'd-none '}f-row a-start j-left`}
                                        >
                                            <img
                                                src={convertFileToUrl(commentState.image[element.id])}
                                                alt="commentImg"
                                                style={{ width: 100, height: 100 }}
                                            />
                                            <button
                                                className='imgXBtn d-flex a-center j-center bc-white ba-1-mediumGray28 borderBox'
                                                style={{ width: 50, height: 50 }}
                                                onClick={() => resetImg(element.id)}
                                            >
                                                <XButton
                                                    style={{ width: '100%', height: '100%' }} />
                                            </button>
                                        </div>
                                        <div className='commentBtnFooter d-flex f-row a-end j-between w-100 pa-10 borderBox mb-20'>
                                            <div className='leftBtnBox d-flex f-row a-center j-center'>
                                                <button
                                                    className='hiddenCommentBtn d-flex a-center j-center br-7'
                                                    style={{ width: 86, height: 36 }}
                                                    onClick={() => toggleState('isSecretComment', element.id)}
                                                >
                                                    {commentState.isSecretComment[element.id]
                                                        ? <LockColor style={{ width: '100%', height: '100%' }} />
                                                        : <Lock style={{ width: '100%', height: '100%' }} />}
                                                </button>
                                                <button
                                                    className='photoBtn d-flex a-center j-center br-7'
                                                    style={{ width: 86, height: 36 }}
                                                    onClick={() => clickInput(element.id)}
                                                >
                                                    <Camera style={{ width: '100%', height: '100%' }} />
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        id={`imageUpload-${element.id}`}
                                                        onChange={(e) => handleImageUpload(element.id, e)}
                                                    />
                                                </button>
                                            </div>
                                            <div className='rightBtnBox d-flex f-row a-center j-center'>
                                                <button
                                                    className='registerBtn d-flex a-center j-center br-7'
                                                    style={{ width: 100, height: 55 }}
                                                    onClick={() => { commentDisplayChange(element, false) }}
                                                >
                                                    <CancelBtn style={{ width: '100%', height: '100%' }} />
                                                </button>
                                                <button
                                                    className='registerBtn d-flex a-center j-center br-7'
                                                    style={{ width: 100, height: 55 }}
                                                    onClick={() => handleChangeComment(1, element.id)}
                                                >
                                                    <ChangeBtn style={{ width: '100%', height: '100%' }} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {commentState.replyVisible[element.id] && (
                            <div className='replyBox w-100 d-flex f-column a-center j-center bc-lightGray13'>
                                <div className='replyListBox d-flex f-column a-center j-center w-100 bc-lightGray13'>
                                    {replyLists.find(reply => reply.id === element.id)?.list.map((reply, index) => (
                                        <div key={index} className='replyElement w-100'>

                                            {/* 첫번째 인덱스라면 선을 추가
                                            {index === 0 && (
                                                <div className='borderDiv bc-white pt-20' style={{ width: '100%', height: 1 }} />
                                            )} */}

                                            <div className='inner bc-lightGray13'>
                                                <div className={`commentElementHeader w-100 d-flex a-center ${element.writerName === localStorage.getItem('nickName') ? 'j-between' : 'j-left'} mt-20 mb-15`} style={{ height: 30 }}>
                                                    <span className='author fs-22 fw-7 fc-black2'>
                                                        {reply.isHidden === 1 ? '*****' : reply.writerName}
                                                    </span>
                                                    {element.writerName === localStorage.getItem('nickName') &&
                                                        <button
                                                            className='headerMenuBtn'
                                                            style={{ width: 25, height: 25, objectFit: 'cover' }}
                                                            onClick={() => tabCommentMenu(reply)}
                                                        >
                                                            <MenuColumn style={{ width: '100%', height: '100%' }} />
                                                        </button>
                                                    }
                                                </div>
                                                <div className='commentElementContent w-100 d-flex a-start j-left mt-15 mb-8'>
                                                    <span className={`fs-20 fw-4 ${reply.isHidden === 1 ? 'fc-mediumGray16' : 'fc-black2'} ellipsis-30`}>
                                                        {reply.isHidden === 1 ? '비밀댓글입니다.' : reply.content}
                                                    </span>
                                                </div>
                                                {reply.isHidden !== 1 && reply.imageUrl?.length > 0 && (
                                                    <div className='commentElementPhoto ba-1-mediumGray28 mb-20' style={{ width: 90, height: 90 }}>
                                                        <img className='commentImg'
                                                            src={reply.imageUrl}
                                                            alt="commentImage"
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        />
                                                    </div>
                                                )}
                                                <div className='commentElementFooter w-100 d-flex a-end j-right mb-20'>
                                                    <span className='fs-14 fw-4 fc-mediumGray2'>
                                                        {getFormattedTimeStamp(reply.createDateTime)}
                                                    </span>
                                                </div>
                                                {isCommentChange[reply.id] &&
                                                    <div className='changeComment d-flex f-column a-center j-center bc-lightGray13 mt-20 w-100'>
                                                        <div className='inner'>
                                                            <div className='changeCommentHeader d-flex f-row a-center j-left w-100' style={{ height: 66 }}>
                                                                <span className='fs-22 fw-6 fc-mediumGray29 ml-5'>답글 수정</span>
                                                            </div>
                                                            <div className='changeCommentBody d-flex f-column a-center j-center mb-20 bc-white w-100'>
                                                                <textarea
                                                                    key={`tf-${reply.id}`}
                                                                    className='textInputField pa-10 br-7 fs-20 fw-4 fc-black2 w-100 borderBox'
                                                                    style={{ height: 80, resize: 'none', border: 'none', outline: 'none', fontFamily: 'pretendard' }}
                                                                    placeholder="거래와 관련된 댓글을 500자 이내로 입력해주세요."
                                                                    value={commentState.textAreaValues[reply.id] || ''}
                                                                    onChange={(e) => handleInputChange(reply.id, e)}
                                                                    maxLength={500}
                                                                />
                                                                <div
                                                                    className={`commentImgBox ${commentState.image[0] ? 'd-flex ' : 'd-none '}f-row a-start j-left`}
                                                                >
                                                                    <img
                                                                        src={commentState.image[reply.id]}
                                                                        alt="commentImg"
                                                                        style={{ width: 100, height: 100 }}
                                                                    />
                                                                    <button
                                                                        className='imgXBtn d-flex a-center j-center bc-white ba-1-mediumGray28 borderBox'
                                                                        style={{ width: 50, height: 50 }}
                                                                        onClick={() => resetImg(reply.id)}
                                                                    >
                                                                        <XButton
                                                                            style={{ width: '100%', height: '100%' }} />
                                                                    </button>
                                                                </div>
                                                                <div className='commentBtnFooter d-flex f-row a-end j-between w-100 pa-10 borderBox'>
                                                                    <div className='leftBtnBox d-flex f-row a-center j-center'>
                                                                        <button
                                                                            className='hiddenCommentBtn d-flex a-center j-center br-7'
                                                                            style={{ width: 86, height: 36 }}
                                                                            onClick={() => toggleState('isSecretComment', reply.id)}
                                                                        >
                                                                            {commentState.isSecretComment[reply.id]
                                                                                ? <LockColor style={{ width: '100%', height: '100%' }} />
                                                                                : <Lock style={{ width: '100%', height: '100%' }} />}
                                                                        </button>
                                                                        <button
                                                                            className='photoBtn d-flex a-center j-center br-7'
                                                                            style={{ width: 86, height: 36 }}
                                                                            onClick={() => clickInput(reply.id)}
                                                                        >
                                                                            <Camera style={{ width: '100%', height: '100%' }} />
                                                                            <input
                                                                                type="file"
                                                                                accept="image/*"
                                                                                style={{ display: 'none' }}
                                                                                id={`imageUpload-${reply.id}`}
                                                                                onChange={(e) => handleImageUpload(reply.id, e)}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                    <div className='rightBtnBox d-flex f-row a-center j-center'>
                                                                        <button
                                                                            className='registerBtn d-flex a-center j-center br-7'
                                                                            style={{ width: 100, height: 55 }}
                                                                            onClick={() => { commentDisplayChange(reply, false) }}
                                                                        >
                                                                            <CancelBtn style={{ width: '100%', height: '100%' }} />
                                                                        </button>
                                                                        <button
                                                                            className='registerBtn d-flex a-center j-center br-7'
                                                                            style={{ width: 100, height: 55 }}
                                                                            onClick={() => handleChangeComment(2, reply.id)}
                                                                        >
                                                                            <ChangeBtn style={{ width: '100%', height: '100%' }} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* 마지막 인덱스가 아니라면 선을 추가 */}
                                                {index !== replyLists.find(reply => reply.id === element.id)?.list.length - 1 && (
                                                    <div className='borderDiv' style={{ width: '100%', height: 1, backgroundColor: '#E9E9E9' }} />
                                                )}
                                                
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='addReplyComment d-flex f-column a-center j-center bc-lightGray13 mt-20 w-100'>
                                    <div className='inner'>
                                        <div className='replyCommentHeader d-flex f-row a-center j-left w-100' style={{ height: 66 }}>
                                            <EnterIcon style={{ width: 15, height: 15 }} />
                                            <span className='fs-22 fw-6 fc-mediumGray29 ml-5'>답글</span>
                                        </div>
                                        <div className='replyCommentBody d-flex f-column a-center j-center mb-20 bc-white w-100'>
                                            <textarea
                                                key={`tf-${element.id}`}
                                                className='textInputField pa-10 br-7 fs-20 fw-4 fc-black2 w-100 borderBox'
                                                style={{ height: 80, resize: 'none', border: 'none', outline: 'none', fontFamily: 'pretendard' }}
                                                placeholder="거래와 관련된 댓글을 500자 이내로 입력해주세요."
                                                value={commentState.textAreaValues[element.id] || ''}
                                                onChange={(e) => handleInputChange(element.id, e)}
                                                maxLength={500}
                                            />
                                            <div
                                                className={`commentImgBox ${commentState.image[element.id] ? 'd-flex ' : 'd-none '}f-row a-start j-left`}
                                            >
                                                <img
                                                    src={convertFileToUrl(commentState.image[element.id])}
                                                    alt="commentImg"
                                                    style={{ width: 100, height: 100 }}
                                                />
                                                <button
                                                    className='imgXBtn d-flex a-center j-center bc-white ba-1-mediumGray28 borderBox'
                                                    style={{ width: 50, height: 50 }}
                                                    onClick={() => resetImg(element.id)}
                                                >
                                                    <XButton
                                                        style={{ width: '100%', height: '100%' }} />
                                                </button>
                                            </div>
                                            <div className='commentBtnFooter d-flex f-row a-end j-between w-100 pa-10 borderBox'>
                                                <div className='leftBtnBox d-flex f-row a-center j-center'>
                                                    <button
                                                        className='hiddenCommentBtn d-flex a-center j-center br-7'
                                                        style={{ width: 86, height: 36 }}
                                                        onClick={() => toggleState('isSecretComment', element.id)}
                                                    >
                                                        {commentState.isSecretComment[element.id]
                                                            ? <LockColor style={{ width: '100%', height: '100%' }} />
                                                            : <Lock style={{ width: '100%', height: '100%' }} />}
                                                    </button>
                                                    <button
                                                        className='photoBtn d-flex a-center j-center br-7'
                                                        style={{ width: 86, height: 36 }}
                                                        onClick={() => clickInput(element.id)}
                                                    >
                                                        <Camera style={{ width: '100%', height: '100%' }} />
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            id={`imageUpload-${element.id}`}
                                                            onChange={(e) => handleImageUpload(element.id, e)}
                                                        />
                                                    </button>
                                                </div>
                                                <button
                                                    className='registerBtn d-flex a-center j-center br-7'
                                                    style={{ width: 100, height: 55 }}
                                                    onClick={() => handleCommentSubmit(2, element.id)}
                                                >
                                                    <SubmitBtn style={{ width: '100%', height: '100%' }} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className='inner bc-white'>
                <div
                    className={`commentInput p-relative br-7 ba-1-mediumGray25 ${commentList.length === 0 ? 'mt-22' : 'mt-24'} mb-40`}
                    style={{ width: '100%' }}
                >
                    <textarea
                        key={`tf-${0}`}
                        className='textInputField pa-10 br-7 fs-20 fw-4 fc-black2 w-100 borderBox'
                        style={{ height: 80, resize: 'none', border: 'none', outline: 'none', fontFamily: 'pretendard' }}
                        placeholder="거래와 관련된 댓글을 500자 이내로 입력해주세요."
                        value={commentState.textAreaValues[0] || ''}
                        onChange={(e) => handleInputChange(0, e)}
                        maxLength={500}
                    />
                    <div
                        className={`commentImgBox ${commentState.image[0] ? 'd-flex' : 'd-none'} f-row a-start j-left`}
                    >
                        <img
                            src={convertFileToUrl(commentState.image[0])}
                            alt="commentImg"
                            style={{ width: 100, height: 100 }}
                        />
                        <button
                            className='imgXBtn d-flex a-center j-center bc-white ba-1-mediumGray28 borderBox'
                            style={{ width: 50, height: 50 }}
                            onClick={() => resetImg(0)}
                        >
                            <XButton
                                style={{ width: '100%', height: '100%' }} />
                        </button>
                    </div>
                    <div className='commentBtnFooter d-flex f-row a-end j-between w-100 pa-10 borderBox'>
                        <div className='leftBtnBox d-flex f-row a-center j-center'>
                            <button
                                className='hiddenCommentBtn d-flex a-center j-center br-7'
                                style={{ width: 86, height: 36 }}
                                onClick={() => toggleState('isSecretComment', 0)}
                            >
                                {commentState.isSecretComment[0]
                                    ? <LockColor style={{ width: '100%', height: '100%' }} />
                                    : <Lock style={{ width: '100%', height: '100%' }} />}
                            </button>
                            <button
                                className='photoBtn d-flex a-center j-center br-7'
                                style={{ width: 86, height: 36 }}
                                onClick={() => clickInput(0)}
                            >
                                <Camera style={{ width: '100%', height: '100%' }} />
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id={`imageUpload-${0}`}
                                    onChange={(e) => handleImageUpload(0, e)}
                                />
                            </button>
                        </div>
                        <button
                            className='registerBtn d-flex a-center j-center br-7'
                            style={{ width: 100, height: 55 }}
                            onClick={() => handleCommentSubmit(1, 0)}
                        >
                            <SubmitBtn style={{ width: '100%', height: '100%' }} />
                        </button>
                    </div>
                </div>
            </div>
            <CommonPopup2
                id='commentPopup'
                title={`
                    <span class='fc-green1'>댓글 삭제</span>
                    <span class='fc-black1'> 또는 </span>
                    <span class='fc-green1'>수정</span>
                    <span class='fc-black1'>할까요?</span>
                `}
                text='댓글을 삭제하거나 수정이 가능합니다.'
                closeBtn={true}
                lineBtn={true}
                btnText1='삭제'
                btnText2='수정'
                btnType='two'
                btnEvent1={true}
                status={commentPopup}
                setStatus={setCommentPopup}
            />
            {showSnackBar &&
                <TradeSnackBar message={snackBarMessage} />
            }
        </div >
    );
}

export default TradeDetailComment;