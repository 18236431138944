import React from "react";
import backBtnImage from "assets/icon/backButton.svg";
import {useNavigate} from "react-router-dom";

const BackHeaderAddress = ({title, url, type, setValue}) => {
  const navigate = useNavigate();

  const backEvent = () => {
    if (setValue) {
        setValue(); // 팝업만 닫습니다.
    } else if (url === -1) {
        navigate(-1); // url이 -1이면 navigate(-1)로 뒤로가기
    } else if (!url) {
        window.history.back(); // url이 없을 때 window.history.back() 사용
    } else {
        switch (type) {
            case 1:
                navigate(url);
                break;
            case 2:
                navigate(url, { replace: true });
                break;
            default:
                break;
        }
    }
};


  return (
    <div className="backHeader pt-10 pb-10 bc-white">
      <div className="inner d-flex a-center j-between">
        <button
          className="btnBox"
          onClick={backEvent}
        >
          <img
            src={backBtnImage}
            alt="뒤로가기"
          />
        </button>
        {title &&
          <>
            <p className="fw-7 fs-28">{title}</p>
            <div className="btnBox d-block"></div>
          </>
        }
      </div>
    </div>
  );
};

export default React.memo(BackHeaderAddress);