import React, { useEffect } from "react";
import backBtnImage from "assets/icon/backButton.svg";
import { useNavigate } from "react-router-dom";

const BackHeader = ({ title, url, type, setValue }) => {
  const navigate = useNavigate();

  const backEvent = () => {
    if (setValue) {
      setValue(false);
    } else if (!url) {
      navigate(-1);
    } else {
      switch (type) {
        case 1:
          // 특정 URL로 이동
          navigate(url);
          break;
        case 2:
          // 히스토리 제거
          navigate(url, { replace: true })
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      backEvent();
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton); // 클린업
    };
  }, [navigate]);

  return (
    <div className="backHeader pt-10 pb-10 bc-white">
      <div className="inner d-flex a-center j-between">
        <button
          className="btnBox"
          onClick={backEvent}
        >
          <img
            src={backBtnImage}
            alt="뒤로가기"
          />
        </button>
        {title &&
          <>
            <p className="fw-7 fs-28">{title}</p>
            <div className="btnBox d-block"></div>
          </>
        }
      </div>
    </div>
  );
};

export default React.memo(BackHeader);