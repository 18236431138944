import React from "react";
import {useNavigate} from "react-router-dom";
import logo from "assets/image/logo.png";
import search from "assets/icon/search.svg";


const Header = () => {
  const navigate = useNavigate();

  return (
    <header id="header" className="header bc-green1">
      <div className="inner d-flex a-center j-between ta-center">
        <button
          className="logo"
          onClick={() => navigate("/home")}
        >
          <img className="d-block" src={logo} alt="로고" />
        </button>
        <div className="headerMenuBox d-flex a-center j-right">
          <button
            className="searchIcon"
            onClick={() => navigate("/searchPage")}
          >
            <img src={search} alt="검색" />
          </button>
          <button
            className="hamburgerBtn d-flex a-center j-center bc-white br-7"
            onClick={() => navigate("/menumain")}
          >
            <span className="fs-24 fc-darkGray7 fw-7">메뉴</span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
