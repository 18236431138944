export const mainMenuData = [
  { name: "두레 서비스" },
  { name: "나의 서비스" }
]

export const menuData1 = [
  {
    name: "함평군 날씨",
    url: "/weather"
  },
  {
    name: "이웃과 거래하기",
    url: "/tradeMain"
  },
  {
    name: "함평군 농업기계 임대 신청",
    url: "/tradeCenterSelect"
  },
  {
    name: "지도에서 찾기",
    url: "/map"
  },
  {
    name: "글 작성하기",
    url: "/menuwrite"
  },
  {
    name: "공지사항",
    url: "/notice"
  }
]