export const cityData = [
  {
    code: 4686025000,
    name: "함평읍",
    sub: [
      {
        code: 4686025021,
        name: "함평리"
      },
      {
        code: 4686025022,
        name: "기각리"
      },
      {
        code: 4686025023,
        name: "내교리"
      },
      {
        code: 4686025024,
        name: "수호리"
      },
      {
        code: 4686025025,
        name: "성남리"
      },
      {
        code: 4686025026,
        name: "자풍리"
      },
      {
        code: 4686025027,
        name: "만흥리"
      },
      {
        code: 4686025028,
        name: "장교리"
      },
      {
        code: 4686025029,
        name: "옥산리"
      },
      {
        code: 4686025030,
        name: "가동리"
      },
      {
        code: 4686025031,
        name: "석성리"
      },
      {
        code: 4686025032,
        name: "장년리"
      },
      {
        code: 4686025033,
        name: "진양리"
      },
      {
        code: 4686025034,
        name: "대덕리"
      }
    ]
  },
  {
    code: 4686031000,
    name: "손불면",
    sub: [
      {
        code: 4686031021,
        name: "학산리"
      },
      {
        code: 4686031022,
        name: "양재리"
      },
      {
        code: 4686031023,
        name: "북성리"
      },
      {
        code: 4686031024,
        name: "월천리"
      },
      {
        code: 4686031025,
        name: "대전리"
      },
      {
        code: 4686031026,
        name: "동암리"
      },
      {
        code: 4686031027,
        name: "죽장리"
      },
      {
        code: 4686031028,
        name: "죽암리"
      },
      {
        code: 4686031029,
        name: "궁산리"
      },
      {
        code: 4686031030,
        name: "석창리"
      },
      {
        code: 4686031031,
        name: "산남리"
      }
    ]
  },
  {
    code: 4686032000,
    name: "신광면",
    sub: [
      {
        code: 4686032021,
        name: "보여리"
      },
      {
        code: 4686032022,
        name: "송사리"
      },
      {
        code: 4686032023,
        name: "동정리"
      },
      {
        code: 4686032024,
        name: "함정리"
      },
      {
        code: 4686032025,
        name: "원산리"
      },
      {
        code: 4686032026,
        name: "삼덕리"
      },
      {
        code: 4686032027,
        name: "백운리"
      },
      {
        code: 4686032028,
        name: "복흥리"
      },
      {
        code: 4686032029,
        name: "계천리"
      },
      {
        code: 4686032030,
        name: "월암리"
      },
      {
        code: 4686032031,
        name: "가덕리"
      },
      {
        code: 4686032032,
        name: "유천리"
      }
    ]
  },
  {
    code: 4686033000,
    name: "학교면",
    sub: [
      {
        code: 4686033021,
        name: "복천리"
      },
      {
        code: 4686033022,
        name: "고막리"
      },
      {
        code: 4686033023,
        name: "금송리"
      },
      {
        code: 4686033024,
        name: "월호리"
      },
      {
        code: 4686033025,
        name: "곡창리"
      },
      {
        code: 4686033026,
        name: "학교리"
      },
      {
        code: 4686033027,
        name: "사거리"
      },
      {
        code: 4686033028,
        name: "월산리"
      },
      {
        code: 4686033029,
        name: "죽정리"
      },
      {
        code: 4686033030,
        name: "마산리"
      },
      {
        code: 4686033031,
        name: "석정리"
      }
    ]
  },
  {
    code: 4686034000,
    name: "엄다면",
    sub: [
      {
        code: 4686034021,
        name: "엄다리"
      },
      {
        code: 4686034022,
        name: "송로리"
      },
      {
        code: 4686034023,
        name: "신계리"
      },
      {
        code: 4686034024,
        name: "삼정리"
      },
      {
        code: 4686034025,
        name: "영흥리"
      },
      {
        code: 4686034026,
        name: "학야리"
      },
      {
        code: 4686034027,
        name: "성천리"
      },
      {
        code: 4686034028,
        name: "화양리"
      }
    ]
  },
  {
    code: 4686035000,
    name: "대동면",
    sub: [
      {
        code: 4686035021,
        name: "상옥리"
      },
      {
        code: 4686035022,
        name: "금곡리"
      },
      {
        code: 4686035023,
        name: "백호리"
      },
      {
        code: 4686035024,
        name: "월송리"
      },
      {
        code: 4686035025,
        name: "강운리"
      },
      {
        code: 4686035026,
        name: "향교리"
      },
      {
        code: 4686035027,
        name: "덕산리"
      },
      {
        code: 4686035028,
        name: "용성리"
      },
      {
        code: 4686035029,
        name: "운교리"
      },
      {
        code: 4686035030,
        name: "금산리"
      },
      {
        code: 4686035031,
        name: "서호리"
      },
      {
        code: 4686035032,
        name: "연암리"
      }
    ]
  },
  {
    code: 4686036000,
    name: "나산면",
    sub: [
      {
        code: 4686036021,
        name: "삼축리"
      },
      {
        code: 4686036022,
        name: "수하리"
      },
      {
        code: 4686036023,
        name: "나산리"
      },
      {
        code: 4686036024,
        name: "신평리"
      },
      {
        code: 4686036025,
        name: "송암리"
      },
      {
        code: 4686036026,
        name: "이문리"
      },
      {
        code: 4686036027,
        name: "수상리"
      },
      {
        code: 4686036028,
        name: "용두리"
      },
      {
        code: 4686036029,
        name: "우치리"
      },
      {
        code: 4686036030,
        name: "초포리"
      },
      {
        code: 4686036031,
        name: "월봉리"
      },
      {
        code: 4686036032,
        name: "원선리"
      },
      {
        code: 4686036033,
        name: "구산리"
      },
      {
        code: 4686036034,
        name: "덕림리"
      }
    ]
  },
  {
    code: 4686037000,
    name: "해보면",
    sub: [
      {
        code: 4686037021,
        name: "상곡리"
      },
      {
        code: 4686037022,
        name: "대창리"
      },
      {
        code: 4686037023,
        name: "용산리"
      },
      {
        code: 4686037024,
        name: "문장리"
      },
      {
        code: 4686037025,
        name: "금덕리"
      },
      {
        code: 4686037026,
        name: "해보리"
      },
      {
        code: 4686037027,
        name: "금계리"
      },
      {
        code: 4686037028,
        name: "산내리"
      },
      {
        code: 4686037029,
        name: "광암리"
      },
      {
        code: 4686037030,
        name: "대각리"
      }
    ]
  },
  {
    code: 4686038000,
    name: "월야면",
    sub: [
      {
        code: 4686038021,
        name: "용월리"
      },
      {
        code: 4686038022,
        name: "양정리"
      },
      {
        code: 4686038023,
        name: "영월리"
      },
      {
        code: 4686038024,
        name: "외치리"
      },
      {
        code: 4686038025,
        name: "월야리"
      },
      {
        code: 4686038026,
        name: "월악리"
      },
      {
        code: 4686038027,
        name: "계림리"
      },
      {
        code: 4686038028,
        name: "예덕리"
      },
      {
        code: 4686038029,
        name: "정산리"
      },
      {
        code: 4686038030,
        name: "용암리"
      },
      {
        code: 4686038031,
        name: "용정리"
      },
      {
        code: 4686038032,
        name: "월계리"
      }
    ]
  }
]