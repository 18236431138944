import React, { useEffect, useState } from "react";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import MenuLiBox from "components/menu/MenuLiBox";
import CommonBarBtn from "components/button/CommonBarBtn";
import {
    categoryData1,
    categoryData2,
    categoryData3,
    categoryData4,
    categoryData5,
    mainCategoryData
} from "data/categoryData";
import MainMenuBox from "../../components/menu/MainMenuBox";
import BackHeader from "components/header/BackHeader";

const MenuWrite = () => {
    const [activeTab, setActiveTab] = useState("농기계");
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        pageLoadingEvent();
    }, []);

    return (
        <section id="menuWrite" className="container">
            <Header />
            <div className="content content4 bottom bc-white scrollbar">
                <BackHeader
                    title='글 작성하기'
                />
                <div className="subTextBox bc-white">
                    <div className="inner">
                        <div className="pt-15 pb-15 d-flex a-center j-start fs-28 fw-6 fc-green1">
                            ㆍ작성할 글의 카테고리를 선택하세요
                        </div>
                    </div>
                </div>
                <div className="menuContentBox d-flex w-100 bc-lightGray10 h-940">
                    <MainMenuBox
                        list={mainCategoryData}
                        status={activeTab}
                        setStatus={setActiveTab}
                    />
                    <div className="menu-content bc-white">
                        {activeTab === "농기계" ? (
                            <ul className="fs-24">
                                {categoryData1.map(({ name }, index) => {
                                    return (
                                        <MenuLiBox key={index} text={`${name}`} type="category" status={`${selectedOption}`} setStatus={setSelectedOption} />
                                    )
                                })}
                            </ul>
                        ) : activeTab === "농지" ? (
                            <ul className="fs-24 fw-5">
                                {categoryData2.map(({ name }, index) => {
                                    return (
                                        <MenuLiBox key={index} text={`${name}`} type="category" status={`${selectedOption}`} setStatus={setSelectedOption} />
                                    )
                                })}
                            </ul>
                        ) : activeTab === "인력" ? (
                            <ul className="fs-24 fw-5">
                                {categoryData3.map(({ name }, index) => {
                                    return (
                                        <MenuLiBox key={index} text={`${name}`} type="category" status={`${selectedOption}`} setStatus={setSelectedOption} />
                                    )
                                })}
                            </ul>
                        ) : activeTab === "농산물" ? (
                            <ul className="fs-24 fw-5">
                                {categoryData4.map(({ name }, index) => {
                                    return (
                                        <MenuLiBox key={index} text={`${name}`} type="category" status={`${selectedOption}`} setStatus={setSelectedOption} />
                                    )
                                })}
                            </ul>
                        ) : (
                            <ul className="fs-24 fw-5">
                                {categoryData5.map(({ name }, index) => {
                                    return (
                                        <MenuLiBox key={index} text={`${name}`} type="category" setStatus={setSelectedOption} />
                                    )
                                })}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            <div className="inner pt-20">
                <CommonBarBtn
                    active={selectedOption}
                    text='다음'
                    activeText='다음'
                    url={`/writeoption?mainCategory=${activeTab}&selectedOption=${selectedOption}`}
                />
            </div>
        </section>
    );
};

export default MenuWrite;
