import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingPopup from "./components/popup/LoadingPopup";
import PostCode from "pages/searchAddress/PostCode";
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';

import Login from 'pages/login/Login';
import Join from 'pages/join/Join';
import Home from 'pages/Home';
import LoginNumVerification from 'pages/login/LoginNumVerification';
import ReqVerNum from 'pages/login/ReqVerNum';
import Weather from 'pages/weather/Weather';
import MenuMain from 'pages/menu/MenuMain';
import MenuWrite from 'pages/menu/MenuWrite';
import WriteOption from 'pages/menu/WriteOption';
import WritingPage from 'pages/menu/WritingPage';
import MyInfoUpdate from 'pages/myInfo/MyInfoUpdate';
import Inquiry from 'pages/myInfo/Inquiry';
import Terms from 'pages/signUp/Terms';
import NickNameField from 'pages/signUp/NickNameField';
import TradeMain from 'pages/trade/TradeMain';
import Notice from 'pages/notice/Notice';
import Map from 'components/Map/Map';
import TradeDetailMain from 'pages/trade/detail/TradeDetailMain';
import SearchPage from 'pages/searchPage/SearchPage';
import MyTradeList from 'pages/trade/myTrade/MyTradeList';
import NoticeDetail from 'pages/notice/NoticeDetail';
import SignUpNum from 'pages/signUp/SignUpNum';
import SignUpVerNum from 'pages/signUp/SignUpVerNum';
import InquiryDetail from 'pages/myInfo/InquiryDetail';
import InquiryUpdate from 'pages/myInfo/InquiryUpdate';
import FromMapMain from 'pages/trade/fromMap/FromMapMain';
import ChangePage from 'pages/menu/ChangePage';
import TradeCenterDetail from "pages/trade/center/TradeCenterDetail";
import TradeCenterSelect from "pages/trade/center/TradeCenterSelect";
import TradeCenterList from "pages/trade/center/TradeCenterList";
import TradeCenterItems from "pages/trade/center/TradeCenterItems";

// Lazy load pages
// const Login = lazy(() => import("pages/login/Login"));
// const Join = lazy(() => import("pages/join/Join"));
// const Home = lazy(() => import("pages/Home"));
// const LoginNumVerification = lazy(() => import("pages/login/LoginNumVerification"));
// const ReqVerNum = lazy(() => import("pages/login/ReqVerNum"));
// const Weather = lazy(() => import("pages/weather/Weather"));
// const MenuMain = lazy(() => import("pages/menu/MenuMain"));
// const MenuWrite = lazy(() => import("pages/menu/MenuWrite"));
// const WriteOption = lazy(() => import("pages/menu/WriteOption"));
// const WritingPage = lazy(() => import("pages/menu/WritingPage"));
// const MyInfoUpdate = lazy(() => import("pages/myInfo/MyInfoUpdate"));
// const Inquiry = lazy(() => import("pages/myInfo/Inquiry"));
// const Terms = lazy(() => import("pages/signUp/Terms"));
// const NickNameField = lazy(() => import("pages/signUp/NickNameField"));
// const TradeMain = lazy(() => import("pages/trade/TradeMain"));
// const Notice = lazy(() => import("pages/notice/Notice"));
// const Map = lazy(() => import("components/Map/Map"));
// const TradeDetailMain = lazy(() => import("pages/trade/detail/TradeDetailMain"));
// const SearchPage = lazy(() => import("pages/searchPage/SearchPage"));
// const MyTradeList = lazy(() => import("pages/trade/myTrade/MyTradeList"));
// const NoticeDetail = lazy(() => import("pages/notice/NoticeDetail"));
// const SignUpNum = lazy(() => import("pages/signUp/SignUpNum"));
// const SignUpVerNum = lazy(() => import("pages/signUp/SignUpVerNum"));
// const InquiryDetail = lazy(() => import("pages/myInfo/InquiryDetail"));
// const InquiryUpdate = lazy(() => import("pages/myInfo/InquiryUpdate"));
// const FromMapMain = lazy(() => import("pages/trade/fromMap/FromMapMain"));
// const ChangePage = lazy(() => import("pages/menu/ChangePage"));

function App() {

  const setMobileVh = () => {
    // 모바일 환경에서 vh 값 설정
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    if (isMobile) {
      setMobileVh();
      const handleResize = () => {
        setMobileVh();
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <div className="App">
      <LoadingPopup />
      <div className="backgroundBox d-flex a-start j-center o-hidden">
        <div className="backGroundImgBox left" aria-label="backgroundImg"></div>
        <Routes>
          <Route path="/" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/loginNumVerification" element={<PublicRoute><LoginNumVerification /></PublicRoute>} />
          <Route path="/join" element={<PublicRoute><Join /></PublicRoute>} />
          <Route path="/ReqVerNum" element={<PublicRoute><ReqVerNum /></PublicRoute>} />
          <Route path="/NickNameField" element={<PublicRoute><NickNameField /></PublicRoute>} />
          <Route path="/signUpNum" element={<PublicRoute><SignUpNum /></PublicRoute>} />
          <Route path="/SignUpVerNum" element={<PublicRoute><SignUpVerNum /></PublicRoute>} />
          <Route path="/Terms" element={<PublicRoute><Terms /></PublicRoute>} />

          {/* 나머지 경로들은 PrivateRoute로 보호 */}
          <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/Weather" element={<><Weather /></>} />
          <Route path="/menuMain" element={<PrivateRoute><MenuMain /></PrivateRoute>} />
          <Route path="/menuWrite" element={<PrivateRoute><MenuWrite /></PrivateRoute>} />
          <Route path="/writeOption" element={<PrivateRoute><WriteOption /></PrivateRoute>} />
          <Route path="/WritingPage" element={<PrivateRoute><WritingPage /></PrivateRoute>} />
          <Route path="/MyInfoUpdate" element={<PrivateRoute><MyInfoUpdate /></PrivateRoute>} />
          <Route path="/Inquiry" element={<PrivateRoute><Inquiry /></PrivateRoute>} />
          <Route path="/tradeMain/*" element={<PrivateRoute><TradeMain /></PrivateRoute>} />
          <Route path="/postCode" element={<PrivateRoute><PostCode /></PrivateRoute>} />
          <Route path="/notice" element={<><Notice /></>} />
          <Route path="/Map" element={<PrivateRoute><Map /></PrivateRoute>} />
          <Route path="/tradeDetailMain" element={<><TradeDetailMain /></>} />
          <Route path="/SearchPage" element={<PrivateRoute><SearchPage /></PrivateRoute>} />
          <Route path="/myTradeList" element={<PrivateRoute><MyTradeList /></PrivateRoute>} />
          <Route path="/noticeDetail" element={<PrivateRoute><NoticeDetail /></PrivateRoute>} />
          <Route path="/InquiryDetail" element={<PrivateRoute><InquiryDetail /></PrivateRoute>} />
          <Route path="/inquiryUpdate" element={<PrivateRoute><InquiryUpdate /></PrivateRoute>} />
          <Route path="/FromMap" element={<PrivateRoute><FromMapMain /></PrivateRoute>} />
          <Route path="/changePage/*" element={<PrivateRoute><ChangePage /></PrivateRoute>} />
          <Route path="/center/detail" element={<TradeCenterDetail />} />
          <Route path="/center" element={<TradeCenterSelect />} />
          <Route path="/center/list" element={<TradeCenterList />} />
          <Route path="/center/item" element={<TradeCenterItems />} />
        </Routes>
        <div className="backGroundImgBox right" aria-label="backgroundImg"></div>
      </div>
    </div>
  );
}

export default App;
