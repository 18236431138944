import weather1 from "assets/icon/weather/weather1.png";
import weather2 from "assets/icon/weather/weather2.png";
import weather3 from "assets/icon/weather/weather3.png";
import weather4 from "assets/icon/weather/weather4.png";
import weather5 from "assets/icon/weather/weather5.png";
import weather6 from "assets/icon/weather/weather6.png";
import weather7 from "assets/icon/weather/weather7.png";
import weather8 from "assets/icon/weather/weather8.png";

export const weatherDisplay = (weatherType, hour) => {
  let icon;
  let text;

  switch (weatherType) {
    case 1: // 맑음
      icon = hour <= 20 && hour >= 8 ? weather1 : weather2;
      text = '맑음';
      break;
    case 2: // 구름있음
      icon = hour <= 20 && hour >= 8 ? weather3 : weather4;
      text = '구름많음';
      break;
    case 3: // 흐림
      icon = weather5;
      text = '흐림';
      break;
    case 4: // 비
    case 7: // 소나기
      icon = weather6;
      text = '비';
      break;
    case 5: // 비, 눈
      icon = weather7;
      text = '비/눈';
      break;
    case 6: // 눈
      icon = weather8;
      text = '눈';
      break;
    case 8: // 빗방울
      icon = weather6;
      text = '빗방울';
      break;
    case 9: // 빗방울/눈날림
      icon = weather7;
      text = '빗방울/눈날림';
      break;
    case 10: // 눈날림
      icon = weather8;
      text = '눈날림';
      break;
    default: // 상태없음
      icon = hour <= 20 && hour >= 8 ? weather1 : weather2;
      text = '맑음';
  }

  return { icon, text };
};