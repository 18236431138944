import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { pageLoadingEvent } from "utils/commonUtil";
import phoneIcon from 'assets/icon/phone-call.svg';
import LoginTopBox from "../../components/login/LoginTopBox";
import LoginInputBox from "../../components/login/LoginInputBox";
import CommonBarBtn from 'components/button/CommonBarBtn';
import { sendTel } from 'api/LoginApi';

const LoginNumVerification = () => {
  const [phoneNumber, setPhoneNumber] = useState(""); // 전화번호 상태 관리
  const navigate = useNavigate();
  const location = useLocation();

  // 페이지 로드 시 실행
  useEffect(() => {
    pageLoadingEvent();

    // 버튼 클릭 이벤트 리스너 추가
    const sendTelbtn = document.getElementById('sendTelbtn');
    const handleClick = async () => {
      if (!phoneNumber) {
        console.error("유효하지 않습니다.");
      } else {
        await sendVerificationCode(); // 문자 전송
      }
    };

    sendTelbtn.addEventListener('click', handleClick);

    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Go') {
        handleClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      sendTelbtn.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeyDown); // 추가한 리스너도 제거
    };
  }, [phoneNumber]);

  // 전화번호 중복 확인 및 문자 전송 API 호출
  const sendVerificationCode = async () => {
    try {
      const data = { tel: phoneNumber }; // 전송할 데이터 객체 생성
      await sendTel(data); // sendTel 함수 호출하여 문자 전송
      navigate(`/reqvernum?phone=${phoneNumber}`);
    } catch (error) {
      console.error("SMS 전송 중 오류 발생:", error);
    }
  };

  return (
    <section id="loginNumVerification" className="container">
      <div className="content content3 scrollbar">
        <div className="inner">
          <LoginTopBox
            image={phoneIcon}
            text1="두레 이용을 위해"
            text2="를 입력해주세요"
            greenText1=""
            greenText2="휴대폰 번호"
          />
          <div className="loginInputBox">
            <LoginInputBox
              id="tel"
              maxLength={11}
              placeholder="예시) 01012345678"
              setValue={setPhoneNumber}
              type="number"
            />
          </div>
        </div>
      </div>
      <div className='inner'>
        <CommonBarBtn
          id='sendTelbtn'
          text='인증 문자 받기'
          activeText='인증 문자 받기'
          active={phoneNumber}
        />
      </div>
    </section>
  );
};

export default LoginNumVerification;
