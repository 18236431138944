import React from "react";
import { ReactComponent as Camera } from "assets/icon/cameraPlus.svg";
import { ReactComponent as CloseButton } from "assets/icon/xButton.svg";

const ImageUploader = ({ images, setImages, setFiles }) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    const handleImageChange = (event) => {
        const filesArray = Array.from(event.target.files);
        const validFiles = [];

        for (const file of filesArray) {
            if (file.size > MAX_FILE_SIZE) {
                alert("5MB 이상의 이미지는 업로드할 수 없습니다.");
            } else {
                validFiles.push(file);
            }
        }

        if (validFiles.length > 0) {
            const newImages = validFiles.map(file => {
                const imageUrl = URL.createObjectURL(file);
                return {
                    id: imageUrl,
                    src: imageUrl,
                    objectFit: 'contain',
                    name: file.name,
                    extension: file.name.split('.').pop()
                };
            });

            setImages(prevImages => [...prevImages, ...newImages].slice(0, 5));
            setFiles(prevFiles => [...prevFiles, ...validFiles].slice(0, 5));
        }

        event.target.value = ''; // Reset input
    };

    const handleImageDelete = (id) => {
        setImages(prevImages => prevImages.filter(image => image.id !== id));
        setFiles(prevFiles => prevFiles.filter(file => URL.createObjectURL(file) !== id));
    };

    const handleImageLoad = (event, imageId) => {
        const { naturalWidth, naturalHeight } = event.target;
        const aspectRatio = naturalWidth / naturalHeight;
        const newImages = images.map((image) =>
            image.id === imageId
                ? { ...image, objectFit: aspectRatio > 1 ? 'contain' : 'cover' }
                : image
        );
        setImages(newImages);
    };

    return (
        <div className="d-flex flex-wrap a-center j-left image-gallery">
            {/* 이미지 업로드 버튼 */}
            {images.length < 5 && (
                <div className="d-flex a-center j-center br-6"
                    style={{ width: 144, height: 144, border: '1px solid #ccc', cursor: 'pointer', margin: 5 }}>
                    <label className="d-flex a-center j-center"
                        htmlFor="file-input"
                        style={{ width: '100%', height: '100%' }}>
                        <Camera style={{ width: '50%', height: '50%', objectFit: 'fill' }} />
                    </label>
                    <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                </div>
            )}

            {/* 기존 및 새로 업로드된 이미지 렌더링 */}
            {images.map((image, index) => (
                <div key={image.id}
                    className="image-container br-6 p-relative"
                    style={{ width: 146, height: 146, margin: 5 }}>
                    <img
                        src={image.src}
                        alt="Selected"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: image.objectFit,
                        }}
                        onLoad={(e) => handleImageLoad(e, image.id)}
                    />
                    <button
                        onClick={() => handleImageDelete(image.id)}
                        style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            backgroundColor: 'rgba(18,18,18,0.6)',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        <CloseButton alt="Delete" style={{ width: '50px', height: '50px' }} />
                    </button>
                </div>
            ))}
        </div>
    );
};

export default ImageUploader;
