import React, { useState } from "react";

const NoticePopup = ({ popupData, onClose }) => {
    const [hideToday, setHideToday] = useState(false);

    const handleHideToday = () => {
        setHideToday(true);

        // 내일 자정까지 남은 시간을 계산하여 만료 시간 설정
        const now = new Date();
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const timeUntilMidnight = tomorrow.getTime() - now.getTime();

        const expiryTime = now.getTime() + timeUntilMidnight;
        localStorage.setItem(`hidePopup_${popupData.id}`, expiryTime);

        onClose();
    };

    const savedExpiryTime = localStorage.getItem(`hidePopup_${popupData.id}`);
    if (savedExpiryTime && new Date().getTime() < savedExpiryTime) {
        return null;
    } else if (savedExpiryTime && new Date().getTime() >= savedExpiryTime) {
        localStorage.removeItem(`hidePopup_${popupData.id}`);
    }

    return (
        <div className="d-flex j-center" style={popupBodyStyle}>
            <div style={popupStyle}>
                <div style={imageContainerStyle}>
                    <img src={popupData.imageUrl} alt="Popup Notice" style={imageStyle} />
                </div>
                <div style={buttonContainerStyle}>
                    <button className="fw-4 fs-20 fc-mediumGray1 ta-center" onClick={handleHideToday} style={leftButtonStyle}>
                        오늘 하루 보지 않기
                    </button>
                    <button className="fw-6 fs-20" onClick={onClose} style={rightButtonStyle}>
                        닫기
                    </button>
                </div>
            </div>
        </div>
    );
};

const popupBodyStyle = {
    position: "fixed",
    transform: "translateY(-50%)",
    top: "50%",
    left: 0,
    width: "100vw",
    zIndex: 1300,
}

const popupStyle = {
    width: '90%', // 화면 너비의 90%로 설정하여 반응형 처리
    maxWidth: '430px', // 최대 너비는 430px로 제한
    height: 'auto',
    backgroundColor: "white",
    borderRadius: "14px",
    textAlign: "center",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

const imageContainerStyle = {
    width: "100%", // 전체 너비를 사용하여 반응형 처리
    height: "auto", // 이미지의 높이를 자동으로 조정
    borderRadius: "14px",
    overflow: "hidden",
};

const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // 이미지가 영역에 맞게 잘리지 않도록 설정
};

const buttonContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: '100%', // 버튼 영역이 이미지와 동일한 너비를 갖도록 설정
};

const leftButtonStyle = {
    width: "60%", // 왼쪽 버튼이 70% 차지
    cursor: "pointer",
    textAlign: "center",
    border: "none",
    padding: "15px 0", // 버튼의 패딩 설정
    borderRadius: "0 0 0 14px", // 왼쪽 버튼의 모서리 둥글게
};

const rightButtonStyle = {
    width: "40%", // 오른쪽 버튼이 30% 차지
    cursor: "pointer",
    textAlign: "center",
    border: "none",
    padding: "15px 0", // 버튼의 패딩 설정
    borderRadius: "0 0 14px 0", // 오른쪽 버튼의 모서리 둥글게
};

export default React.memo(NoticePopup);
