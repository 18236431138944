import React from 'react'

const TradeDetailTitle = ({ title }) => {
    return (
        <div className='tradeDetailTitle d-flex f-row a-center j-left' style={{ width: "100%" }}>
            <span className='fs-36 fw-6 fc-black1 ellipsis-30'>{title}</span>
        </div>
    );
}

export default TradeDetailTitle