import axios from "axios";
import {wasUrl} from "utils/commonUtil";

// 홈 공지 리스트 가져오기
export const getHomeNoticeListApi = async (data) => {
  const response = await axios.get(`${wasUrl}/notice/home`, {
    params: data
  });

  return response.data;
};

export const getNoticeListApi = async () => {
  const response = await axios.get(`${wasUrl}notice/member`,{
    params : {
      page: 1 ,
      count: 99
    }
  });
  return response.data
}

export const getNoticeDetailApi = async (noticeId) => {
  const response = await axios.get(`${wasUrl}notice/member/${noticeId}`);
  return response.data
}