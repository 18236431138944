import React from "react";
import { Navigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

const PublicRoute = ({ children }) => {
  const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 확인
  // const isAuthenticated = !!cookies.authToken;  // 쿠키에 인증 토큰이 있는지 확인

  // authToken이 있는 경우 홈으로 리다이렉트
  return !cookies.authToken ? children : <Navigate to="/home" />;
};

export default PublicRoute;
