import axios from "axios";
import {wasUrl} from "utils/commonUtil";

// 문의 리스트 조회
export const getQnaListApi = async (data, authToken) => {
  const response = await axios.get(`${wasUrl}qna/member`, {
    headers: {
      'Authorization': `Bearer ${authToken}`  // authToken을 헤더에 추가
    },
    params: data
  });

  return response.data;
};

// 문의 상세 조회
export const getQnaDetailApi = async (id,authToken) => {
  const response = await axios.get(`${wasUrl}qna/member/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`  // authToken을 헤더에 추가
      },
    }
  );

  return response.data;
}

//문의 등록
export const addQnaApi = async (data, authToken) => {
  await axios.post(`${wasUrl}qna`, data, {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  });
};


// 문의 수정
export const setQnaApi = async (id, data,authToken) => {
  await axios.post(`${wasUrl}qna/${id}`, data,{
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  });
}

// 문의 삭제
export const removeQnaApi = async (id, data,authToken) => {
  await axios.post(`${wasUrl}qna/remove/${id}`, data,{
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  });
}