import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { pageLoadingEvent, useGetCookies } from 'utils/commonUtil';
import Header from 'components/header/Header';
import TradeDetailBody from './TradeDetailBody';
import TradeFooter from 'components/footer/TradeFooter';
import BackHeader from 'components/header/BackHeader';
import { getTradeDetail } from 'api/trade';

const TradeDetailMain = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tradeId = searchParams.get('tradeId');

    const cookieDatas = useGetCookies();
    const [tradeData, setTradeData] = useState(null); // 초기값을 null로 설정

    useEffect(() => {
        pageLoadingEvent();
        
        const fetchTradeDetail = async () => {
            if (cookieDatas && tradeId) {
                const data = await getTradeDetail(tradeId, cookieDatas);
                setTradeData(data); // 데이터가 있으면 상태를 업데이트
            }
        };

        fetchTradeDetail();
    }, [tradeId]);

    return (tradeData &&
        <section id='tradeDetailMain' className='container bc-lightGray13'>
            <Header />
            <div className='content content4 scrollbar'>
                <BackHeader
                    title='이웃과 거래하기'
                />
                <TradeDetailBody
                    tradeData={tradeData}
                    tradeId={tradeId}
                />
            </div>
            <TradeFooter
                tradeData={tradeData}
                tradeId={tradeId}
            />
        </section>
    );
};

export default TradeDetailMain;