import React from "react";
import { useNavigate } from "react-router-dom";

const CommonBarBtn = ({ id, active, text, activeText, url }) => {
  const navigate = useNavigate();

  return (
    <button
      id={id}
      className={`commonBarBtn w-100 br-7 fc-white fw-7 fs-28 m-auto ${active ? "bc-green1" : "bc-darkGray20"}`}
      onClick={() => { if (url) navigate(url) }}
      disabled={!active}
    >
      {active ? activeText : text}
    </button>
  );
};
export default React.memo(CommonBarBtn);