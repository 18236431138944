import React from "react";
import {ReactComponent as XBtn} from "assets/icon/xButton.svg";

const CommonPopup2 = (
  { id, image, title, text, btnType, btnText1, lineBtn, btnText2, status, setStatus, btnEvent1, closeBtn }
) => {
  const defaultBtnType = btnType || "one";
  const closeEvent = () => {
    if (!btnEvent1) {
      setStatus(false);
    }
  }

  return (
    <div
      id={id}
      className={`commonPopup popupContainer d-flex a-center j-center ${status ? "d-block" : "d-none"}`}
    >
      <div className="popupBody bc-white scrollbar">
        {closeBtn &&
          <XBtn className="xbtn" onClick={closeEvent} />
        }
        <div className="inner">
          <div className="textArea ta-center">
            {image &&
              <img
                className="popupImg mb-30"
                src={image}
                alt="팝업 아이콘"
                loading="lazy"
              />
            }
            <p
              className="fs-28 fw-7 popupTitle"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className="popupText fs-22 fw-5"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
          <div className="btnArea d-flex a-center j-between fs-24 fw-7 fc-white">
            <button
              className={`oneBtn ${lineBtn ? "lineBtn" : ""} ${defaultBtnType === "one" ? "bc-green1" : "bc-mediumGray9 half"}`}
              onClick={closeEvent}
            >
              {btnText1}
            </button>
            {defaultBtnType === "two" &&
              <button className="twoBtn half bc-green1">{btnText2}</button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonPopup2;