import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import BackHeader from "components/header/BackHeader";
import { getNoticeDetailApi } from "api/noticeApi";  // API 함수 가져오기

const NoticeDetail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const noticeId = queryParams.get("id");  // URL에서 noticeId 가져오기

    const [noticeDetail, setNoticeDetail] = useState(null);

    useEffect(() => {
        pageLoadingEvent();

        const fetchNoticeDetail = async () => {
            try {
                const data = await getNoticeDetailApi(noticeId);  // API 호출
                setNoticeDetail({
                    title: data.title,
                    content: data.content,
                    createDateTime: data.createDateTime,
                    fileList: data.imageUrlList  // imageUrlList를 fileList로 매핑
                });
            } catch (error) {
                console.error("Error occurred while fetching notice detail:", error);
            }
        };

        fetchNoticeDetail();
    }, [noticeId]);

    if (!noticeDetail) {
        return <div>Loading...</div>;
    }

    return (
        <section className="container">
            <Header />
            <div className="content homeContent scrollbar">
                <BackHeader title='공지사항' />
                <div className="inner">
                    <h2 className="fs-26 fw-6 fc-black1 mt-40 mb-15">{noticeDetail.title}</h2>
                    <p className="fs-20 fw-4 fc-mediumGray2 pb-25 bb-1-mediumGray25 mb-25">
                        {noticeDetail.createDateTime}
                    </p>
                    <div className="fs-24 fw-4 fc-black1 mb-25">
                        {noticeDetail.content}
                    </div>
                    {noticeDetail.fileList && noticeDetail.fileList.length > 0 && (
                        <div className="file-list d-flex f-column a-center j-start">
                            {noticeDetail.fileList.map((file, index) => (
                                <img key={index} src={file} alt={`attachment-${index}`} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default NoticeDetail;
