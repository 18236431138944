import React from "react";
import {setParameter} from "../utils/commonUtil";

const TwoTabBar = ({tab1, tab2, activeTab, setActiveTab, count1, count2}) => {
  return (
    <div className="tab-container d-flex">
      <button
        className={`tab-button d-flex a-center j-center${activeTab === tab1 ? " active" : ""}`}
        onClick={() => {
          setActiveTab(tab1);
          setParameter("active", tab1);
        }}
      >
        <div
          className={`tabBtnLayer d-flex a-center j-center ${activeTab === tab1 ? "bc-green8" : ""}`}
          style={{ width: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }}
        >
          <span className={`fs-30 fw-7 ${activeTab === tab1 ? 'fc-white' : 'fc-darkGray14'}`}>
            {tab1}{count1 !== null ? ` (${count1})` : ""}
          </span>
        </div>
      </button>
      <button
        className={`tab-button d-flex a-center j-center${activeTab === tab2 ? " active" : ""}`}
        onClick={() => {
          setActiveTab(tab2);
          setParameter("active", tab2);
        }}
      >
        <div
          className={`tabBtnLayer d-flex a-center j-center ${activeTab === tab2 ? "bc-green8" : ""}`}
          style={{ width: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }}
        >
          <span className={`fs-30 fw-7 ${activeTab === tab2 ? 'fc-white' : 'fc-darkGray14'}`}>
            {tab2}{count2 !== null ? ` (${count2})` : ""}
          </span>
        </div>
      </button>
    </div>
  );
};

export default React.memo(TwoTabBar);