import React from "react";
import {getDayName} from "utils/formatUtil";

const TodayBox = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();
  const day = today.getDay();

  return (
    <div className="todayBox fs-28 fw-7 d-flex a-end j-left mt-25 mb-20">
      <span className="fs-20 fw-6">오늘은</span>
      <span className="fc-green2">{year}</span>
      년
      <span className="fc-green2">{month}</span>
      월
      <span className="fc-green2">{date}</span>
      일
      <span className="fc-green2">{getDayName(day)}</span>
      <span className="fs-20 fw-6">요일 입니다.</span>
    </div>
  );
};

export default React.memo(TodayBox);