import React from 'react';
import PageNav from 'components/pageNav/PageNavigator';
import TradeBox from "components/trade/TradeBox";
import noImage from "assets/image/trade/noSmallImage.svg"

const TradeBody = ({ tradeData, getCategoryNameById, isEmpty, totalItems, currentPage, setCurrentPage, pageContentAmount }) => {

  return (
    <div className='tradeBody bt-1-mediumGray19'>
      <div className='tradeBodyContent d-flex f-column a-center'>
        {isEmpty ? (
          <div className='no-results-message d-flex a-center j-center fw-5 fs-22 fc-mediumGray2 pt-50'>
            해당되는 게시글이 존재하지 않습니다.
          </div>
        ) : (
          tradeData.map((data, index) => (
            <TradeBox
              key={index}
              id={data.id}
              tradeType={data.type}
              title={data.title}
              categoryId={data.categoryId}
              region={data.dong || data.eupMyeon|| '지역 정보 없음'}
              imagePath={data.imageUrl || data.path || noImage}
              price={data.paymentCost === 0 ? '가격협의' : `${data.paymentCost}`}
              costNegotiate={data.isCostNegotiable}
              status={data.status}
            />
          ))
        )}
      </div>
      {tradeData.length > 0 && (
        <div className='tradeBodyFooter d-flex a-center j-center mt-50 mb-30' style={{ width: '100%' }}>
          <PageNav
            totalItems={totalItems}  // 전체 아이템 수
            currentPage={currentPage}  // 현재 페이지
            setCurrentPage={setCurrentPage}  // 페이지 변경 핸들러
            pageContentAmount={pageContentAmount}  // 페이지당 항목 수
          />
        </div>
      )}
    </div>
  );
};

export default TradeBody;
