import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { pageLoadingEvent } from "utils/commonUtil";
import CommonBarBtn from "components/button/CommonBarBtn";
import loginLogo from "assets/image/login/loginLogo.png";
import loginImage from "assets/image/login/loginImage.png";


const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    pageLoadingEvent();

    const loginBtnEvent = () => navigate("/loginNumVerification?from=login");

    const loginBtn = document.getElementById("loginBtn");
    loginBtn.addEventListener("click", loginBtnEvent);

    return () => {
      loginBtn.removeEventListener("click", loginBtnEvent);
    }
  }, []);

  return (
    <section id="login" className="login container">
      <div className="content">
        <div className="inner imageBox">
          <img
            className="loginLogo"
            src={loginLogo}
            alt="편리한 농촌생활, 두레와 함께해요!"
          />
          <img
            className="loginImage d-block"
            src={loginImage}
            alt="농부가족 모습"
          />
        </div>

        <div className="inner mb-15">
          <CommonBarBtn
            id="loginBtn"
            active={true}
            activeText="시작하기"
          />
        </div>
        <div className="fs-24 ta-center">
          두레가 처음이신가요?
          <button
            id="termsBtn"
            className="fw-7 fc-green1 under"
            onClick={() => navigate("/terms")}
          >
            회원가입하기
          </button>
        </div>
      </div>
    </section>
  );
};

export default Login;
