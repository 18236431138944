import React from "react";
import { useNavigate } from "react-router-dom";
import rightArrow from "assets/icon/rightArrow.svg";

const MenuLiBox = ({id, text, url, type, status, setStatus}) => {
  const navigate = useNavigate();

  return (
    <li
      id={id}
      className={`menuLiBox fs-22 fw-5 d-flex a-center j-between w-100 ${text === status ? "active" : ""}`}
      onClick={() => {
        if (type === "menu" && url) {
          navigate(url);
        } else if (type === "category" && setStatus) {
          setStatus(text);
        }
      }}
    >
      {text}
      {type === "menu" &&
        <img
          className="arrowImage"
          src={rightArrow}
          alt="이동"
        />
      }
    </li>
  );
};

export default React.memo(MenuLiBox);