import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie"; // useCookies 훅 추가
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import completeIcon from "assets/icon/complete.svg"; // 완료 아이콘
import waitIcon from "assets/icon/wait.svg"; // 대기 아이콘
import BackHeader from "components/header/BackHeader";
import CommonBarBtn from "components/button/CommonBarBtn";
import { getTimeDifference } from "../../utils/inquiryUtil";
import { getQnaDetailApi } from "api/inquiryApi";


const InquiryDetail = () => {
    const navigate = useNavigate();
    const location = useLocation(); // 쿼리 파라미터를 가져오기 위해 useLocation 사용
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id"); // 쿼리 파라미터에서 id를 추출
    const [inquiryDetail, setInquiryDetail] = useState();
    const [cookies] = useCookies(['authToken']); // 쿠키에서 authToken 가져오기
    const authToken = cookies.authToken; // 쿠키에서 가져온 authToken 값
    
    useEffect(() => {
        pageLoadingEvent();
    
        const fetchInquiryDetail = async () => {
            try {
                const data = await getQnaDetailApi(id, authToken);  // getQnaDetailApi 호출
                setInquiryDetail(data);
            } catch (error) {
                window.history.back();
                console.error("Error occurred while fetching inquiry detail:", error);
            }
        };
    
        fetchInquiryDetail();
    }, [id, authToken]);

    const changeSubmit = () => {
        console.log('변경버튼을 눌렀어요');
    };

    if (!inquiryDetail) {
        return <div>Loading...</div>;
    }

    return (
        <section className="container">
            <Header />
            <div className={`content ${inquiryDetail.status === false ? 'content6' : 'homeContent'} scrollbar`}>
                <BackHeader title='1:1 문의' />
                <div className="inner pt-40">
                    <div className="inquiryStateBox d-flex a-center j-start mb-20">
                        <span className="inquiry-status d-flex a-center j-center">
                            <img
                                src={inquiryDetail.status === false ? waitIcon : completeIcon}
                                alt={inquiryDetail.status === false ? "대기" : "완료"}
                            />
                        </span>
                        <span className="inquiry-time ml-10 fs-20 fw-4 fc-mediumGray2">{getTimeDifference(inquiryDetail.createDateTime)}</span>
                    </div>
                    <div className="inquiry-title fs-28 fw-6 fc-black1 mb-10">{inquiryDetail.title}</div>
                    <div className={`inquiry-content fs-24 fw-4 fc-black1 pb-40 mb-30${inquiryDetail.status ? ' bb-1-lightGray17' : ''}`}>
                        <p className="fw-4 fs-24">{inquiryDetail.content}</p>
                    </div>
                    <div className="inner">
                        {inquiryDetail.status === true && (
                            <div className="inquiry-answer">
                                <p className="inquiryAnswerTitle fw-6 fs-24 fc-black1 mb-25">답변 내용</p>
                                <div
                                    className='inquiryAnswerContentBox br-7 bc-white pa-10 ba-1-green1'
                                    style={{
                                        width: 'calc(100% - 22px)',
                                        height: 210,
                                        overflowY: "auto", // 내용이 많을 경우 스크롤 가능하게 설정
                                    }}
                                >
                                    <p className="inquiryAnswerContent fs-24 fw-5 fc-black2">{inquiryDetail.answerContent}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
              className={`inner ${inquiryDetail.status === false ? '' : 'd-none'}`}
              onClick={() => navigate(`/inquiryUpdate?id=${id}`)}
            >
                <CommonBarBtn
                    id='changeBtn'
                    active={true}
                    text='수정하기'
                    activeText='수정하기'
                />
            </div>
        </section>
    );
};

export default InquiryDetail;
