import axios from "axios";
import { wasUrl } from "utils/commonUtil";

export const getWeatherInfo = async () => {
    try {
        const response = await axios.get(`${wasUrl}weather/member`);
        return response.data;
    } catch (e) {
        console.log(e);
    }
};