import React from 'react';
import PropTypes from 'prop-types';

const TradeTabElement = ({ isActive, label, onClick, selected, width }) => {
    return (
        <div className={`tradeTabElement d-flex  j-center${selected ? ' bb-5-green8' : ''} }`} style={{ width: width, height: 74, }}>
            <div className='d-flex j-center' style={{ width: '100%' }}>
                <button className='tab-button' onClick={onClick}>
                    <span className={`fs-28 ${isActive ? 'fc-green8 fw-7' : 'fc-darkGray14 fw-6'}`}> {label} </span>
                </button>
            </div>
        </div>
    );
};

TradeTabElement.propTypes = {
    isActive: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    width: PropTypes.string
};

export default TradeTabElement;
