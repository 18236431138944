import React from 'react';

const TradeDetailTable = ({ price, type, location, costNegotiate }) => {
    const formatPrice = () => {
        if (costNegotiate) {
            return <span className="fs-22 fw-6 fc-green10 ml-17">가격 협의</span>;
        } else if (price === 0||isNaN(price)) {
            return <span className="fs-22 fw-6 fc-red6 ml-17">무료</span>;
        } else {
            return <span className="fs-22 fw-6 fc-green10 ml-17">{Intl.NumberFormat('ko-KR').format(price)}</span>;
        }
    };

    return (
        <table className='tradeDetailTable bt-1-lightGray14 bb-1-lightGray14 mt-22' style={{ width: "100%", borderCollapse: 'collapse' }}>
            <tbody>
                <tr style={{ height: 52 }}>
                    <td className='bc-lightGray8 pl-20' style={{ width: "20%" }}>
                        <span className='fs-20 fw-6 fc-darkGray9 ml-10'>
                            가격
                        </span>
                    </td>
                    <td className='bc-white' style={{ width: "80%" }}>
                        {formatPrice()}
                    </td>
                </tr>
                <tr className='bt-1-lightGray14 bb-1-lightGray14' style={{ height: 52 }}>
                    <td className='bc-lightGray8 pl-20' style={{ width: "20%" }}>
                        <span className='fs-20 fw-6 fc-darkGray9 ml-10'>
                            종류
                        </span>
                    </td>
                    <td className='bc-white' style={{ width: "80%" }}>
                        <span className='fs-22 fw-6 fc-black10 ml-17'>
                            {type}
                        </span>
                    </td>
                </tr>
                <tr style={{ height: 52 }}>
                    <td className='bc-lightGray8 pl-20' style={{ width: "20%" }}>
                        <span className='fs-20 fw-6 fc-darkGray9 ml-10'>
                            위치
                        </span>
                    </td>
                    <td className='bc-white' style={{ width: "80%" }}>
                        <span className='fs-22 fw-6 fc-black10 ml-17'>
                            {location}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TradeDetailTable;
