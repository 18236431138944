import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TradeDetailImage = ({ imagePaths, setValue, active }) => {
    const settings = {
        className: 'imgList',
        dots: imagePaths.length !== 1,
        infinite: false,
        speed: 500,
        sliderToShow: 1,
        arrows: true,
        autoplay: false,
        swipeToSlide: true,
    }

    return (
        <Slider {...settings}>
            {imagePaths.map((path, index) => {
                return (
                    <img
                        className={`imgList ${active ? "" : "noImage"}`} key={index} src={path} alt={`img${index}`}
                        onClick={() => {
                            if (active) setValue(true);
                        }}
                    />
                )
            })}
        </Slider>
    );
}

export default TradeDetailImage;