import React, { useState, useEffect } from 'react';
import Header from 'components/header/Header';
import TradeToggleTab from '../TradeToggleTab';
import TradeBody from '../TradeBody';
import BackHeader from 'components/header/BackHeaderAddress';
import { getListFromMarker } from 'api/map';
import { useLocation } from 'react-router-dom';
import { getCategoryNameByIdBig } from 'utils/formatUtil';

const FromMapMain = () => {
    const location = useLocation();  // 쿼리 파라미터로 좌표값 받아옴
    const [toggleTab, setToggleTab] = useState('전체');  // 기본값은 '전체'
    const [tradeData, setTradeData] = useState([]);  // tradeData 상태
    const [filteredData, setFilteredData] = useState([]); // 필터링된 데이터를 위한 상태

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const lat = params.get('lat');
        const lng = params.get('lng');

        if (lat && lng) {
            // API 호출
            const fetchData = async () => {
                try {
                    const response = await getListFromMarker({ lat, lng });
                    setTradeData(response.list);  // 데이터를 state에 저장
                    setFilteredData(response.list); // 기본으로 전체 데이터를 필터링
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
        }
    }, [location.search]);

    const handleToggleTabClick = (tab) => {
        setToggleTab(tab);  // 탭 변경 시 상태 업데이트

        if (tab === '전체') {
            setFilteredData(tradeData);
        } else if (tab === '해주세요') {
            setFilteredData(tradeData.filter(item => item.type === 1)); // type이 1인 데이터 필터링
        } else if (tab === '해드립니다') {
            setFilteredData(tradeData.filter(item => item.type === 2)); // type이 2인 데이터 필터링
        }
    };

    return (
        <section id='FromMapMain' className='container'>
            <Header />
            <div className='content homeContent scrollbar'>
                <BackHeader title={'지도에서 찾기'} url={-1} />
                <div className="divider"></div>
                <div className="subTextBox bc-white">
                    <div className="inner">
                        <div className="pt-15 pb-15 d-flex a-center j-center fs-28 fw-6 fc-green1 ta-center">
                            {tradeData.length > 0 && (
                                `${tradeData[0].dong} (${getCategoryNameByIdBig(tradeData[0].categoryId)}) 게시글 총 ${tradeData.length} 개`
                            )}
                        </div>
                    </div>
                    <div className="divider"></div>

                    {/* TradeToggleTab에 상태와 클릭 핸들러 전달 */}
                    <TradeToggleTab activeTab={toggleTab} onTabClick={handleToggleTabClick} />

                    {/* 필터링된 데이터를 전달 */}
                    <TradeBody
                        tradeData={filteredData}  // 필터링된 데이터를 그대로 전달
                    />
                </div>
            </div>
        </section>
    );
}

export default FromMapMain;