import {useEffect, useRef} from "react";
import {getHomeNoticeListApi} from "api/noticeApi";

export const useGetHomeNoticeListApi = (setters, data) => {
  const previousDataRef = useRef();

  useEffect(() => {
    const fetchData = async () => {

      try {
        const getData = await getHomeNoticeListApi(data);

        setters.setList?.(getData.list);
      } catch (e) {
        console.error(e);
      }
    };

    if (JSON.stringify(previousDataRef.current) !== JSON.stringify(data)) {
      fetchData();
      previousDataRef.current = data;
    }
  }, [data, setters]);
};