import React, { useEffect, useState } from "react";
import { pageLoadingEvent } from "utils/commonUtil";
import { ReactComponent as SelectedRadio } from "assets/icon/radio-fill.svg";
import { ReactComponent as NormalRadio } from "assets/icon/radio.svg";

const MyTradeRadioBtnBar = ({ writeCount, applyCount, activeRadioTab, onRadioTabChange }) => {
    useEffect(() => {
        pageLoadingEvent();
    }, []);

    return (
        <div className="myTradeRadioBtnBar d-flex f-row a-center j-between bb-1-mediumGray30" style={{ width: '100%', height: 70 }}>
            <button className="radioBtn d-flex f-row a-center j-center ml-26"
                onClick={() => onRadioTabChange("내가 쓴 글")}
                style={{width:'40%',height:37}}>
                <div className="radioBtnIcon mr-8" style={{ width: 30, height: 30 }}>
                    {activeRadioTab === '내가 쓴 글'
                        ? <SelectedRadio />
                        : <NormalRadio />}
                </div>
                <span className={`fs-22 fc-black1 ls-2 ${activeRadioTab === '내가 쓴 글' ? 'fw-6' : 'fw-4'}`}
                style={{width:''}}>
                    {`내가 쓴 글(${writeCount})`}
                </span>
            </button>
            <button className="radioBtn d-flex f-row a-center j-center mr-26"
                onClick={() => onRadioTabChange("내가 신청한 거래")}
                style={{width: '60%', height:37}}>
                <div className="radioBtnIcon mr-8" style={{ width: 30, height: 30 }}>
                    {activeRadioTab === '내가 신청한 거래'
                        ? <SelectedRadio />
                        : <NormalRadio />}
                </div>
                <span className={`fs-22 fc-black1 ls-2 ${activeRadioTab === '내가 신청한 거래' ? 'fw-6' : 'fw-4'}`}
                    style={{ width: '' }}>
                    {`내가 신청한 거래(${applyCount})`}
                </span>
            </button>
        </div>
    );
}

export default MyTradeRadioBtnBar;
