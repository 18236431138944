import React from "react";
import { Navigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

const PrivateRoute = ({ children }) => {
  const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 확인
  const isAuthenticated = !!cookies.authToken;  // 쿠키에 인증 토큰이 있는지 확인

  return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
