import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import ReqSelect from "assets/icon/ReqSelect.png";
import ResSelect from "assets/icon/ResSelect.png";
import BackHeader from "components/header/BackHeader";

const WriteOption = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // 쿼리 파라미터에서 값 가져오기
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get("mainCategory");
    const selectedOption = queryParams.get("selectedOption");

    useEffect(() => {
        pageLoadingEvent();

        // 쿼리 파라미터로 받은 값 확인

        if (!activeTab || !selectedOption) {
            console.warn("Missing parameters! Check the previous page logic.");
        }
    }, [activeTab, selectedOption]);

    const handleOptionClick = (option) => {
        navigate("/writingpage", { state: { selectedOption, activeTab, serviceOption: option } });
    };

    return (
        <section id="writeOption" className="container">
            <Header />
            <div className="content homeContent scrollbar">
               <BackHeader title={'글 작성하기'} />
                <div className="inner mb-10">
                    <div className="fs-34 fw-6 pb-20 pt-20">원하는 서비스를 선택해주세요.</div>
                    <div className="fs-20 fw-4 pb-20">
                        <p>함평군 내에 있는 모든 이웃과 서로 필요한</p>
                        <p>농업기계는 물론, 농촌활동에 필요한 자원이나</p>
                        <p>인력을 서로 빌려줄 수 있습니다.</p>
                    </div>
                </div>
                <div className="inner mb-10">
                    <div className="twoImageBox d-flex a-center j-between">
                        <button className="option-button d-block" onClick={() => handleOptionClick('ReqSelect')}>
                            <img className="d-block" src={ReqSelect} alt="Request Select" />
                        </button>
                        <button className="option-button d-block" onClick={() => handleOptionClick('ResSelect')}>
                            <img className="d-block" src={ResSelect} alt="Response Select" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WriteOption;
