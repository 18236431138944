import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import closeBtn from "assets/icon/imagePopup/closeBtn.svg";

const TradeImagePopup = ({imageList, setValue}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    sliderToShow: 1,
    arrows: true,
    autoplay: false,
    swipeToSlide: true,
  }

  return (
    <div
      id="imagePopup"
      className="commonPopup popupContainer d-flex a-center j-center"
    >
      <div className="popupBox d-flex a-center j-center imgListBox">
        <button className="closePopup" onClick={() => setValue(false)}>
          <img src={closeBtn} alt="닫기" />
        </button>
        <div className="w-100">
          <Slider {...settings}>
            {imageList.map((path, index) => {
              return (
                <img className='imgList' key={index} src={path} alt={`img${index}`} />
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TradeImagePopup;