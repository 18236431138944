import {closePopup} from "utils/commonUtil";
import React from "react";
import {ReactComponent as LineCircle} from "assets/icon/line-circle.svg";
import {ReactComponent as XButton} from "assets/icon/xButton.svg";

const Terms1 = () => {
  return (
    <div id="terms1Popup" className="popupContainer termsPopup d-flex a-center j-center d-none">
      <div className="popupBody bc-white p-relative">
        <XButton width={52} height={52} onClick={() => closePopup("terms1Popup")} />
        <div className="inner">
          <div className="popupHead fs-22 fw-7 ls-3">
            준수사항
          </div>
          <ul className="textArea scrollbar fs-18 fw-5 ls-3">
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>신청 농기계는 농업용으로만 사용하고 사용기간을 지켜야 합니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>사용료의 납부기간은 사용허가일로부터 사용일 전까지 납부하여야 합니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>농기계 작업종료 후 청소 및 세척 등을 통하여 반환하고 농기계를 반환할 때에는 고장유무에 대하여 소속직원의 확인을 받아야 합니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>임대 농기계를 농작업 이외의 목적으로 사용하거나, 타인에게 양도 또는 영업행위, 사용신청 사항이 허위 또는 부정한 방법으로 사용신청을 한 사실이 밝혀지면 사용허가를 취소하게 됩니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>사용허가조건을 이행하지 아니하는 경우에는 사용허가를 제한 받습니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>사용자가 주의의무를 태만히 하여 농기계를 망실 또는 훼손하였을 경우 변상하여야 합니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>농기계를 출고한 후에 발생한 인적·물적패해 사고 등에 대하여는 사용자가 모든 책임을 져야 합니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>임대 농기계의 구조 및 성능을 변경하여서는 안 됩니다.</p>
            </li>
            <li className="d-flex a-start j-left">
              <LineCircle className="circle" />
              <p>농기계 임대 사용조건 및 준수사항 위반시 삼진 아웃제도를 적용 합니다.<br/>
                1차 : 사용정지 3개월<br/>
                2차 : 사용정지 6개월<br/>
                3차 : 사용정지 1년
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Terms1;