import React, {useEffect} from "react";
import {pageLoadingEvent} from "utils/commonUtil";

const Join = () => {

  // 페이지가 불러와지면 실행
  useEffect(() => {
    pageLoadingEvent();
  }, []);

  return (
    <section id="join" className="container">
      <p>Join</p>
    </section>
  );
};

export default Join;