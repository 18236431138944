import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RequestOffer from 'assets/image/button/requestOffer.png';
import RequestProvide from 'assets/image/button/requestProvide.png';
import CommonPopup from 'components/popup/CommonPopup';
import { pageLoadingEvent, useGetCookies } from 'utils/commonUtil';
import { completeDeal } from 'api/deal';

const MyTradeListBlock = ({
    tradeType,
    region,
    imgPath,
    nickName,
    title,
    traderCount = 0,
    tradeState,
    timeStamp = 'YY/MM/DD',
    tradeId,
    activeRadioTab
}) => {
    const hasHeaderSub = tradeState === 1 && traderCount !== 0;
    const isCompleted = tradeState === 3;

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate();
    const cookieDatas = useGetCookies();
    const [targetIndex, setTargetIndex] = useState(null);

    const handleButtonClick = (tradeId) => {
        setTargetIndex(tradeId); // 버튼 클릭 시 tradeId 저장
        setIsPopupOpen(true); // 팝업 열기
    };

    const handleConfirm = async () => {
        const paramData = { status: 3 };
        const responseCode = await completeDeal(cookieDatas, targetIndex, paramData); // 특정 tradeId로 요청
        if (responseCode === 200) {
            console.log('success');
        } else {
            console.log('fail');
        }
        setIsPopupOpen(false);
    };

    const handleBlockClick = (e, tradeId) => {
        if (e.target.classList.contains('confirmBtn')) {
            handleButtonClick(tradeId); // 클릭된 버튼의 tradeId 전달
        } else {
            navigate(`/tradeDetailMain?tradeId=${tradeId}`);
        }
    };

    useEffect(() => {
        pageLoadingEvent();

        const btnAction = () => handleConfirm(); // handleConfirm 호출

        // 팝업에서 완료 버튼을 누를 때만 handleConfirm 실행
        if (isPopupOpen) {
            const confirmBtn = document.querySelector('#completePopup .twoBtn');
            confirmBtn?.addEventListener('click', btnAction);
        }
        return () => {
            const confirmBtn = document.querySelector('#completePopup .twoBtn');
            confirmBtn?.removeEventListener('click', btnAction);
        };
    }, [isPopupOpen, targetIndex]);  // isPopupOpen, targetIndex가 변경될 때 이벤트 리스너 제어

    return (
        <>
            <div
                className='myTradeListBlock d-flex f-row a-center j-center bb-1-mediumGray19'
                style={{ width: '100%', height: 220, cursor: 'pointer' }}
                onClick={(e) => handleBlockClick(e, tradeId)}
            >
                <div className='blockImg p-relative' style={{ width: '42.66%', height: '100%' }}>
                    <div className='imgTextAbs d-flex p-absolute a-center j-center'
                        style={{
                            width: '88.93%',
                            height: 'calc(40 * 100vw / 600)', minHeight: 24, maxHeight: 40
                        }}>
                        <img src={tradeType === '해드립니다' ? RequestOffer : RequestProvide}
                            alt={tradeType}
                            style={{ width: '51.11%', height: '100%', objectFit: 'cover' }} />
                        <span className='region d-flex a-center j-center bc-red5 fs-20 fw-6 fc-white' style={{ width: '48.88%', height: '100%' }}>{region}</span>
                    </div>
                    <img src={imgPath} alt="thumbnail" style={{ width: '100%', height: '100%' }} />
                </div>
                <div className='blockExplain d-flex f-column a-center j-left bc-white' style={{ width: '57.83%', minHeight: 219 }}>
                    <div className={`blockExplainHeader d-flex f-row a-center ${hasHeaderSub || isCompleted ? 'j-between' : 'j-left'} ml-23 mr-23`}
                        style={{ width: 'calc(100% - 46px)', height: 78 }}>
                        <span className='nickName ta-left fs-20 fw-5 fc-black6'>{nickName}</span>
                        {hasHeaderSub && activeRadioTab !== '내가 신청한 거래' && (
                            <span className='fs-20 fw-5 fc-black6'>신청자 <span className='fs-20 fw-7 fc-green17'>{traderCount}</span>명</span>
                        )}
                        {isCompleted && (
                            <div className='stateDisplay d-flex a-center j-center br-31 bc-gray1 ba-1-mediumGray8' style={{ width: '25.93%', height: 44 }}>
                                <span className='fs-20 fw-7 fc-white'>
                                    완료
                                </span>
                            </div>
                        )}
                    </div>
                    <span className='title ta-left fs-26 fw-6 ml-23 mr-23 fc-black1'
                        style={{ width: 'calc(100% - 46px)', lineHeight: '31.03px', minHeight: 62 }}>
                        {title}
                    </span>
                    {(hasHeaderSub || isCompleted) && activeRadioTab !== '내가 신청한 거래' && (
                        <div className='positionedFooter d-flex a-end j-center' style={{ width: '100%', height: 80 }}>
                            {hasHeaderSub && (
                                <button
                                    className={`confirmBtn bc-green1 br-6 mb-15`}
                                    style={{ width: 'calc(100% - 40px)', height: 45 }}
                                    onClick={() => handleButtonClick(tradeId)}
                                >
                                    <span className={`confirmBtn fs-20 fw-6 fc-white`}>
                                        거래 완료
                                    </span>
                                </button>
                            )}
                            {isCompleted && (
                                <span
                                    className='timeStamp ta-left fs-20 fw-5 fc-black6 mb-28 ml-23'
                                    style={{ width: '100%' }}
                                >
                                    {`${timeStamp} 거래완료`}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <CommonPopup
                id='completePopup'
                title1=''
                title2='거래완료'
                title3=' 처리할까요?'
                text='거래가 완전히 끝난 후에 완료처리 해주세요.'
                btnType='two'
                btnText1='취소'
                btnText2='완료'
                btnEvent1={false}
                status={isPopupOpen}
                setStatus={setIsPopupOpen}
            />
        </>
    );
};

export default MyTradeListBlock;
