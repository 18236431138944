import React from "react";

const LoginTopBox = ({image, text1, text2, greenText1, greenText2}) => {
  return (
    <article className="loginTopBox fs-40 fw-7 mb-50">
      {image &&
        <img
          src={image}
          alt="아이콘"
          className="mb-20"
        />
      }
      <p>
        <span className="fc-green1">{greenText1}</span>
        {text1}
      </p>
      <p>
        <span className="fc-green1">{greenText2}</span>
        {text2}
      </p>
    </article>
  );
};

export default React.memo(LoginTopBox);