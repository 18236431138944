import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import PageNav from "components/pageNav/PageNavigator";
import BackHeader from "components/header/BackHeader";
import { getNoticeListApi } from "api/noticeApi"; // 분리된 API 파일에서 함수 가져오기

const Notice = () => {
    const [notices, setNotices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const noticesPerPage = 5;
    const navigate = useNavigate();

    useEffect(() => {
        pageLoadingEvent();
        const fetchNotices = async () => {
            try {
                const data = await getNoticeListApi(currentPage, noticesPerPage); // API 호출
                setNotices(data.list); // 데이터를 상태에 저장
            } catch (error) {
                console.error("Error occurred while fetching notices:", error);
            }
        };

        fetchNotices();
    }, [currentPage]);

    const getTimeDifference = (createDateTime) => {
        const now = new Date();
        const createdTime = new Date(createDateTime);
        const diffInMs = now - createdTime;
        const diffInMinutes = Math.floor(diffInMs / 60000);
        const diffInHours = Math.floor(diffInMinutes / 60);

        if (diffInMinutes < 60) {
            return `${diffInMinutes}분 전`;
        } else if (diffInHours < 24) {
            return `${diffInHours}시간 전`;
        } else {
            const year = createdTime.getFullYear();
            const month = String(createdTime.getMonth() + 1).padStart(2, '0');
            const day = String(createdTime.getDate()).padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    };

    const totalPageCount = Math.ceil(notices.length / noticesPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNoticeClick = (id) => {
        navigate(`/noticeDetail?id=${id}`);  // id를 URL에 포함하여 전달
    };

    return (
        <section className="container">
            <Header />
            <div className="content homeContent scrollbar">
                <BackHeader title='공지사항' />
                <div className="inner">
                    <ul className="noticeListBox w-100 fs-26 fw-6">
                        {notices.map((notice, index) => (
                            <React.Fragment key={notice.id}>
                                <li
                                    className="noticeListElement pt-20 pb-20"
                                    onClick={() => handleNoticeClick(notice.id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {notice.title}
                                    <div className="fs-20 fw-4 pt-15">{getTimeDifference(notice.createDateTime)}</div>
                                </li>
                                {index < notices.length - 1 && <div className="divider" />}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
                <div className="inner pt-50">
                    <PageNav
                        pageCount={totalPageCount}
                        currentPage={currentPage}
                        setCurrentPage={handlePageChange}
                    />
                </div>
            </div>
        </section>
    );
};

export default Notice;
