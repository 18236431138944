import React, { useEffect, useState } from "react";
import { getParameter, pageLoadingEvent, setParameter } from "utils/commonUtil";
import Header from "components/header/Header";
import completeIcon from "assets/icon/complete.svg"; // 완료 아이콘
import waitIcon from "assets/icon/wait.svg"; // 대기 아이콘
import { useNavigate } from "react-router-dom";
import BackHeader from "components/header/BackHeader";
import CommonBarBtn from "components/button/CommonBarBtn";
import CommonPopup from "components/popup/CommonPopup";
import TwoTapBar from "components/TwoTabBar";
import CommonInputBox from "components/CommonInputBox";
import CommonAreaBox from "components/CommonAreaBox";
import { useGetQnaListApi } from "hooks/inquiryHooks";
import { addQnaApi } from "api/inquiryApi";
import "assets/css/inquiry.scss";
import { getTimeDifference } from "utils/inquiryUtil";
import PageNav from "components/pageNav/PageNavigator";
import inquiryIcon from "assets/icon/inquiry.svg";
import { useCookies } from 'react-cookie';

const Inquiry = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(getParameter("active") || "문의하기");
  const [page, setPage] = useState(Number(getParameter("page")) || 1);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [cookies] = useCookies(['authToken']);
  const authToken = cookies.authToken;  // 쿠키에서 authToken 가져옴
  const userId = localStorage.getItem("userId");  // 사용자 ID

  let searchData = {
    writerId: userId,
    page: page,
    count: 6
  };

  useGetQnaListApi({ setList, setTotalCount, setTotalPage }, searchData, activeTab, authToken);  // authToken 전달

  const updateStateFromUrl = () => {
    setPage(Number(getParameter("page")) || 1);
    setActiveTab(getParameter("active") || "문의하기");
    setTitle("");
    setContent("");
    setIsWarningPopupOpen(false);
    setIsConfirmPopupOpen(false);
  };

  useEffect(() => {
    const handlePopState = () => {
      updateStateFromUrl();
    };

    window.addEventListener('popstate', handlePopState);
    updateStateFromUrl();

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "문의내역") {
      setParameter("page", 1);
      setPage(1);
    }
  }, [activeTab]);

  useEffect(() => {
    pageLoadingEvent();
    const completeBtn = document.getElementById('completeBtn');
    completeBtn.addEventListener('click', handleSubmit);
    const confirmBtn = document.querySelector('#inquiryPopup .twoBtn');
    confirmBtn.addEventListener('click', handleConfirm);

    return () => {
      completeBtn.removeEventListener('click', handleSubmit);
      confirmBtn.removeEventListener('click', handleConfirm);
    }
  }, [title, content]);

  useEffect(() => {
    setParameter("page", page);
  }, [page]);

  const handleConfirm = async () => {
    const addData = {
      writerId: Number(userId),
      title: title,  // 상태에서 제목 값을 직접 사용
      content: content  // 상태에서 내용 값을 직접 사용
    };
  
    try {
      await addQnaApi(addData, authToken);  // authToken 전달
      setParameter("active", "문의내역");
      setActiveTab("문의내역");
    } catch (e) {
      console.error(e);
    }
  };
  

  const handleSubmit = () => {
    const error = notInputParams(); // 제목 및 내용이 비어있을 때 오류 메시지 생성
  
    if (error) {
      setErrorMessage(error);  // 오류 메시지를 설정
      setIsWarningPopupOpen(true);  // 경고 팝업 열기
    } else {
      setIsConfirmPopupOpen(true);  // 확인 팝업 열기
    }
  };
  

  const notInputParams = () => {
    return `${title ? '' : '제목'}${!title && !content ? ', ' : ''}${content ? '' : '문의 내용'}`;
  };
  

  return (
    <section id="Inquiry" className="inquiry container">
      <Header />
      <div className={`content scrollbar ${activeTab === "문의하기" ? "content6" : "homeContent"}`}>
        <BackHeader title='1:1 문의' url="/menumain" type={2} />

        <TwoTapBar
          tab1="문의하기"
          tab2="문의내역"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          count1={null}
          count2={null}
        />

        <div className="inner pt-30">
          {activeTab === "문의하기" ? (
            <>
              <div className="inquiryBox">
                <CommonInputBox
                  id="title"
                  title="제목"
                  maxLength={30}
                  placeholder="제목을 입력해 주세요(30자 이내)"
                  value={title}
                  setValue={setTitle}
                />
                 <div className="pt-30">
                <CommonAreaBox
                  id="content"
                  title="문의내용"
                  maxLength={100}
                  placeholder="문의내용을 입력해 주세요(100자 이내)"
                  value={content}
                  setValue={setContent}
                />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="inquiry-list">
                {list.map((inquiry, index) => (
                  <div
                    key={inquiry.id}
                    className={`inquiryItem pb-30 ${index === 0 ? "first" : "pt-30"}`}
                    onClick={() => navigate(`/InquiryDetail?id=${inquiry.id}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex a-center j-left mb-15">
                      <img
                        className="status mr-10"
                        src={inquiry.status === false ? waitIcon : completeIcon}
                        alt={inquiry.status === false ? "대기" : "완료"}
                      />
                      <span className="fs-20 fc-mediumGray2">{getTimeDifference(inquiry.createDateTime)}</span>
                    </div>
                    <div className="inquiry-title fs-26 fw-6">{inquiry.title}</div>
                  </div>
                ))}
                {list.length === 0 && (
                  <div className="ta-center">
                    <img className="mt-50 mb-30" style={{ width: 47 }} src={inquiryIcon} alt="문의" />
                    <p className="fs-24 fw-5 fc-mediumGray2">아직 등록된 문의글이 없어요</p>
                  </div>
                )}
              </div>

              {totalPage > 1 && (
                <div className='tradeBodyFooter d-flex a-center j-center mt-50 mb-30 w-100'>
                  <PageNav
                    totalItems={totalCount}
                    currentPage={page}
                    setCurrentPage={setPage}
                    pageContentAmount={6}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className={`inner btnBox ${activeTab === "문의하기" ? "ta-center" : "d-none"}`}>
        <CommonBarBtn
          id='completeBtn'
          active={true}
          text='작성완료'
          activeText='작성완료'
        />
      </div>

      <CommonPopup
        id='notInputPopup'
        title1=''
        title2={errorMessage}
        title3=' 항목을 입력해주세요.'
        btnText1='확인'
        status={isWarningPopupOpen}
        setStatus={setIsWarningPopupOpen}
      />
      <CommonPopup
        id='inquiryPopup'
        title1=''
        title2='문의글'
        title3='을 등록하시겠습니까?'
        text='※부적절한 문의글은 관리자에 의해 삭제될 수 있습니다.'
        btnType='two'
        btnText1='취소'
        btnText2='등록'
        status={isConfirmPopupOpen}
        setStatus={setIsConfirmPopupOpen}
      />
    </section>
  );
};

export default Inquiry;
