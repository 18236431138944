import React, { useState } from 'react';
import TradeSnackBar from './TradeSnackBar';
import { ReactComponent as Share } from "assets/icon/share.svg";

const TradeDetailNicknameBar = ({ nickname, tradeType }) => {
    const [copySnackbar, setCopySnackbar] = useState(false);

    const handleCopyLink = () => {
        const currentUrl = window.location.href;

        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(currentUrl)
                .then(() => {
                    setCopySnackbar(true);
                    setTimeout(() => {
                        setCopySnackbar(false);
                    }, 1000); // 1초 후 스낵바 숨기기
                })
                .catch((err) => {
                    console.error('링크 복사에 실패했습니다.', err);
                });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = currentUrl;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
                setCopySnackbar(true);
                setTimeout(() => {
                    setCopySnackbar(false);
                }, 1000); // 1초 후 스낵바 숨기기
            } catch (err) {
                console.error('링크 복사에 실패했습니다.', err);
            }

            document.body.removeChild(textArea);
        }
    };

    return (
        <div className='tradeDetailNicknameBar d-flex f-row a-center j-between' style={{ width: "100%" }}>
            <div className='nickname d-flex a-center j-center mt-20 mb-18'>
                <span className='fs-22 fw-6 fc-darkGray11'>{nickname}</span>
            </div>
            <div className='tradeType_copyBtn d-flex mt-20 mb-18'>
                <div className='bc-darkGray16 d-flex br-35 a-center j-center' style={{ width: 111, height: 50 }}>
                    <span className='fs-22 fw-6 fc-white'>{tradeType}</span>
                </div>
                <button
                    className='copyBtn d-flex bc-white br-45 a-center j-center ml-15 ba-2-lightGray1'
                    style={{ width: 100, height: 50 }}
                    onClick={handleCopyLink}
                >
                    <Share style={{ width: 20, height: 20, marginRight: 10 }} />
                    <span className='fs-20 fw-6 fc-darkGray7'>공유</span>
                </button>
            </div>
            {copySnackbar && (
                <TradeSnackBar message='URL 주소를 복사하였습니다.' />
            )}
        </div>
    );
}

export default TradeDetailNicknameBar;
