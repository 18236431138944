import { useEffect, useRef } from "react";
import { getQnaDetailApi, getQnaListApi } from "api/inquiryApi";

export const useGetQnaListApi = (setters, data, active, authToken) => {
  const previousDataRef = useRef();
  const previousActiveRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getQnaListApi(data, authToken);  // authToken 전달

        setters.setList?.(getData.list);
        setters.setTotalCount?.(getData.totalCount);
        setters.setTotalPage?.(getData.totalPage);
      } catch (e) {
        console.error(e);
      }
    };

    if (
      JSON.stringify(previousDataRef.current) !== JSON.stringify(data) ||
      previousActiveRef.current !== active
    ) {
      if (active === "문의내역") fetchData();
      previousDataRef.current = data;
      previousActiveRef.current = active;
    }
  }, [data, setters, active, authToken]);
  
};
export const useGetQnaDetailApi = (setters, id, authToken) => {  // authToken 추가
  const previousDataRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getQnaDetailApi(id, authToken);  // authToken 전달
        setters.setTitle?.(getData.title);
        setters.setContent?.(getData.content);
        setters.setStatus?.(getData.status);
      } catch (error) {
        console.error(error);
      }
    };

    if (JSON.stringify(previousDataRef.current) !== JSON.stringify(id)) {
      fetchData();
      previousDataRef.current = id;
    }
  }, [id, setters, authToken]);  // authToken을 의존성에 추가
};
