import React from "react";

const Footer = ({ onResetLocation, onSetCurrentLocation }) => {
  return (
    <footer id="footer" className="mapFooter bc-white fs-24 fw-7 fc-black7 d-flex a-center j-left">
      <button 
        className="d-flex a-center j-center"
        style={{ 
          padding: '0 15px' // 버튼 내부 패딩 설정
        }}
        onClick={onResetLocation}  // 재선택 버튼 클릭 시 동작
      >
        현재위치 재선택
      </button>
      
      <span 
        className="d-block" 
        style={{ 
          borderRight: '1px solid #ccc', 
          height: '50px',  // 세로 divider 길이
          margin: '0 15px',  // 버튼 사이 간격
        }}
      ></span>
      
      <button 
        className="d-flex a-center j-center"
        style={{ 
          padding: '0 15px' // 버튼 내부 패딩 설정
        }}
        onClick={onSetCurrentLocation}  // 현재 내 위치로 버튼 클릭 시 동작
      >
        현재 내 위치로
      </button>
    </footer>
  );
};

export default React.memo(Footer);
