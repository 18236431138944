export const mainCategoryData = [
  { name: "농기계" },
  { name: "농지" },
  { name: "인력" },
  { name: "농산물" },
  { name: "기타" }
]

export const categoryData1 = [
  { name: "트랙터" },
  { name: "경운기" },
  { name: "이앙기" },
  { name: "관리기" },
  { name: "콤바인" },
  { name: "드론" },
  { name: "기타 농기계" }
];

export const categoryData2 = [
  { name: "논" },
  { name: "밭" },
  { name: "과수원" },
  { name: "하우스재배" },
  { name: "축사" },
  { name: "농업 창고" },
  { name: "기타농지" }
];

export const categoryData3 = [
  { name: "농작업 인력" },
  { name: "행사 인력" },
  { name: "기타 인력" }
];

export const categoryData4 = [
  { name: "벼/맥류" },
  { name: "양파" },
  { name: "고추" },
  { name: "단호박" },
  { name: "기타 농축산물" }
];

export const categoryData5 = [
  { name: "기타거래" }
];

