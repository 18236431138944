import React from "react";
import { handelInput } from "../utils/commonUtil";

const CommonAreaBox = (
  { id, title, subTitle, titleClass, hintMessage, maxLength, placeholder, value, setValue, activeEvent, disabled, type }
) => {
  const handleInputChange = (e) => {
    const target = e.target;
    const getValue = target.value;

    if (getValue.length >= 1) {
      target.classList.add("active");
    } else {
      target.classList.remove("active");
    }

    if (setValue) setValue(getValue);  // 상태 업데이트
  }

  const activeCheck = activeEvent && value.length > 0;

  return (
    <div className="commonInputBox">
      <label
        htmlFor={id}
        className="fs-24 mb-15 d-block"
      >
        <span className={`fw-6 ${titleClass}`}>{title}</span>
        {subTitle &&
          <span>({subTitle})</span>
        }
      </label>
      {hintMessage &&
        <p className="fw-6 fc-mediumGray2 mb-10">{hintMessage}</p>
      }

      <textarea
        id={id}
        key={id}
        className={`commonInput areaBox w-100 fs-24 br-6 bc-white ${activeCheck ? "active" : ""}`}
        value={value}  // defaultValue 대신 value 사용
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleInputChange}  // onChange로 상태 업데이트
        onInput={(e) => handelInput(e, type)}  // 추가로 onInput 이벤트 처리
      />
    </div>
  );
};

export default React.memo(CommonAreaBox);
