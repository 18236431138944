import axios from 'axios';
import { wasUrl } from "utils/commonUtil";

// 토큰 갱신 함수
export const refreshAuthToken = async (authToken, refreshToken) => {
    try {
      const response = await axios.post(`${wasUrl}login/token/refresh`, {}, {
        headers: {
           'Authorization' : authToken, 
          'X-Refresh-Token': refreshToken
        }
      });
  
      if (response.status === 200) {
        return response.data;  // 갱신된 토큰 반환
      } else {
        throw new Error('Failed to refresh token');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

// API 요청 헬퍼 함수 (쿠키를 인자로 받도록 수정)
export const apiWithAuth = async (url, method = 'get', data = null, authToken, refreshToken, setCookie) => {
    try {
      // GET 요청일 경우, data 대신 params로 전달
      const config = {
        url,
        method,
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
        ...(method.toLowerCase() === 'get' && { params: data }),  // GET일 경우 params 사용
        ...(method.toLowerCase() !== 'get' && { data })           // GET이 아닐 경우 data 본문에 전송
      };
  
      const response = await axios(config);
      return response;  // 성공 시 응답 반환
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // 403 에러일 때, 토큰 갱신 시도
        try {
          const tokenResponse = await refreshAuthToken(refreshToken);
          authToken = tokenResponse.authToken;
          refreshToken = tokenResponse.refreshToken;
  
          // 갱신된 토큰을 쿠키에 저장
          setCookie('authToken', authToken, { maxAge: 3 * 60 * 60, path: '/', secure: false, sameSite: 'strict' });
          setCookie('refreshToken', refreshToken, { maxAge: 30 * 24 * 60 * 60, path: '/', secure: false, sameSite: 'strict' });
  
          // 갱신된 토큰으로 다시 API 요청
          const retryConfig = {
            url,
            method,
            headers: {
              'Authorization': `Bearer ${authToken}`
            },
            ...(method.toLowerCase() === 'get' && { params: data }),  // GET일 경우 params 사용
            ...(method.toLowerCase() !== 'get' && { data })           // GET이 아닐 경우 data 본문에 전송
          };
  
          const retryResponse = await axios(retryConfig);
          return retryResponse;  // 성공 시 응답 반환
        } catch (refreshError) {
          console.error('Error refreshing token during retry:', refreshError);
          throw refreshError;  // 토큰 갱신 실패 시 에러 던짐
        }
      } else {
        // 403 이외의 에러는 그대로 던짐
        throw error;
      }
    }
  };
  