import axios from "axios";
import { wasUrl } from "utils/commonUtil";

// export const searchByLegal = async (jibnun) => {
//     const response = await axios.get(`${wasUrl}trade/trade/map/member`,{
//         params: {
//             page: 1,
//             count: 99,
//             jibun : jibnun,
//         }

//     });
//     return response;
// }

export const findMarkerZoomOut = async (data) => {
    const response = await axios.get(`${wasUrl}trade/location/zoom-out`, {
        params: {
            lat: data.lat,
            lng: data.lng,
            radius: data.radius
        }
    });
    return response.data;
};

export const findMarkerZoomIn = async (data) => {
    const response = await axios.get(`${wasUrl}trade/location/zoom-in`, {
        params: {
            lat: data.lat,
            lng: data.lng,
            radius: data.radius
        }
    });
    return response.data;
};


export const getListFromMarker = async (data)  => {
    const response = await axios.get(`${wasUrl}trade/location`, {
        params: {
            lat: data.lat,
            lng: data.lng,
            page:1,
            count:99,    
        }
    });
    return response.data;
}