import React from 'react';

const TradeToggleTab = ({ activeTab, onTabClick }) => {
    return (
        <div className='tradeToggleTab d-flex j-right a-center mt-10 mb-20'>
            <button
                className={`tradeToggleButton d-flex j-center a-center mr-28 ${activeTab === '전체' ? 'active' : ''}`}
                onClick={() => onTabClick('전체')}
            >
                <span className={`toggleButtonText fs-22 fw-6 ${activeTab === '전체' ? 'fc-green8' : 'fc-mediumGray1'} under`}>전체</span>
            </button>
            <button
                className={`tradeToggleButton d-flex j-center a-center mr-28 ${activeTab === '해주세요' ? 'active' : ''}`}
                onClick={() => onTabClick('해주세요')}
            >
                <span className={`toggleButtonText fs-22 fw-6 ${activeTab === '해주세요' ? 'fc-green8' : 'fc-mediumGray1'} under`}>해주세요</span>
            </button>
            <button
                className={`tradeToggleButton d-flex j-center a-center mr-18 ${activeTab === '해드립니다' ? 'active' : ''}`}
                onClick={() => onTabClick('해드립니다')}
            >
                <span className={`toggleButtonText fs-22 fw-6 ${activeTab === '해드립니다' ? 'fc-green8' : 'fc-mediumGray1'} under`}>해드립니다</span>
            </button>
        </div>
    );
};

export default TradeToggleTab;
