import { useCookies } from "react-cookie";

// 팝업 열기
export const openPopup = (id) => {
  document.getElementById(id).classList.remove("d-none");
}
// 팝업 닫기
export const closePopup = (id) => {
  document.getElementById(id).classList.add("d-none");
}
// 페이지 로딩 공통 이벤트
export const pageLoadingEvent = () => {
  // 로딩 팝업 닫기
  closePopup("loadingPopup");
}

// 숫자만 가능
export const onlyNumber = (e) => {
  const inputValue = e.target.value;
  e.target.value = inputValue.replace(/[^0-9]/ig, "");
}
// 대문자, 소문자, 숫자만 가능
export const onlyAlphabetAndNumber = (e) => {
  const inputValue = e.target.value;
  e.target.value = inputValue.replace(/[^A-Za-z0-9]/ig, "");
}
// 한글(천지인 고려), 영어, 숫자만 가능
export const noSpecialText = (e) => {
  const inputValue = e.target.value;
  e.target.value = inputValue.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣ㆍᆞᆢA-Za-z0-9]/ig, "");
}

export const useGetCookies = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'refreshToken']);

  const authToken = cookies.authToken;
  const refreshToken = cookies.refreshToken;

  return { authToken, refreshToken, setCookie, removeCookie };
}

// // select 이벤트
// export const selectActive = (id) => {
//   const select = document.getElementById(id);
//   const selectOptionBox = select.querySelector(".selectOptionBox");

//   if (!select.classList.contains("readOnly")) {
//     selectClose();

//     select.classList.add("active");
//     selectOptionBox.classList.remove("d-none");
//   }
// }
// export const selectCheck = (e) => {
//   if (!e.target.classList.contains("selectText")) {
//     selectClose();
//   }
// }
// export const selectClose = () => {
//   const selectBox = document.querySelectorAll(".selectBox");
//   selectBox.forEach((element) => {
//     element.classList.remove("active");
//   });

//   const selectOptionBox = document.querySelectorAll(".selectOptionBox");
//   selectOptionBox.forEach((element) => {
//     element.scrollTop = 0;
//     element.classList.add("d-none");
//   });
// }

// export const optionSelectActive = (e, type) => {
//   const option = e.target;
//   const name = option.getAttribute("name");
//   const value = option.value;
//   const selectBox = option.parentNode.parentNode;
//   const selectText = selectBox.querySelector(".selectText");
//   const optionList = selectBox.querySelectorAll("option");

//   // 옵션 활성화
//   optionList.forEach(element => {
//     element.classList.remove("active");
//   });
//   option.classList.add("active");

//   // 선택값 변경
//   selectText.innerText = name;
//   selectText.setAttribute("data-id", value);

//   if (type === "city") {
//     selectCity();
//   } else if (type === "time") {
//     selectTime();
//   }
// }


export const wasUrl = "/nong/api/";

// export const noSmallImage = noSmallImage;
// export const noBigImage = noBigImage;

// input 단어 체크
export const handelInput = (e, type) => {
  switch (type) {
    case "number":
      onlyNumber(e);
      break;
    case "noSpecialText":
      noSpecialText(e);
      break;
    default:
      break;
  }

};

// 검색어 강조
export function searchBold(value, filter) {
  let regex = new RegExp(filter, 'gi');
  return value.replace(regex, `<span class='fc-red1'>${filter}</span>`);
}

export const getParameter = (key) => {
  return new URLSearchParams(window.location.search).get(key);
}

export const setParameter = (key, value) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (value === null || value === undefined || value === '') {
    params.delete(key);
  } else {
    params.set(key, value);
  }

  window.history.replaceState({}, '', `${url.pathname}?${params.toString()}`);
};

export const getElementValue = (id) => {
  return document.getElementById(id).value
}

export const getRadioValue = (name) => {
  return document.querySelector(`input[name=${name}]:checked`).value;
}