import { completeDeal, submitDeal } from 'api/deal';
import TradeSnackBar from 'pages/trade/detail/TradeSnackBar';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { pageLoadingEvent, useGetCookies } from 'utils/commonUtil';

const TradeFooter = ({ tradeData, tradeId }) => {
    const navigate = useNavigate();
    const cookieDatas = useGetCookies();

    const submitDealAction = async (type) => {
        if (type === 'complete') {
            const data = {
                status: 3
            }
            const statusCode = await completeDeal(cookieDatas, tradeId, data);
            if (statusCode === 200) {
                snackBarDisplay('거래 완료 처리에 성공했습니다.');
            } else {
                snackBarDisplay('거래 완료 처리에 실패했습니다.');
            }
        } else {
            const data = {
                tradeId: tradeId
            };
            const statusCode = await submitDeal(cookieDatas, data);
            if (statusCode === 200) {
                snackBarDisplay('거래 신청에 성공했습니다.');
            } else {
                snackBarDisplay('거래 신청에 실패했습니다.');
            }
        }
    }

    const changeTradeDetail = () => {
        navigate(`/changePage?tradeId=${tradeId}`);
    }

    const [isSingleBtn, setIsSingleBtn] = useState(false);
    const [mainBtnText, setMainBtnText] = useState('');
    const [subBtnText, setSubBtnText] = useState('');
    const [mainBtnActive, setMainBtnActive] = useState(false);
    const [subBtnActive, setSubBtnActive] = useState(false);
    const [mainBtnAction, setMainBtnAction] = useState(null);
    const [subBtnAction, setSubBtnAction] = useState(null);

    const [tradeState, setTradeState] = useState(tradeData.status);
    const [isTradeRequested, setIsTradeRequested] = useState(tradeData.isTradeRequested);
    const [writerId, setWriterId] = useState(tradeData.writerId);


    useEffect(() => {
        pageLoadingEvent();

        const memberId = localStorage.getItem('userId');
        setTradeState(tradeData.status);
        setIsTradeRequested(tradeData.isTradeRequested);
        setWriterId(tradeData.writerId);
        
        if (tradeState === 1 || tradeState === 2) { // 거래가 미완료상태임
            if (writerId == memberId) { // 글 쓴 사람이 자기글에 들어온 경우
                if (tradeData.requestCount > 0) {
                    setMainBtnText('거래 완료');
                    setSubBtnText('수정');
                    setMainBtnActive(true);
                    setSubBtnActive(true);
                    setMainBtnAction(() => () => submitDealAction('complete')); // 함수 참조
                    setSubBtnAction(() => changeTradeDetail); // 함수 참조
                    setIsSingleBtn(false);
                } else {
                    setMainBtnText('거래 완료');
                    setSubBtnText('수정');
                    setMainBtnActive(false);
                    setSubBtnActive(true);
                    setMainBtnAction(null);
                    setSubBtnAction(() => changeTradeDetail); // 함수 참조
                    setIsSingleBtn(false);
                }
            } else { // 글 쓴 사람이 아닌 글을 들어갈 경우
                if (isTradeRequested) {
                    setMainBtnText('거래 신청 완료');
                    setMainBtnActive(false);
                    setMainBtnAction(null);
                    setSubBtnText('');
                    setSubBtnActive(false);
                    setSubBtnAction(null);
                    setIsSingleBtn(true);
                } else {
                    setMainBtnText('거래 신청');
                    setMainBtnActive(true);
                    setMainBtnAction(() => () => submitDealAction('request')); // 함수 참조
                    setSubBtnText('');
                    setSubBtnActive(false);
                    setSubBtnAction(null);
                    setIsSingleBtn(true);
                }
            }
        } else { // 거래가 완료된 경우
            setMainBtnText('거래 완료');
            setSubBtnText('수정');
            setMainBtnActive(false);
            setSubBtnActive(false);
            setMainBtnAction(null);
            setSubBtnAction(null);
            setIsSingleBtn(false);
        }
    }, [tradeState]);

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const snackBarDisplay = ((message) => {
        setSnackBarMessage(message);
        setShowSnackBar(true);
        setTimeout(() => {
            setShowSnackBar(false);
        }, 1000); // 1초 후 스낵바 숨기기
    })

    return (
        <div className="tradeFooter d-flex a-center j-center bc-white" style={{ width: '100%', height: 110 }}>
            <div className={`inner d-flex f-row a-center ${isSingleBtn ? 'j-center' : 'j-between'}`}>
                {!isSingleBtn && (
                    <button
                        className={`leftBtn d-flex a-center j-center br-7 mt-15 mb-15 bc-white ${subBtnActive ? 'ba-1-green1' : 'ba-1-mediumGray9'}`}
                        style={{ width: '32%', height: 70 }}
                        onClick={subBtnAction}
                        disabled={!subBtnActive}
                    >
                        <span className={`fs-28 fw-7 ${subBtnActive ? 'fc-green1' : 'fc-mediumGray9'}`}>{subBtnText}</span>
                    </button>
                )}
                <button
                    className={`rightBtn d-flex a-center j-center br-7 mt-15 mb-15 ${isSingleBtn ? '' : 'ml-16'} ${mainBtnActive ? 'bc-green1' : 'bc-mediumGray9'}`}
                    style={{ width: isSingleBtn ? '100%' : '63%', height: 70 }}
                    onClick={mainBtnAction} // tradeState가 1일 때만 클릭 이벤트 활성화
                    disabled={!mainBtnActive} // 버튼 비활성화 여부
                >
                    <span className='fs-28 fw-7 fc-white'>
                        {mainBtnText}
                    </span>
                </button>
            </div>
            {showSnackBar &&
                <TradeSnackBar message={snackBarMessage} />
            }
        </div>
    );
};

export default TradeFooter;