import React, { useState, useEffect } from 'react';
import { pageLoadingEvent, useGetCookies } from 'utils/commonUtil';
import { useLocation } from 'react-router-dom';
import Header from "components/header/Header";
import MyTradeTabBar from './MyTradeTabBar';
import MyTradeRadioBtnBar from './MyTradeRadioBtnBar';
import MyTradeListBlock from './MyTradeListBlock';
import PageNav from 'components/pageNav/PageNavigator';
import BackHeader from "../../../components/header/BackHeader";
import pencil from "assets/icon/pencil.svg";
import TwoTabBar from "../../../components/TwoTabBar";
import { getMyDealList } from 'api/deal';
import noImage from "../../../assets/image/trade/noSmallImage.svg";


const MyTradeList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('진행 중');
    const [activeRadioTab, setActiveRadioTab] = useState('내가 쓴 글');
    const [tradeDataList, setTradeDataList] = useState([]);

    const [tradeProgressingCount, setTradeProgressingCount] = useState(0);
    const [tradeRequestProgressingCount, setTradeRequestProgressingCount] = useState(0);
    const [tradeCompleteCount, setTradeCompleteCount] = useState(0);
    const [tradeRequestCompleteCount, setTradeRequestCompleteCount] = useState(0);
    const itemsPerPage = 6;
    const location = useLocation();

    const cookieDatas = useGetCookies();

    useEffect(() => {
        pageLoadingEvent();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabParam = searchParams.get('tab');

        if (tabParam === 'complete') {
            setActiveTab('거래 완료');
        } else {
            setActiveTab('진행 중');
        }
    }, [location.search]);

    useEffect(() => {
        updateTradeData(activeTab, activeRadioTab);
    }, [activeTab, activeRadioTab]);

    const updateTradeData = async (activeTab, activeRadioTab) => {
        let tempTradeMemberType = activeRadioTab === '내가 쓴 글' ? 1 : 2;
        const paramData = {
            page: 1,
            count: 77,
            tradeMemberType: tempTradeMemberType,
        }
        const data = await getMyDealList(cookieDatas, activeTab, paramData);
        setTradeProgressingCount(data.tradeProgressingCount);
        setTradeRequestProgressingCount(data.tradeRequestProgressingCount);
        setTradeCompleteCount(data.tradeCompleteCount);
        setTradeRequestCompleteCount(data.tradeRequestCompleteCount);
        setTradeDataList(data.list);
    }

    // 현재 페이지에 해당하는 데이터를 계산
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = tradeDataList.slice(startIndex, endIndex);

    return (
        <section id="myTradeList" className="container bc-lightGray12">
            <Header />
            <BackHeader title="내 거래 현황" />
            {/*<TradeHeader destination={'home'} title='내 거래 현황' />*/}
            <TwoTabBar
                tab1="진행 중"
                tab2="거래 완료"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                count1={(tradeProgressingCount + tradeRequestProgressingCount)}
                count2={(tradeCompleteCount + tradeRequestCompleteCount)}
            />
            {/* <MyTradeTabBar completeCount={writeCount} continueCount={continueCount} activeTab={activeTab} onTabChange={setActiveTab} /> */}
            <MyTradeRadioBtnBar
                writeCount={activeTab === '진행 중' ? tradeProgressingCount : tradeCompleteCount}
                applyCount={activeTab === '진행 중' ? tradeRequestProgressingCount : tradeRequestCompleteCount}
                activeRadioTab={activeRadioTab}
                onRadioTabChange={setActiveRadioTab}
            />
            <div className='content content8 d-flex f-column a-center w-100 scrollbar'>
                {currentItems.map((data, index) => (
                    <MyTradeListBlock
                        key={index}
                        tradeType={data.type === 1 ? '해주세요' : '해드립니다'}
                        region={data.dong}
                        tradeState={data.status}
                        imgPath={data.imageUrl || noImage}
                        nickName={data.writerName}
                        traderCount={data.tradeRequestCount}
                        title={data.title}
                        timeStamp={data.completeDateTime}
                        tradeId={data.id}
                        activeRadioTab={activeRadioTab}
                    />
                ))}
                {currentItems.length === 0 &&
                    <div className="noListBox ta-center">
                        <img src={pencil} alt="거래 완료" />
                        <p className="fs-22">아직 완료된 거래가 없어요</p>
                    </div>
                }
            {/* 페이지 네비게이터 */}
            {tradeDataList.length > itemsPerPage && (
                <div className='myTradeListBodyFooter d-flex a-center j-center mt-50 mb-30 w-100'>
                    <PageNav
                        totalItems={tradeDataList.length}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageContentAmount={itemsPerPage}
                    />
                </div>
            )}
            </div>
        </section>
    );
};

export default MyTradeList;