import React, { useEffect, useState } from 'react';
import { pageLoadingEvent } from 'utils/commonUtil';
import BackHeader from 'components/header/BackHeader';
import Header from 'components/header/Header';
import { useNavigate } from 'react-router-dom';
import {useGetRentSiteApi} from "hooks/centerHooks";
import {useCookies} from "react-cookie";
import mappin from "assets/image/center/centerMappin.svg";
import call from "assets/image/center/centerCall.svg";

const TradeCenterSelect = () => {
  const [list, setList] = useState([]);
  const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 가져오기
  const authToken = cookies.authToken;
  const navigate = useNavigate();

  /*useGetRentSiteApi({setList}, authToken)*/

  console.log(list);

  useEffect(() => {
    pageLoadingEvent();
  }, []);

  const navigateEvent = (value) => {
    navigate(`/center/list?center=${value}`);
  }

  return (
    <section id="tradeCenterSelect" className="container bc-white">
      <Header />
      <div className='content homeContent scrollbar'>
        <BackHeader title={'함평군 농업기계 임대 신청'} />
        <div className='inner'>
          <div className='subTitle d-flex f-column a-start j-center w-100 mt-30 mb-30'>
            <p className="fs-32 fw-7 fc-black1 w-100">
              함평군 농업기계임대사업소
            </p>
            <p className="fs-32 fw-7 fc-black1 w-100">
              <span className="fc-green1">지점을 선택</span>
              해주세요
            </p>
          </div>
          <div className="btnList">
            <div className="d-flex a-start j-between mb-25">
              <button
                className="br-9 ba-1-lightGray20"
                onClick={() => navigateEvent("본점")}
              >
                <p className='fs-30 fw-7 fc-green1 mb-20'>본점</p>
                <div className="d-flex a-start j-between textBox address ta-left mb-5">
                  <img src={mappin} alt="" />
                  <p className="fs-20 fw-5 fc-black2">전남 함평군 학교면 학교화산길 88</p>
                </div>
                <div className="d-flex a-start j-between textBox ta-left">
                  <img src={call} alt="" />
                  <p className="fs-20 fw-5 fc-black2">061-320-2502</p>
                </div>
              </button>
              <button
                className="br-9 ba-1-lightGray20"
                onClick={() => navigateEvent("동부점")}
              >
                <p className='fs-30 fw-7 fc-green1 mb-20'>동부점</p>
                <div className="d-flex a-start j-between textBox address ta-left mb-5">
                  <img src={mappin} alt="" />
                  <p className="fs-20 fw-5 fc-black2">전남 함평군 해보면 해삼로 392-12</p>
                </div>
                <div className="d-flex a-start j-between textBox ta-left">
                  <img src={call} alt="" />
                  <p className="fs-20 fw-5 fc-black2">061-320-2903<br/>061-320-2899</p>
                </div>
              </button>
            </div>
            <button
              className="br-9 ba-1-lightGray20 mb-50"
              onClick={() => navigateEvent("서부점")}
            >
              <p className='fs-30 fw-7 fc-green1 mb-20'>서부점</p>
              <div className="d-flex a-start j-between textBox address ta-left mb-5">
                <img src={mappin} alt="" />
                <p className="fs-20 fw-5 fc-black2">전남 함평군 손불면 손무로 728</p>
              </div>
              <div className="d-flex a-start j-between textBox ta-left">
                <img src={call} alt="" />
                <p className="fs-20 fw-5 fc-black2">061-320-2903</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TradeCenterSelect;