import axios from "axios";
import { wasUrl } from "utils/commonUtil";


export const searchByKeword = async (keyword) => {
    const response = await axios.get(`${wasUrl}trade/search/member`, {
        params: {
            page: 1,
            count: 99,
            keyword: keyword
        }
    });
    return response;
}

export const regalToAddress = async (legalcode) =>{
    const response = await axios.get(`${wasUrl}address/${legalcode}`);
    return response;
}