import BackHeader from "components/header/BackHeader";
import Header from "components/header/Header";
import PageNavigator from "components/pageNav/PageNavigator";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {openPopup, pageLoadingEvent} from "utils/commonUtil";
import noImage from 'assets/image/trade/150.png';
import { ReactComponent as CenterDownArrow } from 'assets/icon/centerDownArrow.svg';
import { ReactComponent as CenterSafety } from 'assets/icon/centerSafety.svg';
import { ReactComponent as CenterObserve } from 'assets/icon/centerObserve.svg';
import {useCookies} from "react-cookie";
import {useGetRentCategoryApi} from "hooks/centerHooks";
import Terms1 from "components/popup/Terms1";
import Terms2 from "components/popup/Terms2";

const TradeCenterSelect = () => {
  const [list, setList] = useState([]);
  const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 가져오기
  const authToken = cookies.authToken;

  const searchData = {
    page: 1,
    count: 10,
    rentSiteCode: "농업기술센터"
  }

  /*useGetRentCategoryApi({setList}, authToken, searchData);*/

  console.log(list)

  const data = [
    { id: 1, name: '장비1', branch: '지점1', subname: '서브1', subloc: '지점A' },
    { id: 2, name: '장비2', branch: '지점2', subname: '서브2', subloc: '지점B' },
    { id: 3, name: '장비3', branch: '지점3', subname: '서브3', subloc: '지점C' },
    { id: 4, name: '장비4', branch: '지점4', subname: '서브4', subloc: '지점D' },
    { id: 5, name: '장비5', branch: '지점5', subname: '서브5', subloc: '지점E' },
  ];  // 임시 더미데이터

  const navigate = useNavigate();

  // 디테일 박스 열기/닫기
  const toggleDetail = (e) => {
    const target = e.currentTarget.parentElement; // 부모 요소로 접근
    if (target.classList.contains("active")) {
      target.classList.remove("active");
    } else {
      const centerListElement = document.querySelectorAll(".centerListElement");
      centerListElement.forEach((element) => {
        element.classList.remove("active");
      });
      target.classList.add("active");
    }
  };


  const goToItemList = () => {
    navigate('/center/item');
  };

  // 서브 카테고리 버튼 생성
  const getSubCategories = (data) => {
    return data.map((item) => (
      <button
        key={item.id}
        className="subCategoryBtn a-center j-center mb-15 pl-5 pr-5"
        onClick={() => goToItemList()}
      >
        <div
          className="textBox bc-darkGray7 w-100"
          style={{ height: '25%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
        >
          <span className="fs-22 fw-6 fc-white">{item.subname || '이름 없음'}</span>
        </div>
        <img
          src={noImage}
          alt="subCategoryThumb"
          style={{ width: '100%', height: '60%', overflow: "hidden" }}
        />
        <div
          className="textBox bc-lightGray8 w-100"
          style={{ height: '15%', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}
        >
          <span className="fs-18 fw-4 fc-black1">{item.subloc || '지점 없음'}</span>
        </div>
      </button>
    ));
  };

  useEffect(() => {
    pageLoadingEvent();
  }, []);

  return (
    <>
      <Terms1 />
      <Terms2 />
      <section id="tradeCenterList" className="container bc-white">
        <Header />
        <div className='content homeContent scrollbar'>
          <BackHeader title='함평군 농업기계 임대 신청' />
          <div className="inner">
            <div className="centerListSubTitleBox d-flex f-column a-center j-center ta-left w-100 mt-30 mb-30">
              <p className="fs-32 fw-7 fc-black1 w-100">
                함평군 농업기계임대사업소
              </p>
              <p className="fs-32 fw-7 fc-black1 w-100">
                <span className="fc-green1">임대 장비를 선택</span>
                해주세요
              </p>
            </div>
            <div className="centerListBtnBox d-flex f-row a-center j-left w-100 mb-40">
              <button
                className="safetyBtn d-flex f-row a-center j-center bc-lightGray9 mr-15"
                onClick={() => openPopup("terms2Popup")}
              >
                <CenterSafety />
                <span className="fs-20 fw-6 fc-darkGray10 ml-5">
                  안전수칙
                </span>
              </button>
              <button
                className="observedBtn d-flex f-row a-center j-center bc-lightGray9"
                onClick={() => openPopup("terms1Popup")}
              >
                <CenterObserve />
                <span className="fs-20 fw-6 fc-darkGray10 ml-5">
                  준수사항
                </span>
              </button>
            </div>
            <div className="centerListBody d-flex f-column a-center j-center w-100 mb-40">
              {data.map((item) => {
                return (
                  <div key={item.id} className="centerListElement d-flex f-column a-center j-between w-100 mb-30 mt-30">
                    <button
                      className="centerListVisible d-flex f-row a-center j-between w-100 mb-30"
                      onClick={(e) => toggleDetail(e)}
                    >
                      <div
                        className="imgBox"
                        style={{ width: '13%', aspectRatio: 1.0, overflow: "hidden", borderRadius: 999 }}
                      >
                        <img src={item.image || noImage} alt="machineImg" style={{ width: '100%', height: '100%' }} />
                      </div>
                      <div
                        className="textBox d-flex f-row a-center j-between"
                        style={{ width: '85%' }}
                      >
                        <div className="d-flex f-column a-center j-center ta-left">
                          <span className="fs-26 fw-7 fc-darkGray2 w-100">{item.name || '이름 없음'}</span>
                          <span className="fs-22 fw-4 fc-mediumGray2 w-100">{item.branch || '지점 없음'}</span>
                        </div>
                        <CenterDownArrow />
                      </div>
                    </button>
                    <div
                      className="centerListHiddenBox a-start j-left w-100 d-none"
                    >
                      {getSubCategories([item]) || '카테고리 없음'}
                    </div>
                    {item.id !== data[data.length - 1].id && (
                      <div
                        className="divideLine mt-30"
                        style={{ width: '100%', height: 0.5, background: '#C5C5C5' }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
            <PageNavigator />
          </div>
        </div>
      </section>
    </>
  );
};

export default TradeCenterSelect;
