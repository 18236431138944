import React from 'react';

const TradeDropdownPopup = ({ list, onClose, onSelect, type }) => {
    return (
        <div
            className="darkBackground p-absolute d-flex a-center j-center"
            style={{
                width: '100vw',
                height: '100vh',
                overflow: 'visible',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)'
            }}
        >
            <div
                className="tradeDropdownPopup d-flex p-absolute f-column a-center j-start bc-white br-7"
                style={{
                    width: '83%',
                    maxWidth: 500,
                    minWidth: 310,
                    maxHeight: '70vh',
                    zIndex: 1000,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                }}
            >
                <div
                    className="tradeDropdownPopupHeader d-flex f-row a-center j-between"
                    style={{
                        width: '100%',
                        minHeight: 82,
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 10,
                    }}
                >
                    <span className="tradeDropdownPopupTitle fs-32 fw-7 fc-black1 ml-20">
                        {`${type} 종류 찾기`}
                    </span>
                    <button className="closeBtn ml-20 mr-20" onClick={onClose}>
                        {/* icon 처리할것 */}
                        <span className="fs-30">X</span>
                    </button>
                </div>
                <div
                    className="tradeDropdownPopupBody d-flex f-column j-start a-center"
                    style={{
                        width: '100%',
                        height: 'calc(100% - 82px)',
                        overflowY: 'auto',
                    }}
                >
                    {list.map((element, index) => (
                        <button
                            key={index}
                            className="popupItemBlock d-flex j-center a-center bt-1-mediumGray27"
                            style={{
                                width: '100%',
                                minHeight: 95,
                            }}
                            onClick={() => {
                                onSelect(element);
                                onClose();
                            }}
                        >
                            <span className="popupItemText fs-34 fw-5 fc-black3">
                                {element}
                            </span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TradeDropdownPopup;
