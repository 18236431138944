import React from "react";

const TradeSnackBar = ({ message }) => {
    return (
        <div
            className='snackbar d-flex a-center j-center p-fixed'
            style={{
                width: 'calc(548 * 100vw / 600)',
                minWidth: 328.8,
                maxWidth: 548,
                height: 'calc(68 * 100vw / 600)',
                minHeight: 40.8,
                maxHeight: 68,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(35, 35, 35, 0.9)',
                color: 'white',
                borderRadius: '9px',
                zIndex: 1000,
            }}
        >
            <span className='fs-24 fw-6 fc-white'>{message}</span>
        </div>
    );

}
export default TradeSnackBar;