import React, { useEffect } from 'react'; 
import closeButton from 'assets/marker/xbutton-for-map.svg';

const MapCategoryModal = ({ isOpen, onClose, onSelectCategory }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';  // 팝업이 열리면 body 스크롤 비활성화
        } else {
            document.body.style.overflow = '';  // 팝업이 닫히면 body 스크롤 복구
        }

        // 컴포넌트 언마운트 또는 팝업 닫힐 때 overflow 복구
        return () => {
            document.body.style.overflow = '';  // 언마운트 시에도 복구
        };
    }, [isOpen]);

    if (!isOpen) return null;

    const handleCategorySelect = (category) => {
        onSelectCategory(category);
        onClose();  // 카테고리 선택 후 모달 닫기
    };

    return (
        <div
            className="darkBackground p-absolute d-flex a-center j-center"
            style={{
                width: '100vw',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)'
            }}
        >
            <div
                className="tradeDropdownPopup d-flex p-absolute f-column a-center j-start bc-white br-7"
                style={{
                    width: '83%',
                    maxWidth: 500,
                    minWidth: 310,
                    maxHeight: '400px',
                    zIndex: 1000,
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                }}
            >
                <div
                    className="tradeDropdownPopupHeader d-flex f-row a-center j-between"
                    style={{
                        width: '100%',
                        minHeight: 82,
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 10,
                    }}
                >
                    <span className="tradeDropdownPopupTitle fs-32 fw-7 fc-black1 ml-20">
                        카테고리별로 보기
                    </span>
                    <button className="closeBtn ml-20 mr-20" onClick={onClose}>
                        <img src={closeButton} alt="close" />
                    </button>
                </div>
                <div
                    className="tradeDropdownPopupBody d-flex f-column j-start a-center"
                    style={{
                        width: '100%',
                        height: 'calc(100% - 82px)',
                        overflowY: 'auto',
                    }}
                >
                    <button
                        className="popupItemBlock d-flex j-center a-center bt-1-mediumGray27"
                        style={{ width: '100%', minHeight: 95 }}
                        onClick={() => handleCategorySelect('전체')}
                    >
                        <span className="popupItemText fs-34 fw-5 fc-black3">전체</span>
                    </button>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }}></div>
                    <button
                        className="popupItemBlock d-flex j-center a-center bt-1-mediumGray27"
                        style={{ width: '100%', minHeight: 95 }}
                        onClick={() => handleCategorySelect('농기계')}
                    >
                        <span className="popupItemText fs-34 fw-5 fc-black3">농기계</span>
                    </button>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }}></div>
                    <button
                        className="popupItemBlock d-flex j-center a-center bt-1-mediumGray27"
                        style={{ width: '100%', minHeight: 95 }}
                        onClick={() => handleCategorySelect('농지')}
                    >
                        <span className="popupItemText fs-34 fw-5 fc-black3">농지</span>
                    </button>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }}></div>
                    <button
                        className="popupItemBlock d-flex j-center a-center bt-1-mediumGray27"
                        style={{ width: '100%', minHeight: 95 }}
                        onClick={() => handleCategorySelect('인력')}
                    >
                        <span className="popupItemText fs-34 fw-5 fc-black3">인력</span>
                    </button>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }}></div>
                    <button
                        className="popupItemBlock d-flex j-center a-center bt-1-mediumGray27"
                        style={{ width: '100%', minHeight: 95 }}
                        onClick={() => handleCategorySelect('농산물')}
                    >
                        <span className="popupItemText fs-34 fw-5 fc-black3">농산물</span>
                    </button>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }}></div>
                    <button
                        className="popupItemBlock d-flex j-center a-center bt-1-mediumGray27"
                        style={{ width: '100%', minHeight: 95 }}
                        onClick={() => handleCategorySelect('기타')}
                    >
                        <span className="popupItemText fs-34 fw-5 fc-black3">기타</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MapCategoryModal;