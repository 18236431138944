import React from "react";
import {Link} from "react-router-dom";
import youtubeBtn from "assets/image/home/youtubeBtn.webp";
import hamPyengHomeBtn from "assets/image/home/hampyengHomeBtn.webp";

const OutLinkTwoImageBox = () => {
  return (
    <div id="outLinkBox" className="twoImageBox d-flex a-center j-between">
      <Link
        className="d-block"
        to="http://www.youtube.com/@hpncenter"
        target="_blank"
      >
        <img className="d-block" src={youtubeBtn} alt="함평유튜브" loading="lazy" />
      </Link>
      <Link
        className="d-block"
        to="https://www.hampyeong.go.kr/agri/"
        target="_blank"
      >
        <img className="d-block" src={hamPyengHomeBtn} alt="함평군홈페이지" loading="lazy" />
      </Link>
    </div>
  );
};

export default React.memo(OutLinkTwoImageBox);