import React, { useEffect, useState } from "react";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import UpdateMyInfo from "assets/icon/updateMyInfo.svg";
import { mainMenuData, menuData1 } from "../../data/menuData";
import MenuLiBox from "../../components/menu/MenuLiBox";
import { useNavigate } from "react-router-dom";
import MainMenuBox from "../../components/menu/MainMenuBox";
import rightArrow from "assets/icon/rightArrow.svg";
import CommonPopup from "components/popup/CommonPopup";
import BackHeader from "components/header/BackHeader";
import { apiWithAuth } from "api/apiHelpers";
import { useCookies } from "react-cookie";
import { wasUrl } from "utils/commonUtil";

const MenuMain = () => {
  const [activeTab, setActiveTab] = useState("두레 서비스");
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [nickname, setNickname] = useState(""); // 닉네임 상태 추가
  const [progressCount, setProgressCount] = useState(0); // 진행중 거래 수
  const [completeCount, setCompleteCount] = useState(0); // 완료된 거래 수
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'refreshToken']); // removeCookie 추가
  const authToken = cookies.authToken;
  const refreshToken = cookies.refreshToken;

  useEffect(() => {
    pageLoadingEvent();
    const memberId = localStorage.getItem('userId'); // 로컬스토리지에서 userId 가져오기

    const fetchDealCounts = async () => {
      const url = `${wasUrl}trade/request/count/${memberId}`;

      try {
        const response = await apiWithAuth(url, 'get', null, authToken, refreshToken, setCookie);
        setNickname(response.data.name);
        setProgressCount(response.data.progressCount);
        setCompleteCount(response.data.completeCount);
      } catch (error) {
        console.error("Error occurred while fetching deal counts:", error);
      }
    };

    fetchDealCounts(); // 데이터 가져오기

    const confirmBtn = document.querySelector('#logoutPopup .twoBtn');
    confirmBtn.addEventListener('click', handleConfirmLogout);

    return () => {
      confirmBtn.removeEventListener('click', handleConfirmLogout);
    };
  }, [authToken, refreshToken, setCookie]);

  const handleMyInfo = () => {
    navigate("/MyInfoUpdate");
  };

  const handleLogoutClick = () => {
    setIsLogoutPopupOpen(true);
  };

  // const handleClosePopup = () => {
  //   setIsLogoutPopupOpen(false);
  // };

  const handleConfirmLogout = () => {
    setIsLogoutPopupOpen(false);
    localStorage.clear(); // 로컬 스토리지 비우기
    removeCookie('authToken', { path: '/' });  // 쿠키 삭제
    removeCookie('refreshToken', { path: '/' });
    navigate("/");  // 로그인 페이지로 리디렉션
  };

  const gotoMyTrade = (tab) => {
    navigate(`/myTradeList?tab=${tab}`)
  }

  return (
    <section id="menuMain" className="menuMain container">
      <Header />
      <div className="content homeContent d-flex f-column a-center j-left">
        <BackHeader
          title='전체메뉴'
        />
        <div className="inner">
          <div className="d-flex a-center j-between mt-15 mb-10">
            <p className="fs-30 fw-7 a-center ml-10">{nickname}</p>
            <button className="infoBtn">
              <img className="d-block" src={UpdateMyInfo} alt="update" onClick={handleMyInfo} />
            </button>
          </div>
          <div className="divideTextArea ba-1-green1 br-8 d-flex a-center j-between w-100"
            style={{ height: 116 }}>
            <div className="d-flex a-center j-center w-50 borderBox"
              style={{ height: '100%' }}>
              <button className="d-flex f-column a-center j-center" onClick={() => gotoMyTrade('progress')} >
                <div className="fw-6 fs-32 ta-center">{progressCount}</div>
                <div className="fw-5 fs-26 fc-mediumGray2 ta-center">진행중</div>
              </button>
            </div>
            <div className="vertical-divider"></div>
            <div className="d-flex a-center j-center w-50 borderBox"
              style={{ height: '100%' }}>
              <button className="d-flex f-column a-center j-center" onClick={() => gotoMyTrade('complete')} >
                <div className="fw-6 fs-32 ta-center">{completeCount}</div>
                <div className="fw-5 fs-26 fc-mediumGray2 ta-center">거래완료</div>
              </button>
            </div>
          </div>
          <div className="pt-20"></div>
        </div>
        <div className="menuContentBox d-flex w-100 bc-lightGray10">
          <MainMenuBox
            list={mainMenuData}
            status={activeTab}
            setStatus={setActiveTab}
          />
          <div className="menu-content bc-white">
            {activeTab === "두레 서비스" ? (
              <ul className="fs-24 fw-5">
                {menuData1.map(({ name, url }) => {
                  return (
                    <MenuLiBox
                      text={`${name}`}
                      url={url}
                      type="menu"
                    />
                  )
                })}
              </ul>
            ) : (
              <ul className="fs-24 fw-5">
                <MenuLiBox
                  text='1:1 문의'
                  url='/inquiry'
                  type='menu'
                />
                <li
                  id='logoutBox'
                  className="menuLiBox fs-24 fw-5 d-flex a-center j-between fc-mediumGray2"
                  onClick={handleLogoutClick}>
                  {'로그아웃'}
                  <img
                    className="arrowImage"
                    src={rightArrow}
                    alt="이동"
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <CommonPopup
        id='logoutPopup'
        title1='로그아웃'
        title2=''
        title3='하시겠어요?'
        text='로그아웃시 로그인 페이지로 이동합니다. 정말 로그아웃하시겠습니까?'
        btnText1='취소'
        btnText2='로그아웃'
        btnType='two'
        status={isLogoutPopupOpen}
        setStatus={setIsLogoutPopupOpen}
        btnEvent1={false}
      />
    </section>
  );
};

export default MenuMain;
